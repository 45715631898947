import { ReactComponent as Nav_arrow } from "../images/nav/arrow.svg";
import { ReactComponent as Slide_arrow } from "../images/news/slideArrow.svg";
import { ReactComponent as Nav_user } from "../images/nav/user.svg";
import { ReactComponent as Eye_visible } from "../images/backend/eye_visible.svg";
import { ReactComponent as Eye_invisible } from "../images/backend/eye_invisible.svg";
import { ReactComponent as Body } from "../images/functionalMedicine/body.svg";

export default {
  Nav_arrow,
  Slide_arrow,
  Nav_user,
  Eye_visible,
  Eye_invisible,
  Body,
};
