import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";

const telegramBaseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API}`,
  prepareHeaders: (headers) => {
    return headers.set("content-type", "application/json");
  },
});

export default telegramBaseQuery;
