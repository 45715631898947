import { styled, css } from "styled-components";
import { RWD_MD, RWD_SM } from "../../../../../utils/rwd";

import plan from "../../../../../images/timewaver/product/plan.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const TitleWrapper = styled.div`
  padding-bottom: 50px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};

  ${RWD_SM(css`
    text-align: left;
  `)};
`;

const PlanSection = styled.section`
  padding-bottom: 100px;
  display: flex;
  gap: 40px;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const PlanImage = styled.div`
  width: 28vw;
  display: flex;
  background: ${({ theme }) => theme.colors.gray};

  img {
    display: block;
  }

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const PlanContent = styled.div`
  width: calc(100% - 28vw - 40px);
  text-align: left;

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const PlanSubTitle = styled.div`
  font-weight: 700;
  font-size: clamp(18px, 2.0833vw, 20px);
  color: ${({ theme }) => theme.colors.mainBrown};
`;

const PlanDescription = styled.div``;

const PlanList = styled.ul`
  margin-top: auto;
  padding-top: 16px;
  padding-left: 20px;
`;

const PlanListItem = styled.li`
  padding: 5px 0;
`;

const PriceWrappaer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-family: ${({ theme }) => theme.fonts[2]};
  margin-top: auto;
  justify-content: flex-end;
`;

const Price = styled.div`
  font-size: 48px;
  font-weight: 600;
`;

const PriceCurrency = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export default function Prime() {
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>Timewaver 精選組合套餐</Title>
      </TitleWrapper>
      <PlanSection>
        <PlanImage>
          <img src={plan} alt="plan" />
        </PlanImage>
        <PlanContent>
          <PlanSubTitle>健康專業，輕鬆掌握！</PlanSubTitle>
          <PlanDescription>
            <p>
              選擇 Timewaver
              頻率專業組合套裝，體驗先進的頻率療法科技。無論是專業醫療機構或個人健康管理，這套設備都能助您輕鬆掌控。
            </p>
            <p>
              套裝內含完整設備、配套電腦、專業配件、培訓課程，以及一整年技術支援，讓您從零到專家，全程無後顧之憂！
            </p>
          </PlanDescription>
          <PlanSubTitle>本套餐組合包含以下內容</PlanSubTitle>
          <PlanList>
            <PlanListItem>Timewaver Frequency 設備一套</PlanListItem>
            <PlanListItem>Large Coil Plate 頻率發射板一個</PlanListItem>
            <PlanListItem>配套電腦</PlanListItem>
            <PlanListItem>所有必要專業配件（詳情面議）</PlanListItem>
            <PlanListItem>專業培訓課程</PlanListItem>
            <PlanListItem>一年技術支援</PlanListItem>
          </PlanList>
          <PriceWrappaer>
            <PriceCurrency>HKD</PriceCurrency>
            <Price>210,000</Price>
          </PriceWrappaer>
        </PlanContent>
      </PlanSection>
    </Wrapper>
  );
}
