import { styled, css } from "styled-components";

import LogoImg from "../../../images/footer/logo.png";
import LanguageImg from "../../../images/footer/language.svg";
import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Select from "../../../global/Select";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.titleBrown};
  font-family: ${({ theme }) => theme.fonts[1]};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
  position: relative;
  width: 100%;

  ${RWD_LL(css`
    width: 80%;
  `)};

  ${RWD_SM(css`
    padding-top: 60px;
    width: 70%;
    align-items: center;
  `)};
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  ${RWD_LL(css`
    flex-direction: column;
  `)};

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const LogoWrapper = styled.div`
  padding-right: 230px;
  padding-right: 11.979vw;

  ${RWD_LL(css`
    padding-bottom: 50px;
  `)};

  ${RWD_SM(css`
    padding-bottom: 0px;
  `)};
`;

const LogoContainer = styled.div`
  width: clamp(101px, 9.53125vw, 182px);

  ${RWD_SM(css`
    width: 28.33vw;
  `)};
`;

const ListWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.lightBrown};
  padding-bottom: 65px;
  font-size: clamp(16px, 1.041667vw, 20px);

  ${RWD_LL(css`
    width: 100%;
    flex-wrap: wrap;
  `)};

  ${RWD_SM(css`
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 45px;
  `)};
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 5.2vw;

  &:last-child {
    padding-right: 0;
  }

  ${RWD_LL(css`
    width: 50%;
    padding-right: 0;
    padding-bottom: 40px;

    &:last-child {
      padding-bottom: 0px;
    }
  `)};

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const ListTitle = styled.div`
  font-weight: bold;
  padding-bottom: 30px;
  font-size: clamp(18px, 1.145833vw, 22px);

  ${RWD_SM(css`
    padding-bottom: 16px;
  `)};
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  list-style-position: outside;
`;

const ListItem = styled.li`
  text-align: left;
  padding-bottom: 16px;
  cursor: pointer;

  &:first-child {
    padding-top: 0;
  }
`;

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.lightBrown};
`;

const AddListItem = styled.li`
  text-align: left;
  padding-bottom: 16px;

  &:nth-child(4) {
    padding-bottom: 5px;

    ${RWD_SM(css`
      padding-bottom: 0;
    `)};
  }

  &:last-child {
    text-align: right;
    ${RWD_SM(css`
      padding-bottom: 0;
    `)};
  }
`;

const CopyRight = styled.div`
  color: ${({ theme }) => theme.colors.copyRight};
  padding-bottom: 50px;
  font-size: clamp(11px, 0.833vw, 16px);

  ${RWD_SM(css`
    padding-bottom: 50px;
  `)};
`;

const LanguageWrapper = styled.div`
  position: absolute;
  right: 50px;
  bottom: 40px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${RWD_LL(css`
    top: 120px;
    right: 0;
  `)};

  ${RWD_SM(css`
    position: static;
    padding-bottom: 40px;
    margin: auto;
  `)};
`;

const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
`;

const Footer = ({ windowSize }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (windowSize[0] <= "430") {
      setMenuList(
        menuList.map((data) => {
          return {
            ...data,
            isOpen: false,
          };
        })
      );
    } else {
      setMenuList(
        menuList.map((data) => {
          return {
            ...data,
            isOpen: true,
          };
        })
      );
    }
  }, [windowSize]);

  const [menuList, setMenuList] = useState([
    // {
    //   id: 1,
    //   title: "關於我們",
    //   isOpen: true,
    //   subList: [
    //     {
    //       id: 1,
    //       name: "News",
    //     },
    //     {
    //       id: 2,
    //       name: "About ADX",
    //     },
    //     {
    //       id: 3,
    //       name: "Contact Us",
    //     },
    //   ],
    // },
    {
      id: 2,
      title: "健康管理方案",
      isOpen: true,
      subList: [
        { id: 1, name: "ADX身體檢查計劃", link: "services/basic" },
        { id: 2, name: "內分泌及荷爾蒙測試", link: "services/endocrine" },
        { id: 3, name: "腸漏症測試", link: "services/lgs" },
        { id: 4, name: "消化系統功能測試", link: "services/digestion" },
        { id: 5, name: "重金屬及毒素測試", link: "services/heavyMetal" },
        { id: 6, name: "延遲性/即時性食物過敏測試", link: "services/allergy" },
        { id: 7, name: "健康基因DNA解碼檢測", link: "services/dna" },
      ],
    },
    {
      id: 3,
      title: "社群媒體",
      isOpen: true,
      subList: [
        {
          id: 1,
          name: "YouTube",
          link: "https://www.youtube.com/@adx--4793",
          type: "blank",
        },
        {
          id: 2,
          name: "Facebook",
          link: "https://www.facebook.com/adxlifecenter/",
          type: "blank",
        },
      ],
    },
    {
      id: 4,
      title: "聯絡方式",
      isOpen: true,
      subList: [
        {
          id: 1,
          name: "+852 2323 3488",
        },
        {
          id: 2,
          name: "info@adxlife.com",
        },
        // {
        //   id: 3,
        //   name: "Add 1 家樂坊 21 樓 2101 室 M8",
        // },
        // {
        //   id: 4,
        //   name: "Add 九龍長沙灣荔枝角道 808 號",
        // },
        // {
        //   id: 5,
        //   name: "好運工業中心 10 樓 1005 室",
        // },
      ],
    },
  ]);

  const handleMenuSlide = (id) => {
    if (windowSize[0] > "428") return;
    setMenuList(
      menuList.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            isOpen: !data.isOpen,
          };
        } else {
          return {
            ...data,
            isOpen: false,
          };
        }
      })
    );
  };

  const handleLink = (link) => {
    navigate(link);
  };

  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <LogoWrapper>
            <LogoContainer>
              <img src={LogoImg} />
            </LogoContainer>
          </LogoWrapper>
          <ListWrapper>
            {menuList.map((data) => (
              <ListContainer
                key={data.id}
                onClick={() => handleMenuSlide(data.id)}
              >
                <ListTitle>{data.title}</ListTitle>
                {data.title === "Contact" ? (
                  <Fragment>
                    {data.isOpen && (
                      <List>
                        {data.subList.map((sub) => (
                          <AddListItem key={sub.id}>{sub.name}</AddListItem>
                        ))}
                      </List>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {data.isOpen && (
                      <List>
                        {data.subList.map((sub, index) => (
                          <Fragment key={sub.id}>
                            {sub.type === "blank" ? (
                              <ListItem
                                key={index}
                                // onClick={() => handleLink(sub.link)}
                              >
                                <Link href={sub.link} target="_blank">
                                  {sub.name}
                                </Link>
                              </ListItem>
                            ) : (
                              <ListItem
                                key={index}
                                onClick={() => handleLink(sub.link)}
                              >
                                {sub.name}
                              </ListItem>
                            )}
                          </Fragment>
                        ))}
                      </List>
                    )}
                  </Fragment>
                )}
              </ListContainer>
            ))}
          </ListWrapper>
        </ContentWrapper>

        <CopyRight>
          © 2023 ADX Lifestyle. All Rights Reserved. <br></br>Powered by AVA
          System Integration
        </CopyRight>

        {/* <LanguageWrapper>
          <LanguageContainer>
            <Select content={1} />
          </LanguageContainer>
        </LanguageWrapper> */}
      </Container>
    </Wrapper>
  );
};

export default Footer;
