import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { getAuthToken } from "../../utils";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API}`,
  prepareHeaders: (headers) => {
    const token = getAuthToken().token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    headers.set("content-type", "application/json");
    return headers;
  },
});

// const baseQueryWithReauth = async (args, api, extraOptions) => {
//   let result = await baseQuery(args, api, extraOptions);

//   if (result.error) {
//     if (result.error?.status === 401 || result.error.data?.ResultCode === 401) {
//       const refreshResult = await baseQuery("RefreshToken", api, extraOptions);

//       if (refreshResult.data) {
//         setAuthToken(refreshResult.data.AccessToken, getAuthToken().user);
//       } else {
//         MySwal.fire({
//           title: "帳號驗證失敗，請重新登入",
//           icon: "warning",
//           confirmButtonText: "確定",
//           confirmButtonColor: "#009A63",
//         }).then((result) => {
//           if (result.isConfirmed) {
//             console.log("401 token失效需重新登入");
//             clearAuthToken();
//             clearAuthFun();
//             clearLoanSearchItem();
//             clearLoanSortItem();
//             window.location.replace(`${process.env.REACT_APP_URL}`);
//           }
//         });
//       }
//     } else {
//       MySwal.fire({
//         title: "帳號驗證失敗，請重新登入",
//         icon: "warning",
//         confirmButtonText: "確定",
//         confirmButtonColor: "#009A63",
//       }).then((result) => {
//         if (result.isConfirmed) {
//           console.log("401 token失效需重新登入");
//           clearAuthToken();
//           clearAuthFun();
//           clearLoanSearchItem();
//           clearLoanSortItem();
//           window.location.replace(`${process.env.REACT_APP_URL}`);
//         }
//       });
//     }
//   }

//   return result;
// };

export default baseQuery;
