import { styled, css } from "styled-components";
import { useOutletContext, useNavigate } from "react-router-dom";

import BabykingomLogo from "../../../images/media/babykingom.png";
import BizhkLogo from "../../../images/media/bizhk.png";
import ELogo from "../../../images/media/e.png";
import EvensiLogo from "../../../images/media/evensi.png";
import StartupLogo from "../../../images/media/startup.png";
import Hk01Logo from "../../../images/media/hk01.png";
import Cta from "../../../images/media/cta.png";

import Cta_Mob from "../../../images/media/cta_mobile.png";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import { useEffect, useState } from "react";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  padding-top: 105px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${RWD_SM(css`
    padding-top: 40px;
    padding-bottom: 60px;
  `)};
`;

const TitleWrapper = styled.div`
  padding-bottom: 50px;
  ${RWD_SM(css`
    width: 77.77%;
  `)};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.titleBrown};
  font-size: ${({ theme }) => theme.fontSize.sectionTitle};
  padding-bottom: 20px;
  font-family: ${({ theme }) => theme.fonts[2]};
  font-weight: bold;
  font-size: clamp(24px, 2.0833vw, 40px);
`;

const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.mainBrown};
  font-size: clamp(14px, 1.25vw, 24px);
`;

const LogosWrapper = styled.div`
  ${RWD_SM(css`
    width: 77.77%;
  `)};
`;

const LogosContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LogosBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;

  ${RWD_SM(css`
    flex-direction: column;
    padding-bottom: 39px;
  `)};
`;

const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4.166vw;

  ${RWD_SM(css`
    width: 100%;
    margin: 0;
    padding-bottom: 39px;

    &:last-child {
      padding-bottom: 0px;
    }
  `)};
`;

const Logo = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 4.166vw;

  &:last-child {
    margin-right: 0;
  }

  ${RWD_SM(css`
    margin-right: 20px;
  `)};

  img {
    width: auto;
    height: clamp(40px, 4.166vw, 80px);

    ${RWD_SM(css`
      width: auto;
      flex-wrap: wrap;
      height: 40px;
    `)};
  }
`;

const CtaWrapper = styled.div`
  padding-top: 160px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${RWD_SM(css`
    padding-top: 70px;
  `)};
`;

const CtaContainer = styled.div`
  width: 62.5vw;
  cursor: pointer;

  ${RWD_SM(css`
    width: 83.333%;
  `)};
`;

const Media = () => {
  const navigate = useNavigate();
  const windowSize = useOutletContext();
  const [ctaImg, setCtaImg] = useState(Cta);
  useEffect(() => {
    if (windowSize[0] <= "430") {
      setCtaImg(Cta_Mob);
    } else {
      setCtaImg(Cta);
    }
  }, [windowSize]);

  const handleToAppointmentPage = () => {
    navigate(`/appointment`);
  };

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>媒體報導</Title>
          <SubTitle>多年累積、多方介紹，ADX 值得你們信賴與推薦的團隊</SubTitle>
        </TitleWrapper>
        <LogosWrapper>
          <LogosContainer>
            <LogosBox>
              <Logos>
                <Logo>
                  <img src={EvensiLogo} />
                </Logo>
                <Logo>
                  <img src={Hk01Logo} />
                </Logo>
              </Logos>

              <Logos>
                <Logo>
                  <img src={StartupLogo} />
                </Logo>
                <Logo>
                  <img src={ELogo} />
                </Logo>
              </Logos>
            </LogosBox>

            <Logos>
              <Logo>
                <img src={BabykingomLogo} />
              </Logo>
              <Logo>
                <img src={BizhkLogo} />
              </Logo>
            </Logos>
          </LogosContainer>
        </LogosWrapper>
        <CtaWrapper>
          <CtaContainer onClick={handleToAppointmentPage}>
            <img src={ctaImg} />
          </CtaContainer>
        </CtaWrapper>
      </Container>
    </Wrapper>
  );
};

export default Media;
