import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import logo from "../../../images/airtrack/logo.png";
import logo_svg from "../../../images/airtrack/logo.svg";
import airtrack_1 from "../../../images/airtrack/airtrack_1.svg";
import airtrack_1_rotate from "../../../images/airtrack/airtrack_1_rotate.svg";
import airtrack_2 from "../../../images/airtrack/airtrack_2.svg";
import airtrack_3 from "../../../images/airtrack/airtrack_3.svg";
import airtrack_4 from "../../../images/airtrack/airtrack_4.png";
import airtrack_app_1 from "../../../images/airtrack/airtrack_app1_new.svg";
import airtrack_app_2 from "../../../images/airtrack/airtrack_app2_new.svg";
import airtrack_app_3 from "../../../images/airtrack/airtrack_app3.svg";
import airtrack_app_4 from "../../../images/airtrack/airtrack_app4.svg";
import airtrack_app_5 from "../../../images/airtrack/airtrack_app5.svg";

import img1 from "../../../images/airtrack/S03.png";
import img2 from "../../../images/airtrack/S04.png";
import img3 from "../../../images/airtrack/S05.png";
import img4 from "../../../images/airtrack/S06.png";
import img5 from "../../../images/airtrack/S07.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: clamp(300px, 62.5vw, 1200px);
  padding-top: 80px;
  padding-bottom: 200px;

  // 1200px
  text-align: left;

  ${RWD_LL(css`
    width: 83.22vw;
    width: 100%;
  `)};

  ${RWD_MD(css`
    padding-top: 30px;
    padding-bottom: 60px;
    padding-top: 0;
  `)};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;

  ${RWD_SM(css`
    padding-top: 30px;
    padding-bottom: 15px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(35px, 3.125vw, 60px);
  font-weight: 900;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const MainLogo = styled.div`
  width: 50%;
  padding-top: 40px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const MainImage = styled.div`
  width: 50%;

  ${RWD_MD(css`
    width: 80%;
    margin: auto;
  `)};
`;

const RowSection = styled.div`
  display: flex;
  gap: 40px;
  justify-content: space-between;
  position: relative;

  margin-bottom: 100px;

  ${RWD_MD(css`
    flex-direction: column;
  `)};
`;

const ColumnSection = styled.div`
  margin-bottom: 100px;
`;

const SubTitle = styled.div`
  font-size: clamp(20px, 1.875vw, 36px);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
  padding-bottom: 20px;
  text-align: center;
`;

const ContentContainer = styled.div`
  text-align: justify;
  width: 45%;

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const Content = styled.div`
  p {
    margin: 5px 0;
  }
`;

const ContentTitle = styled.div`
  font-size: clamp(20px, 1.875vw, 36px);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
  padding-bottom: 20px;
`;

const ContentImage = styled.div`
  width: 55%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  ${RWD_MD(css`
    width: 100%;
    height: 300px;
  `)};
`;

const RowContentContainer = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;

  ${RWD_MD(css`
    flex-direction: column;
  `)};
`;

const EquipmentWrapper = styled.div`
  width: 60%;
  border-top: 1px solid ${({ theme }) => theme.colors.titleBrown};

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const Equipment = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  border: 1px solid ${({ theme }) => theme.colors.titleBrown};
  color: ${({ theme }) => theme.colors.titleBrown};
  border-top: none;
  text-align: center;
`;

const EquipmentName = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(187, 175, 149, 0.1);
  border-right: 1px solid ${({ theme }) => theme.colors.titleBrown};

  ${RWD_MD(css`
    padding: 10px;
  `)};
`;

const EquipmentValue = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${RWD_MD(css`
    padding: 10px;
  `)};
`;

const EquipmentImage = styled.div`
  width: 35%;

  ${RWD_MD(css`
    width: 80%;
    margin: auto;
  `)};
`;

const AppTitle = styled.div`
  font-size: clamp(35px, 3.125vw, 60px);
  font-weight: 900;
  color: ${({ theme }) => theme.colors.titleBrown};
  white-space: nowrap;

  &:nth-child(3) {
    margin-left: 25%;

    ${RWD_MD(css`
      margin-left: 0;
    `)};
  }
`;

const AppTitleWrapper = styled.div`
  position: relative;
  width: 50%;

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const AppImage = styled.div`
  position: relative;
  width: 50%;

  img {
    position: absolute;
    bottom: 0;
    left: 0%;
    width: 100%;
    display: block;

    ${RWD_MD(css`
      position: initial;
    `)};
  }

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const AppLogo = styled.div`
  width: 35%;
  margin-bottom: 10px;
`;

const AppContent = styled.div`
  font-size: clamp(16px, 1.5635vw, 30px);
  white-space: nowrap;
  position: absolute;
  bottom: 20px;
  font-weight: 500;

  ${RWD_MD(css`
    /* padding: 20px 0; */
    padding: 10px 0 40px 0;
    position: initial;
    text-align: end;
  `)};
`;

const AppContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 55vh;
  justify-content: space-between;

  ${RWD_MD(css`
    flex-direction: column;
  `)};
`;

const TableEl = styled.table`
  width: 100%;
  border-collapse: collapse;

  ${RWD_MD(css`
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  `)};
`;

const TheadEl = styled.thead`
  background: ${({ theme }) => theme.colors.titleBrown};
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.titleBrown};

  ${RWD_MD(css`
    text-align: center;
  `)};
`;

const TdEl = styled.td`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.titleBrown};

  ${RWD_MD(css`
    padding: 10px;
  `)};
`;

const TrEl = styled.tr`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.titleBrown};
  ${RWD_MD(css`
    padding: 10px;
  `)};
`;

const ThEl = styled.th`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.white};

  ${RWD_MD(css`
    padding: 10px;
  `)};

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

const TbodyEl = styled.tbody``;

const BackGroundImage = styled.div`
  height: 60vh;
  width: 100%;
  overflow: hidden;

  img {
    object-fit: cover;
    height: 60vh;
    transform: scale(1.3);
  }
`;

const Shadow = styled.div`
  height: 65%;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.6));
`;

const SloganWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  left: 50%;
  padding-bottom: 25px;
  transform: translateX(-50%);

  ${RWD_MD(css`
    flex-direction: column;
    width: 100%;
    padding-bottom: 20px;
    height: 100%;
  `)};
`;

const SloganContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Slogan = styled.div`
  font-size: clamp(20px, 2.5vw, 48px);
  font-weight: 900;
  color: ${({ theme }) => theme.colors.white};

  text-align: end;

  ${RWD_MD(css`
    text-align: center;
  `)};
`;

const SloganLogo = styled.div`
  width: 200px;

  ${RWD_MD(css`
    width: 28%;
    margin: 10px;
    margin-left: auto;
  `)};
`;

const RecordImage = styled.div``;

const RecordContainer = styled.div`
  display: flex;
  gap: 40px;

  ${RWD_MD(css`
    flex-direction: column;
  `)};
`;

const RecordContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  ${RWD_MD(css`
    padding-bottom: 150px;
  `)};
`;

const RecordTitle = styled.div`
  font-size: clamp(24px, 2.5vw, 48px);
  font-weight: 900;
  color: ${({ theme }) => theme.colors.titleBrown};
  padding-bottom: 20px;
`;

const RecordContent = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const RecordLogo = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 200px;

  img {
    width: 100%;
    display: block;
  }

  ${RWD_MD(css`
    width: 150px;
  `)};
`;

const PhotoContainer = styled.div`
  display: flex;
  gap: 40px;

  ${RWD_MD(css`
    flex-direction: column;
  `)};
`;

const PhotoContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PhotoTitle = styled.div`
  font-size: clamp(24px, 2.5vw, 48px);
  font-weight: 900;
  color: ${({ theme }) => theme.colors.titleBrown};
  padding-bottom: 20px;
`;

const PhotoContent = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const GpsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10%;
  justify-content: space-between;
  align-items: center;

  ${RWD_MD(css`
    flex-direction: column;
  `)};
`;

const GpsTitleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${RWD_MD(css`
    width: 100%;
  `)};
`;

const GpsTitle = styled.div`
  font-size: clamp(35px, 3.125vw, 60px);
  font-weight: 900;
  color: ${({ theme }) => theme.colors.titleBrown};
  white-space: nowrap;

  &:nth-child(1) {
    margin-top: 20%;

    ${RWD_MD(css`
      margin: 0;
    `)};
  }

  &:nth-child(2) {
    margin-left: 10%;

    ${RWD_MD(css`
      margin: 0;
    `)};
  }
`;

const GpsContent = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.titleBrown};
  padding-top: 20px;

  ${RWD_MD(css`
    padding-bottom: 40px;
  `)};
`;

const GpsLogo = styled.div`
  width: 35%;
  margin-top: auto;

  ${RWD_MD(css`
    padding-bottom: 20px;
  `)};
`;

const GpsAppImage = styled.div`
  width: 100%;
`;

const WarningContainer = styled.div`
  position: absolute;
  width: 40%;
  right: 0;
  height: 100%;
  padding-top: 5%;
  display: flex;
  flex-direction: column;

  ${RWD_MD(css`
    width: 100%;
    padding-top: 0;
  `)};
`;

const WarningTitleWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  ${RWD_MD(css`
    align-items: flex-end;
    text-align: end;
    margin: 10px 15px 0 0;
  `)};
`;

const WarningTitle = styled.div`
  font-size: clamp(35px, 3.125vw, 60px);
  font-weight: 900;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const WarningContent = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.titleBrown};
  padding-top: 20px;
  width: 80%;

  ${RWD_MD(css`
    /* padding: 20px 0; */
    font-size: 17px;
    padding-top: 10px;
    width: 75%;
  `)};
`;

const WarningLogo = styled.div`
  width: 30%;
  position: absolute;
  right: 20px;
  bottom: 20px;

  img {
    display: block;
  }

  ${RWD_MD(css`
    right: 10px;
  `)};
`;

const WarningImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 60%;
    height: auto;
    display: block;
  }

  ${RWD_MD(css`
    display: none;
  `)};
`;

const WarningBackGroundImage = styled.div`
  /* height: 60vh; */
  width: 100%;
  overflow: hidden;

  img {
    display: block;
    object-fit: cover;
    height: 100%;
  }

  ${RWD_MD(css`
    height: 500px;
  `)};
`;

const PromotionImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
  }
`;

const AirtrackPage = () => {
  const equipment = [
    {
      id: 1,
      name: "電源",
      value: ["充電鋰離子電池（3.7V/2000mAh）", "DC5V/1A TYPE-C USB接口"],
    },
    {
      id: 2,
      name: "電池工作時間",
      value: ["工作續航6HR連續使用"],
    },
    {
      id: 3,
      name: "外形尺寸",
      value: ["172mm (高) × 68mm (寬) × 35mm (厚)"],
    },
    {
      id: 4,
      name: "重量",
      value: ["約 200 克（含電池）"],
    },
    {
      id: 5,
      name: "環境條件",
      value: [
        "工作環境：0 ~ 50°C, 15 ~ 90%RH",
        "儲藏環境：-10 ~ 60°C, 15 ~ 90%RH",
      ],
    },
    {
      id: 6,
      name: "標準附件",
      value: ["Type-C USB線、便攜式保護袋、說明書"],
    },
    {
      id: 6,
      name: "可選語言",
      value: ["英語、繁體中文"],
    },
  ];

  const particulateMatterData = {
    header: [
      {
        id: 1,
        name: "項目",
        value: "item",
      },
      {
        id: 2,
        name: "測試範圍",
        value: "range",
      },
      {
        id: 3,
        name: "等級",
        value: "grading",
      },
      {
        id: 4,
        name: "說明",
        value: "description",
      },
    ],
    data: [
      {
        id: 1,
        item: "PM2.5 (µg/m³)",
        range: "0 - 10",
        grading: "良好 (Excellent)",
        description: "空氣品質優良，適合所有人群",
      },
      {
        id: 2,
        item: "PM2.5 (µg/m³)",
        range: "10 - 25",
        grading: "注意 (Caution)",
        description: "空氣品質尚可，敏感人群需注意，建議減少戶外活動",
      },
      {
        id: 3,
        item: "PM2.5 (µg/m³)",
        range: "25 - 45",
        grading: "嚴重 (Serious)",
        description: "空氣品質較差，對呼吸道敏感人群有影響",
      },
      {
        id: 4,
        item: "PM2.5 (µg/m³)",
        range: "45 - 100",
        grading: "危險 (Danger)",
        description: "空氣污染嚴重，可能影響健康，避免戶外活動",
      },
      {
        id: 5,
        item: "PM2.5 (µg/m³)",
        range: "> 100",
        grading: "極危險 (Hazardous)",
        description: "空氣質量極差，對所有人群均有不良影響",
      },
    ],
  };

  const hchoData = {
    header: [
      {
        id: 1,
        name: "項目",
        value: "item",
      },
      {
        id: 2,
        name: "測試範圍",
        value: "range",
      },
      {
        id: 3,
        name: "等級",
        value: "grading",
      },
      {
        id: 4,
        name: "說明",
        value: "description",
      },
    ],
    data: [
      {
        id: 1,
        item: "甲醛 HCHO (ppm)",
        range: "0.00 - 0.08",
        grading: "良好 (Good)",
        description: "甲醛含量在安全範圍內",
      },
      {
        id: 2,
        item: "甲醛 HCHO (ppm)",
        range: "0.081 - 0.15",
        grading: "注意 (Normal)",
        description: "甲醛含量輕微超標，長時間暴露可能對敏感人群有影響",
      },
      {
        id: 3,
        item: "甲醛 HCHO (ppm)",
        range: "0.151 - 0.20",
        grading: "嚴重 (Caution)",
        description: "甲醛含量超標，需立即改善環境",
      },
      {
        id: 4,
        item: "甲醛 HCHO (ppm)",
        range: "> 0.20",
        grading: "危險 (Danger)",
        description: "甲醛嚴重超標，可能對身體健康造成影響",
      },
    ],
  };

  const tvocData = {
    header: [
      {
        id: 1,
        name: "項目",
        value: "item",
      },
      {
        id: 2,
        name: "測試範圍",
        value: "range",
      },
      {
        id: 3,
        name: "等級",
        value: "grading",
      },
      {
        id: 4,
        name: "說明",
        value: "description",
      },
    ],
    data: [
      {
        id: 1,
        item: "總揮發性有機物 (TVOC)",
        range: "0.00 - 0.60",
        grading: "良好 (Excellent)",
        description: "揮發性有機物含量安全",
      },
      {
        id: 2,
        item: "總揮發性有機物 (TVOC)",
        range: "0.61 - 2.99",
        grading: "注意 (Caution)",
        description: "揮發性有機物含量超標，需注意通風換氣",
      },
      {
        id: 3,
        item: "總揮發性有機物 (TVOC)",
        range: "3.00 - 9.99",
        grading: "嚴重 (Danger)",
        description: "揮發性有機物含量較高，建議儘速改善環境",
      },
      {
        id: 4,
        item: "總揮發性有機物 (TVOC)",
        range: "> 9.99",
        grading: "極危險 (Hazardous)",
        description: "揮發性有機物含量嚴重超標，對健康產生極大威脅",
      },
    ],
  };

  const suspendedParticulateMatterData = {
    header: [
      {
        id: 1,
        name: ">0.3um 懸浮粒子 pcs/L",
        value: "pcs",
      },
      {
        id: 2,
        name: "等級",
        value: "grading",
      },
      {
        id: 3,
        name: "說明",
        value: "description",
      },
    ],
    data: [
      {
        id: 1,
        pcs: "0 - 500",
        grading: "優質 (Excellent)",
        description: "空氣品質優良，適合所有人群",
      },
      {
        id: 2,
        pcs: "501 - 3,000",
        grading: "潔淨 (Good)",
        description: "空氣品質尚可，敏感人群需注意",
      },
      {
        id: 3,
        pcs: "3,001 - 9,999",
        grading: "注意 (Attention)",
        description: "空氣品質較差，對呼吸道敏感人群有影響",
      },
      {
        id: 4,
        pcs: "10,000 - 233,000",
        grading: "警報 (Caution)",
        description: "空氣污染嚴重，可能影響健康，盡快離開現場",
      },
      {
        id: 5,
        pcs: ">= 233,000",
        grading: "危險 (Danger)",
        description: "空氣質量極差，對所有人群均有不良影響",
      },
    ],
  };

  return (
    <Wrapper>
      <Container>
        <PromotionImage>
          <img src={img1} alt="Airtrack 3.0" />
        </PromotionImage>
        <PromotionImage>
          <img src={img2} alt="Airtrack 3.0" />
        </PromotionImage>
        <PromotionImage>
          <img src={img3} alt="Airtrack 3.0" />
        </PromotionImage>
        <PromotionImage>
          <img src={img4} alt="Airtrack 3.0" />
        </PromotionImage>
        <PromotionImage>
          <img src={img5} alt="Airtrack 3.0" />
        </PromotionImage>

        {/* <RowSection>
          <MainContainer>
            <MainLogo>
              <img src={logo} alt="Airtrack 3.0" />
            </MainLogo>
            <TitleWrapper>
              <Title>Airtrack 3.0</Title>
              <Title>空氣品質檢測儀</Title>
            </TitleWrapper>
          </MainContainer>
          <MainImage>
            <img src={airtrack_1} alt="Airtrack 3.0" />
          </MainImage>
        </RowSection>
        <RowSection>
          <ContentContainer>
            <ContentTitle>All-new technology</ContentTitle>
            <Content>
              Airtrack是便攜式空氣質量檢測器，它可以同時測試空氣粉塵和甲醛含量。通過高靈敏度、高分辨率、高穩定性的塵埃粒子分析傳感器，再經過數據收集和分析，最後將數據顯示在螢幕上，這就是Airtrack的運行方式。該儀器測試的精確度高、性能穩定、多功能，操作簡易，更達到並遠超過了國際上同類產品的性能指標。甲醛含量及顆粒物數可以存儲到內存裡並能隨時在螢幕上查看。
            </Content>
          </ContentContainer>
          <ContentImage>
            <img src={airtrack_2} alt="Airtrack 3.0" />
          </ContentImage>
        </RowSection> */}

        {/* PM2.5 */}
        {/* <ColumnSection>
          <SubTitle>測試項目規格說明</SubTitle>
          <TableEl>
            <TheadEl>
              <TrEl>
                {particulateMatterData.header.map((data) => (
                  <ThEl key={data.id}>{data.name}</ThEl>
                ))}
              </TrEl>
            </TheadEl>
            <TbodyEl>
              {particulateMatterData.data.map((item) => (
                <TrEl key={item.id}>
                  {particulateMatterData.header.map((el) => (
                    <TdEl key={el.id}>{item[el.value]}</TdEl>
                  ))}
                </TrEl>
              ))}
            </TbodyEl>
          </TableEl>
        </ColumnSection> */}

        {/* suspended particulate Matter */}
        {/* <ColumnSection>
          <SubTitle>測試項目規格說明</SubTitle>
          <TableEl>
            <TheadEl>
              <TrEl>
                {suspendedParticulateMatterData.header.map((data) => (
                  <ThEl key={data.id}>{data.name}</ThEl>
                ))}
              </TrEl>
            </TheadEl>
            <TbodyEl>
              {suspendedParticulateMatterData.data.map((item) => (
                <TrEl key={item.id}>
                  {suspendedParticulateMatterData.header.map((el) => (
                    <TdEl key={el.id}>{item[el.value]}</TdEl>
                  ))}
                </TrEl>
              ))}
            </TbodyEl>
          </TableEl>
        </ColumnSection> */}

        {/* HCHO */}
        {/* <ColumnSection>
          <SubTitle>測試項目規格說明</SubTitle>
          <TableEl>
            <TheadEl>
              <TrEl>
                {hchoData.header.map((data) => (
                  <ThEl key={data.id}>{data.name}</ThEl>
                ))}
              </TrEl>
            </TheadEl>
            <TbodyEl>
              {hchoData.data.map((item) => (
                <TrEl key={item.id}>
                  {hchoData.header.map((el) => (
                    <TdEl key={el.id}>{item[el.value]}</TdEl>
                  ))}
                </TrEl>
              ))}
            </TbodyEl>
          </TableEl>
        </ColumnSection> */}

        {/* TVOC */}
        {/* <ColumnSection>
          <SubTitle>測試項目規格說明</SubTitle>
          <TableEl>
            <TheadEl>
              <TrEl>
                {tvocData.header.map((data) => (
                  <ThEl key={data.id}>{data.name}</ThEl>
                ))}
              </TrEl>
            </TheadEl>
            <TbodyEl>
              {tvocData.data.map((item) => (
                <TrEl key={item.id}>
                  {tvocData.header.map((el) => (
                    <TdEl key={el.id}>{item[el.value]}</TdEl>
                  ))}
                </TrEl>
              ))}
            </TbodyEl>
          </TableEl>
        </ColumnSection> */}

        {/* <ColumnSection>
          <SubTitle>設備資訊</SubTitle>
          <RowContentContainer>
            <EquipmentImage
              style={{
                transform: "scaleX(1)",
              }}
            >
              <img src={airtrack_1_rotate} alt="Airtrack 3.0" />
            </EquipmentImage>
            <EquipmentWrapper>
              {equipment.map((item) => (
                <Equipment>
                  <EquipmentName>{item.name}</EquipmentName>
                  <EquipmentValue>
                    {item.value.map((value) => (
                      <div>{value}</div>
                    ))}
                  </EquipmentValue>
                </Equipment>
              ))}
            </EquipmentWrapper>
          </RowContentContainer>
        </ColumnSection> */}

        {/* <ColumnSection>
          <SubTitle>注意事項</SubTitle>
          <Content>
            <p>空氣檢測儀使用前請注意以下條件：</p>
            <p>本產品內置鋰電池，請使用標準 DC-5V 充電器。</p>
            <p>操作時不要堵塞進、出風口，避免數值偏差。</p>
            <p>請勿在空氣質量差、塵土飛揚的環境中頻繁使用，以延長使用壽命。</p>
            <p>
              請勿在高溫、高濕、機械性地震的地方或靠近強磁場的地方使用，以免設備數值不準確或內部電路故障。
            </p>
            <p>
              該裝置適用於-10℃～50℃的溫度範圍內。設備及附件儲存在-20℃～55℃的溫度範圍內。當環境溫度過低或過高時，可導致設備故障。
            </p>
            <p>
              請避免設備和附件暴露於雨水或潮濕，否則可能導致顯示屏、電池、外殼和電路故障或風險。
            </p>
            <p>請勿將銳器等尖銳物插入進、出風口或充電空間，以免損壞設備。</p>
            <br></br>
            <p>使用注意</p>
            <p>
              當從包裝中取出後，由於包裝內部有酒精異味氣體包裝，數據可能異常。需要將設備放在空氣流通良好的窗戶邊通風放置1～2小時，數據就會恢復正常值。
            </p>
            <p>
              TVOC
              傳感器易受煙霧、酒精、甲醛、香水等異味氣體影響，過一段時間即可恢復正常。
            </p>
            <p>
              當設備顯示異常數據時，建議將設備放置在空氣流通良好的窗戶邊，重啟設備數據顯示
              10～18 秒，設備數據恢復後重新開機。
            </p>
            <p>
              本產品具備檢測報警功能，但不能防止危險物質的洩漏，故而對發生事故造成的損失，我們不予承擔責任。
            </p>
          </Content>
        </ColumnSection> */}

        {/* <RowSection>
          <AppContainer>
            <AppTitleWrapper>
              <AppLogo>
                <img src={logo} alt="Airtrack 3.0" />
              </AppLogo>
              <AppTitle>看見每一口呼吸</AppTitle>
              <AppTitle> 享受每一刻安心</AppTitle>
              <AppContent>APP智慧連結，提供全方位空氣品質管理。</AppContent>
            </AppTitleWrapper>
            <AppImage>
              <img src={airtrack_app_1} alt="Airtrack 3.0" />
            </AppImage>
          </AppContainer>
        </RowSection> */}

        {/* <RowSection>
          <BackGroundImage>
            <img src={airtrack_app_3} alt="Airtrack 3.0" />
            <Shadow />
          </BackGroundImage>
          <SloganWrapper>
            <SloganLogo>
              <img src={logo_svg} alt="Airtrack 3.0" />
            </SloganLogo>
            <SloganContainer>
              <Slogan>收集30秒測試數據</Slogan>
              <Slogan>前後比較</Slogan>
            </SloganContainer>
          </SloganWrapper>
        </RowSection> */}

        {/* <RowSection>
          <RecordContainer>
            <RecordImage>
              <img src={airtrack_app_2} alt="Airtrack 3.0" />
            </RecordImage>
            <RecordContentContainer>
              <RecordTitle>記錄每一個變化</RecordTitle>
              <RecordContent>
                30秒快速偵測，全面洞察空氣變化，讓監測空氣變得前所未有的簡單。
              </RecordContent>
              <RecordLogo>
                <img src={logo} alt="Airtrack 3.0" />
              </RecordLogo>
            </RecordContentContainer>
          </RecordContainer>
        </RowSection> */}

        {/* <RowSection>
          <PhotoContainer>
            <RecordImage>
              <img src={airtrack_app_4} alt="Airtrack 3.0" />
            </RecordImage>
            <PhotoContentContainer>
              <PhotoTitle>拍照記錄每一個當下</PhotoTitle>
              <PhotoContent>一鍵捕捉瞬間，讓每個時刻都鮮明</PhotoContent>
            </PhotoContentContainer>
          </PhotoContainer>
        </RowSection> */}

        {/* <RowSection>
          <GpsContainer>
            <GpsTitleWrapper>
              <GpsTitle>GPS及拍照留存</GpsTitle>
              <GpsTitle>歷史數據盡在手中</GpsTitle>
              <GpsContent>
                位置與影像同步保存，所有過去清晰可見，歷史變遷一目了然。
              </GpsContent>

              <GpsLogo>
                <img src={logo} alt="Airtrack 3.0" />
              </GpsLogo>
            </GpsTitleWrapper>
            <GpsAppImage>
              <img src={airtrack_app_5} alt="Airtrack 3.0" />
            </GpsAppImage>
          </GpsContainer>
        </RowSection> */}

        {/* <RowSection>
          <WarningBackGroundImage>
            <img src={airtrack_3} alt="Airtrack 3.0" />
          </WarningBackGroundImage>
          <WarningContainer>
            <WarningImage>
              <img src={airtrack_4} alt="Airtrack 3.0" />
            </WarningImage>
            <WarningTitleWrapper>
              <WarningTitle>即時警報提醒</WarningTitle>
              <WarningContent>
                瞬間掌握風險變化，迅速反應防範， 讓安全隨時在線。
              </WarningContent>
            </WarningTitleWrapper>
            <WarningLogo>
              <img src={logo_svg} alt="Airtrack 3.0" />
            </WarningLogo>
          </WarningContainer>
        </RowSection> */}
      </Container>
    </Wrapper>
  );
};

export default AirtrackPage;
