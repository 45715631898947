import { createApi } from "@reduxjs/toolkit/query/react";
import frontendBaseQuery from "./query/frontendBaseQuery";

export const reservationApi = createApi({
  reducerPath: "reservationApi",
  baseQuery: frontendBaseQuery,
  endpoints: (builder) => ({
    getReservationList: builder.mutation({
      query: ({ doctorId, year, month }) => ({
        url: `/Reservation/GetReservationList`,
        method: "POST",
        body: JSON.stringify({
          doctorId,
          year,
          month,
        }),
      }),
      providesTags: [{ type: "reservation", id: "RESERVATION" }],
    }),
    createReservation: builder.mutation({
      query: ({
        doctorId,
        reservationType,
        bookerName,
        bookerPhone,
        bookerEmail,
        bookerTime,
      }) => ({
        url: `/Reservation/Reservation`,
        method: "POST",
        body: JSON.stringify({
          doctorId,
          reservationType,
          bookerName,
          bookerPhone,
          bookerEmail,
          bookerTime,
        }),
      }),
      invalidatesTags: [{ type: "reservation", id: "RESERVATION" }],
    }),
  }),
});

export const { useGetReservationListMutation, useCreateReservationMutation } =
  reservationApi;
