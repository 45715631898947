import { styled, css } from "styled-components";
import { useState, useEffect } from "react";

import { useOutletContext } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { Autoplay, Pagination } from "swiper/modules";

import ReviewImg1 from "../../../images/review/review_1.png";
import ReviewImg2 from "../../../images/review/review_2.png";
import ReviewImg3 from "../../../images/review/review_3.png";
import ReviewImg4 from "../../../images/review/review_4.png";

import Button from "../../../global/Button";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.gray};
`;

const Container = styled.div`
  padding-top: 120px;
  padding-bottom: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;

  ${RWD_SM(css`
    padding-top: 65px;
    padding-bottom: 60px;
  `)};
`;

const TitleWrapper = styled.div`
  padding-bottom: 50px;
  ${RWD_SM(css`
    width: 77.77%;
    padding-bottom: 40px;
  `)};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.titleBrown};
  font-size: ${({ theme }) => theme.fontSize.sectionTitle};
  padding-bottom: 20px;
  font-family: ${({ theme }) => theme.fonts[2]};
  font-weight: bold;
  font-size: clamp(24px, 2.0833vw, 40px);
`;

const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.mainBrown};
  font-size: clamp(14px, 1.25vw, 24px);
`;

const SlideWrapper = styled.div`
  width: 100vw;
  display: flex;
  height: calc(clamp(160px, 15.625vw, 300px) + 100px);
  position: relative;

  ${RWD_SM(css`
    height: calc(160px + 40px);
    /* padding-bottom: 40px; */
    /* height: 44.44vw; */
  `)};
`;

const SlideContainer = styled.div`
  /* width: auto; */
  width: calc(112.5vw + 60px);
  /* width: 100vw; */
  overflow: hidden;
  position: absolute;
  /* left: calc(-14.0625vw + 60px); */
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  ${RWD_SM(css`
    width: calc(199.98vw + 40px);
    /* left: calc(-66.66vw + 40px); */
  `)};
`;

const Slide = styled.div`
  overflow: hidden;
  height: 100%;
  width: 100%;
  height: clamp(160px, 15.625vw, 300px);
  /* background: ${({ theme }) => theme.colors.white}; */
  filter: drop-shadow(3px 3px 10px rgba(61, 42, 13, 0.05));
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  border-radius: 10px;
  position: relative;
  color: ${({ theme }) => theme.colors.white};

  ${RWD_SM(css`
    /* width: 66.66vw; */
  `)};
`;

const SlideImg = styled.img`
  height: 100%;
  position: absolute;
  left: 0;
  object-fit: cover;
  transition: all 0.5s ease-in-out;

  ${Slide}:hover & {
    transform: scale(1.2);
  }
`;

const SlideContentWrapper = styled.div`
  z-index: 2;
  width: 85.185%;
  text-align: left;
  /* position: absolute; */
  /* top: 34.6666%; */
  padding-bottom: clamp(6.9px, 1.5625vw, 10px);

  opacity: 0;
  transition: all 0.3s ease-in-out;
  /* padding: 30px 40px; */

  ${Slide}:hover & {
    opacity: 1;
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
  }

  ${RWD_SM(css`
    opacity: 1;
    width: 90%;
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
  `)};
`;

const SlideTime = styled.div`
  font-family: ${({ theme }) => theme.fonts[1]};
  font-size: clamp(24px, 2.0833vw, 40px);
  padding-bottom: 20px;
  padding-bottom: 1.041667vw;
  font-weight: bold;
`;

const SlideText = styled.div`
  font-size: clamp(15px, 1.25vw, 24px);
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts[1]};
  text-align: justify;
`;

const Shadow = styled.div`
  height: 65%;
  width: 100%;
  background-color: aliceblue;
  position: absolute;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0));
`;

const Review = () => {
  const windowSize = useOutletContext();
  const [swiperView, setSwiperView] = useState(4);

  useEffect(() => {
    if (windowSize[0] <= "1024") {
      setSwiperView(4);
      if (windowSize[0] <= "430") {
        setSwiperView(3);
      }
    } else {
      setSwiperView(4);
    }
  }, [windowSize]);

  const handleAlert = () => {
    MySwal.fire({
      title: "敬請期待",
      confirmButtonColor: "#B77D38",
      confirmButtonText: "確認",
    });
  };
  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>活動回顧</Title>
          <SubTitle>
            ADX 2016
            起至今已辦理無數活動、累積上百位學員，點擊查看花絮與他們的見證
          </SubTitle>
        </TitleWrapper>
        <SlideWrapper>
          <SlideContainer>
            <Swiper
              slidesPerView={swiperView}
              spaceBetween={20}
              loop={true}
              // autoplay={{
              //   disableOnInteraction: true,
              // }}
              // modules={[Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide onClick={handleAlert}>
                <Slide>
                  <SlideImg src={ReviewImg1} />
                  <SlideContentWrapper>
                    <SlideTime>Mind-Body-Spirit</SlideTime>
                    <SlideText>壓力管理和情緒健康研討會</SlideText>
                  </SlideContentWrapper>
                  <Shadow />
                </Slide>
              </SwiperSlide>
              <SwiperSlide onClick={handleAlert}>
                <Slide>
                  <SlideImg src={ReviewImg2} />
                  <SlideContentWrapper>
                    <SlideTime>Mind-Body-Spirit</SlideTime>
                    <SlideText>冥想與心靈平靜工作坊</SlideText>
                  </SlideContentWrapper>
                  <Shadow />
                </Slide>
              </SwiperSlide>
              <SwiperSlide onClick={handleAlert}>
                <Slide>
                  <SlideImg src={ReviewImg3} />
                  <SlideContentWrapper>
                    <SlideTime>Fitness and Physical</SlideTime>
                    <SlideText>運動療法：用運動促進身心康復</SlideText>
                  </SlideContentWrapper>
                  <Shadow />
                </Slide>
              </SwiperSlide>
              <SwiperSlide onClick={handleAlert}>
                <Slide>
                  <SlideImg src={ReviewImg4} />
                  <SlideContentWrapper>
                    <SlideTime>Fitness and Physical</SlideTime>
                    <SlideText>運動與社交：運動隊伍和社交活動的聚會</SlideText>
                  </SlideContentWrapper>
                  <Shadow />
                </Slide>
              </SwiperSlide>
              <SwiperSlide onClick={handleAlert}>
                <Slide>
                  <SlideImg src={ReviewImg1} />
                  <SlideContentWrapper>
                    <SlideTime>Mind-Body-Spirit</SlideTime>
                    <SlideText>壓力管理和情緒健康研討會</SlideText>
                  </SlideContentWrapper>
                  <Shadow />
                </Slide>
              </SwiperSlide>
              <SwiperSlide onClick={handleAlert}>
                <Slide>
                  <SlideImg src={ReviewImg2} />
                  <SlideContentWrapper>
                    <SlideTime>Mind-Body-Spirit</SlideTime>
                    <SlideText>冥想與心靈平靜工作坊</SlideText>
                  </SlideContentWrapper>
                  <Shadow />
                </Slide>
              </SwiperSlide>
              <SwiperSlide onClick={handleAlert}>
                <Slide>
                  <SlideImg src={ReviewImg3} />
                  <SlideContentWrapper>
                    <SlideTime>Fitness and Physical</SlideTime>
                    <SlideText>運動療法：用運動促進身心康復</SlideText>
                  </SlideContentWrapper>
                  <Shadow />
                </Slide>
              </SwiperSlide>
              <SwiperSlide onClick={handleAlert}>
                <Slide>
                  <SlideImg src={ReviewImg4} />
                  <SlideContentWrapper>
                    <SlideTime>Fitness and Physical</SlideTime>
                    <SlideText>運動與社交：運動隊伍和社交活動的聚會</SlideText>
                  </SlideContentWrapper>
                  <Shadow />
                </Slide>
              </SwiperSlide>
            </Swiper>
          </SlideContainer>
        </SlideWrapper>
        {/* <ButtonWrapper>
          <Button text={"See More"} />
        </ButtonWrapper> */}
      </Container>
    </Wrapper>
  );
};

export default Review;
