import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../utils/rwd";

const Wrapper = styled.div``;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
  `)};
`;

const LabelEl = styled.label`
  font-size: clamp(16px, 1.25vw, 24px);
  text-align: left;
  color: ${({ theme }) => theme.colors.titleBrown};

  ${RWD_SM(css`
    padding-bottom: 5px;
  `)};
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.subBrown};
  border-radius: 5px;

  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  max-height: 40px;
`;

const RadioEl = styled.input.attrs({ type: "radio" })`
  display: none;

  & + label:before {
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    padding: 3px;
    background-clip: content-box;
    border: 1px solid ${({ theme }) => theme.colors.subBrown};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
  }

  &:checked + label:before {
    background-color: ${({ theme }) => theme.colors.subBrown};
  }
`;

const RadioLabelEl = styled.label`
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 100%;
  cursor: pointer;
`;

const RadioButton = ({ title, onChangeFun, value }) => {
  return (
    <Wrapper>
      <Container>
        <LabelEl>{title}</LabelEl>
        <InputWrapper>
          <RadioContainer>
            <RadioEl
              name="radio"
              value="first"
              id="first"
              checked={value === "first"}
              onChange={(e) => onChangeFun(e.target.value)}
            ></RadioEl>
            <RadioLabelEl htmlFor={"first"}>初診</RadioLabelEl>
          </RadioContainer>
          <RadioContainer>
            <RadioEl
              name="radio"
              value="follow"
              id="follow"
              checked={value === "follow"}
              onChange={(e) => onChangeFun(e.target.value)}
            ></RadioEl>
            <RadioLabelEl htmlFor={"follow"}>複診</RadioLabelEl>
          </RadioContainer>
        </InputWrapper>
      </Container>
    </Wrapper>
  );
};

export default RadioButton;
