import styled, { css } from "styled-components";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Wrapper = styled.div``;

const Editor = ({ contentData, setContent }) => {
  const handleEditContent = (e, editor) => {
    setContent(editor.getData());
  };

  return (
    <Wrapper>
      <CKEditor
        editor={ClassicEditor}
        data={contentData}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(e, editor) => handleEditContent(e, editor)}
        // onBlur={(event, editor) => {
        //   console.log("Blur.", editor);
        // }}
        // onFocus={(event, editor) => {
        //   console.log("Focus.", editor);
        // }}
      />
    </Wrapper>
  );
};

export default Editor;
