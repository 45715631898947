import { styled, keyframes, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";
import Icon from "../../../global/Icon";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect, useState, useCallback, useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import parse from "html-react-parser";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: clamp(300px, 64.0625vw, 1230px);
  padding-bottom: 80px;

  ${RWD_LL(css`
    width: 83.22vw;
  `)};

  ${RWD_MD(css`
    padding-top: 30px;
    padding-bottom: 60px;
  `)};
`;

const TitleWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 80px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: left;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const ContentWrapper = styled.div`
  /* padding-bottom: 80px; */
  text-align: justify;
  line-height: 2.5;

  ${RWD_SM(css`
    padding-top: 20px;
    padding-bottom: 40px;
  `)};
`;

const FunctionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FunctionList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
  `)};
`;

const FunctionItem = styled.div`
  width: 100%;
  padding: 30px 25px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.gray};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.titleBrown};

  ${RWD_SM(css`
    padding: 15px 15px;
  `)};

  &:first-child {
    grid-column: 1 / span 2;

    ${RWD_SM(css`
      grid-column: 1 / 1;
    `)};
  }

  &:nth-child(1) {
    align-items: center;

    ${RWD_SM(css`
      align-items: flex-start;
    `)};

    div:nth-child(2) {
      width: 100%;

      align-items: center;
    }
  }

  &:nth-child(3),
  &:nth-child(5) {
    align-items: end;

    ${RWD_SM(css`
      align-items: flex-start;
    `)};
  }

  &:nth-child(4) {
    div:nth-child(2) {
      p:first-child {
        width: 85%;

        ${RWD_SM(css`
          width: 100%;
        `)};
      }
      width: 100%;
    }
  }

  &:nth-child(5) {
    div:nth-child(2) {
      p:first-child {
        width: fit-content;
        align-self: flex-end;

        ${RWD_SM(css`
          width: 100%;
        `)};
      }
      width: 100%;
    }
  }
`;

const FunctionTitle = styled.div`
  font-size: clamp(20px, 1.25vw, 24px);
  margin-bottom: 15px;
  /* text-align: left; */
  font-weight: 700;

  /* border: 1px solid rebeccapurple; */
`;

const FunctionContent = styled.div`
  text-align: justify;
  display: flex;

  flex-direction: column;
  width: calc(100% - calc(clamp(280px, 18vw, 350px) / 2) + 10px);

  p {
    margin: 0;
  }

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const FunctionCircle = styled.ul`
  width: clamp(280px, 18vw, 350px);
  height: clamp(280px, 18vw, 350px);
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.mainBrown};
  position: absolute;
  left: 50%;
  top: 50%;
  shape-outside: circle(50%);
  float: left;
  transform: translateX(-50%) translateY(-50%);
  margin: 0;
  padding: 0;
  background: ${({ theme }) => theme.colors.white};

  ${RWD_SM(css`
    display: none;
  `)};

  /* background-color: aliceblue; */
`;

const CircleItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  align-items: center;
  left: 50%;
  width: 10px;
  height: 10px;
  /* border: 1px solid ${({ theme }) => theme.colors.white}; */
  margin-left: -5px;
  margin-top: -5px;
  background-color: ${({ theme }) => theme.colors.subBrown};
  border-radius: 50%;

  /* left: 50%; */

  &:nth-of-type(1) {
    /* transform: rotate(72deg);
    transform-origin: 20px 220px; */
  }

  &:nth-of-type(2) {
    transform: rotate(72deg);
    transform-origin: 5px calc(clamp(280px, 18vw, 350px) / 2 + 5px);
  }

  &:nth-of-type(3) {
    transform: rotate(144deg);
    transform-origin: 5px calc(clamp(280px, 18vw, 350px) / 2 + 5px);
  }

  &:nth-of-type(4) {
    transform: rotate(216deg);
    transform-origin: 5px calc(clamp(280px, 18vw, 350px) / 2 + 5px);
  }

  &:nth-of-type(5) {
    transform: rotate(288deg);
    transform-origin: 5px calc(clamp(280px, 18vw, 350px) / 2 + 5px);
  }
`;

const BodyImg = styled.div`
  position: absolute;
  display: inline-flex;
  left: 50%;
  top: 50%;
  shape-outside: circle(50%);
  float: left;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;

  svg {
    width: clamp(220px, 15vw, 280px);
    height: clamp(220px, 15vw, 280px);
  }

  & path {
    fill: ${({ theme }) => theme.colors.titleBrown};
  }
`;

const PriceWrapper = styled.div`
  padding-top: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PriceContainer = styled.div`
  width: 85%;
`;

const PriceCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.copyRight};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.gray};
  cursor: pointer;
  height: 100%;
  margin-right: 1px;
  flex: 1;
`;

const PriceCardInfo = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.titleBrown};
  min-height: 220px;
  height: auto;

  /* justify-content: space-between; */
`;

const PriceCardTitle = styled.div`
  font-size: clamp(22px, 1.302vw, 25px);
  font-weight: 600;
  margin-bottom: 10px;
  flex: 1;
  display: flex;
  align-items: center;
`;

const PriceCardContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  height: 100%;
`;

const PriceCardContent = styled.ul`
  text-align: left;
  padding-left: 15px;
  font-size: clamp(14px, 0.78125vw, 15px);
  width: 80%;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const PriceCardContentItem = styled.li`
  padding: 3px 0;
`;

const PriceCardPriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  padding-bottom: 10px;

  /* flex-direction: column; */
`;

const PriceCardPrice = styled.div`
  font-size: clamp(35px, 2.34365vw, 45px);
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts[2]};
  color: ${({ theme }) => theme.colors.titleBrown};
  margin-bottom: -1vw;

  ${RWD_SM(css`
    margin-bottom: -3.2vw;
  `)};
  span {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
  }
`;

const PriceCardUnit = styled.div`
  font-size: 14px;
`;

const PriceCardButton = styled.div`
  width: 60%;
  padding: clamp(14px, 0.729vw, 16px) clamp(23.5px, 1.041vw, 45px);
  background-color: ${({ theme }) => theme.colors.subBrown};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

const NavigationButtonLeft = styled.button`
  width: clamp(20px, 3.33vw, 50px);
  height: clamp(20px, 3.33vw, 50px);
  position: relative;
  z-index: 5;
  background: ${({ theme }) => theme.colors.gray};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: scaleX(-1);
  cursor: pointer;
  right: 2vw;
  border: none;

  svg {
    width: 1.25vw;
    height: 1.25vw;
  }

  & path {
    stroke: ${({ $isEnd }) => ($isEnd ? "#BBAF95" : "")};
  }

  ${RWD_SM(css`
    display: none;
  `)};
`;

const NavigationButtonRight = styled(NavigationButtonLeft)`
  transform: scaleX(1);
  left: 2vw;
  /* margin-left: 5vw; */
`;

const FunctionalMedicinePage = () => {
  const navigate = useNavigate();
  const functionData = [
    {
      title: "中醫的整體調整",
      content: [
        "功能醫學與中醫共享相同的整體觀念，認為人體是一個互相連接且相互影響的系統。",
        "中醫的診斷方法，如脈診和舌診，可以與功能醫學的詳細生物化學分析相輔相成，提供更全面的健康評估。",
      ],
    },
    {
      title: "西醫的儀器診斷",
      content: [
        "功能醫學利用西醫的先進技術如血液分析、基因檢測等儀器，來精確地找到健康失衡的生物標誌。",
        "這樣的診斷不僅可以用於疾病的早期發現，還可以用來制定個性化的治療計劃。",
      ],
    },
    {
      title: "脊醫的結構調整",
      content: [
        "功能醫學也重視肌肉骨骼系統的健康，認為骨骼的正確排列和功能對整體健康至關重要。",
        "脊醫的技術可以幫助糾正結構異常，從而改善神經系統的功能和整體健康。",
      ],
    },
    {
      title: "營養醫學",
      content: [
        "營養醫學是功能醫學的核心部分之一，強調通過適當的營養來支持和優化代謝及細胞功能。",
        "它基於一個核心理念：適合個體的營養攝入可以直接影響健康狀態和疾病發生的風險。",
        "功能醫學中的營養治療不僅僅關注營養素的補充，更著眼於飲食整體的質量和平衡，並考慮飲食如何與個人的遺傳背景、健康狀態和生活方式互動。",
      ],
    },
    {
      title: "心理學",
      content: [
        "在功能醫學中，心理學的角色同樣至關重要。",
        "心理學不僅關注疾病的心理因素，也研究這些因素如何與身體的其他系統相互作用，進而影響整體健康。",
        "功能醫學認為，情緒和心理狀態對免疫功能、荷爾蒙平衡及整體健康有深刻影響。例如，壓力和焦慮不僅影響心理健康，還可能導致消化問題、心血管疾病等身體健康問題。",
      ],
    },
  ];

  const planList = [
    {
      id: 1,
      title: "基礎PDA生物共振檢測",
      content: [
        "中醫13經絡狀態",
        "影響健康的情緒因素",
        "血糖及慢性敏感食物",
        "肝腎功能及微量元素檢測",
        "改善策略及營養攝取建議",
      ],
      price: 800,
      unit: "小時",
    },
    {
      id: 2,
      title: "基礎TWF頻率檢測及治療",
      content: [
        "TWF細胞頻率檢測及治療<br />（針對性檢測）",
        "能量場距離檢測",
        "微電流細胞導通檢測",
        "改善策略及營養攝取建議",
      ],
      price: 800,
      unit: "小時",
    },
    {
      id: 3,
      title: "進階PDA+TWF生物共振檢測",
      content: [
        "各器官健康隱患",
        "中醫13經絡狀態",
        "影響健康的情緒因素",
        "血糖及慢性敏感食物",
        "肝腎功能及微量元素檢測",
        "影響健康的情緒因素",
        "TWF細胞頻率檢測<br />（針對性檢測）",
        "能量場距離檢測",
        "微電流細胞導通檢測",
        "改善策略及營養攝取建議",
      ],
      price: 1800,
      unit: "1.5 小時",
    },
    {
      id: 4,
      title: "基礎AI V-Sio 人工智能3D體態分析",
      content: ["AI骨骼及肌肉平衡檢測", "改善策略及營養攝取建議"],
      price: 350,
      unit: "次",
    },
    {
      id: 5,
      title: "進階AI V-Sio 人工智能3D體態分析",
      content: [
        "AI骨骼及肌肉平衡檢測",
        "AI骨骼及肌肉動態檢測",
        "改善策略及營養攝取建議",
      ],
      price: 450,
      unit: "次",
    },
    {
      id: 6,
      title: "整體綜合PDA+TWF+AI V-Sio 人工智能生物共振頻率檢測",
      content: [
        "各器官健康隱患",
        "中醫13經絡狀態",
        "7輪能量圖",
        "影響健康的情緒因素",
        "血糖及慢性敏感食物",
        "重金屬及排毒系統",
        "肝腎功能及微量元素檢測",
        "癌症及腫瘤風險",
        "影響健康的情緒因素",
        "TWF細胞頻率檢測<br />（針對性檢測）",
        "能量場距離檢測",
        "微電流細胞導通檢測",
        "AI骨骼及肌肉平衡檢測",
        "改善策略及營養攝取建議",
      ],
      price: 2800,
      unit: "2.5 小時",
    },
    {
      id: 7,
      title: "7輪能量圖",
      content: ["7輪能量圖", "影響健康的情緒因素", "  改善策略及營養攝取建議"],
      price: 800,
      unit: "小時",
    },
  ];

  const windowSize = useOutletContext();
  const [swiperView, setSwiperView] = useState(3);

  useEffect(() => {
    if (windowSize[0] <= "1024") {
      setSwiperView(2);
      if (windowSize[0] <= "430") {
        setSwiperView(1);
      }
    } else {
      setSwiperView(3);
    }
  }, [windowSize]);

  const pagination = {
    el: ".swiper-pagination",
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + "</span>";
    },
  };

  const sliderRef = useRef(null);

  const [isEnd, setIsEnd] = useState(false);
  const [isBeginning, setIsBeginning] = useState(true);

  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    if (sliderRef.current?.swiper.isBeginning) {
      setIsBeginning(true);
    } else {
      setIsBeginning(false);
    }

    if (sliderRef.current?.swiper.isEnd) {
      setIsEnd(true);
    } else {
      setIsEnd(false);
    }
  }, [slideIndex]);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    setSlideIndex(sliderRef.current?.swiper.realIndex);
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    setSlideIndex(sliderRef.current?.swiper.realIndex);
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>功能醫學</Title>
        </TitleWrapper>
        <ContentWrapper>
          <p>
            功能醫學提供了一種全新的醫療模式，專注於慢性病的根本原因，並整合了多學科的方法來創建一個全面的健康管理計劃。這不僅包括現代西醫的儀器診斷，還融合了中醫的整體調整、脊醫的結構矯正、營養醫學的精確補充以及心理學的深入洞察。
          </p>
        </ContentWrapper>

        <FunctionContainer>
          <FunctionList>
            {functionData.map((data) => (
              <FunctionItem>
                <FunctionTitle>{data.title}</FunctionTitle>
                <FunctionContent>
                  {data.content.map((item) => (
                    <p>{item}</p>
                  ))}
                </FunctionContent>
              </FunctionItem>
            ))}
          </FunctionList>

          <FunctionCircle>
            <CircleItem />
            <CircleItem />
            <CircleItem />
            <CircleItem />
            <CircleItem />

            <BodyImg>
              <Icon.Body />
            </BodyImg>
          </FunctionCircle>
        </FunctionContainer>

        <ContentWrapper>
          <p>
            這些整合性的方法讓功能醫學能夠提供一種更全面、更深入的治療方式，不僅僅是緩解症狀，而是透過改變生活方式、環境和營養攝取來徹底改善健康。功能醫學的目標是使每個人達到最佳的生理和心理狀態，這不僅可以預防疾病，也能改善生活質量，實現真正的健康壽命。
          </p>
        </ContentWrapper>

        <PriceWrapper>
          <NavigationButtonLeft onClick={handlePrev} $isEnd={isBeginning}>
            <Icon.Slide_arrow />
          </NavigationButtonLeft>
          <PriceContainer>
            <Swiper
              spaceBetween={19}
              slidesPerView={swiperView}
              ref={sliderRef}
              pagination={pagination}
              modules={[Navigation, Pagination]}
              autoHeight={true}
            >
              {planList.map((data) => (
                <SwiperSlide key={data.id}>
                  <PriceCard onClick={() => navigate("/appointment")}>
                    <PriceCardInfo>
                      <PriceCardTitle>{data.title}</PriceCardTitle>
                      <PriceCardPriceWrapper>
                        <PriceCardPrice>
                          <span>$</span>
                          {data.price}
                        </PriceCardPrice>
                        <PriceCardUnit> / {data.unit}</PriceCardUnit>
                      </PriceCardPriceWrapper>
                    </PriceCardInfo>
                    <PriceCardContentWrapper>
                      <PriceCardContent>
                        {data.content.map((item) => (
                          <PriceCardContentItem>
                            {parse(item)}
                          </PriceCardContentItem>
                        ))}
                      </PriceCardContent>
                    </PriceCardContentWrapper>

                    <PriceCardButton onClick={() => navigate("/appointment")}>
                      立即預約
                    </PriceCardButton>
                  </PriceCard>
                </SwiperSlide>
              ))}
            </Swiper>
          </PriceContainer>
          <NavigationButtonRight onClick={handleNext} $isEnd={isEnd}>
            <Icon.Slide_arrow />
          </NavigationButtonRight>
        </PriceWrapper>
      </Container>
    </Wrapper>
  );
};

export default FunctionalMedicinePage;
