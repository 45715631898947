import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../utils/rwd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useRef, forwardRef, useEffect } from "react";
import { CiCalendar } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import { addDays, getDay, setHours, setMinutes } from "date-fns";
import { useGetConsultationTimeMutation } from "../services/consultationTimeApi";
import { AppointmentSwal } from "./MySwal";
import { isSameDay } from "../utils";

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
  `)};
`;

const LabelEl = styled.label`
  font-size: clamp(16px, 1.25vw, 24px);
  text-align: left;
  color: #43310b;

  ${RWD_SM(css`
    padding-bottom: 5px;
  `)};
`;

const CalendarContainer = styled.button`
  border: 1px solid;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  background: #fefefe;
  padding: 0 5px;
  border-radius: 5px;
  border-color: #b77d38;

  /* border-color: ${(props) =>
    props.error ? "#0aecd1" : "rgba(255, 255, 255, 0.15)"}; */
`;

const IconContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-size: 22px;
  color: #b77d38;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 10px;
  /* font-size: 0.729vw; */
`;

const Placeholder = styled(Content)`
  color: #999;
`;

const Calendar = ({
  placeholder,
  onChangeFun,
  selectDate,
  title,
  appointmentType,
  disabled,
  doctor,
}) => {
  const dateInputRef = useRef(null);
  const [availableTime, setAvailableTime] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [includeTimes, setIncludeTimes] = useState([]);
  const [currentMonth, setCurrnetMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [_getConsultation, { data, isSuccess, isError, error, isLoading }] =
    useGetConsultationTimeMutation();

  // 初診 Type 1
  // 複診 Type 2
  const handleGetConsultation = async () => {
    const data = {
      DoctorId: doctor,
      Type: appointmentType === "follow" ? 2 : 1,
      Year: currentYear,
      Month: currentMonth,
    };

    await _getConsultation(data);
  };

  useEffect(() => {
    if (isSuccess) {
      setAvailableTime(data.consultationTimes);
    }

    if (isError) {
      console.log(error.data);
    }
  }, [data, isError]);

  // 初診: 2個小時
  // 複診: 1.5個小時
  useEffect(() => {
    console.log("刷新");
    if (!doctor) return;
    handleGetConsultation();
    setIncludeTimes([]);
  }, [appointmentType, currentMonth, currentYear, doctor]);

  useEffect(() => {
    if (isOpen) {
      console.log("打開");
      handleGetConsultation();
      console.log(selectDate);
      // setCurrentYear(new Date().getFullYear());
      // setCurrnetMonth(new Date().getMonth() + 1);
    } else {
      console.log("關閉");
      if (selectDate) {
        // if (selectDate.getHours() === 0) {
        //   AppointmentSwal({
        //     title: "請選擇看診時間",
        //     icon: "warning",
        //     confirmButtonText: "確定",
        //     confirmButtonColor: "#009A63",
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //       onChangeFun(null);
        //     }
        //   });
        // }
        setCurrentYear(selectDate?.getFullYear());
        setCurrnetMonth(selectDate?.getMonth() + 1);
      } else {
        setCurrentYear(new Date().getFullYear());
        setCurrnetMonth(new Date().getMonth() + 1);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    setCurrnetMonth(new Date().getMonth() + 1);
  }, [appointmentType, doctor]);

  const handleCalendarClose = () => {
    setIsOpen(false);
  };
  const handleCalendarOpen = () => {
    setIsOpen(true);
  };

  const onDateChange = (date) => {
    // 清空日期
    onChangeFun(null);
    const Time = availableTime.filter((data) => data.date === dateFormat(date));

    setIncludeTimes(
      Time[0].hours.map((data) =>
        setHours(
          setMinutes(
            new Date(date),
            data.split(":")[1] === "00" ? 0 : data.split(":")[1]
          ),
          data.split(":")[0]
        )
      )
    );
    const defaultDateTime = new Date(`${Time[0].date} ${Time[0].hours[0]}`);
    const availableHours = Time[0].hours;

    if (!availableHours.includes(timeFormat(date))) {
      onChangeFun(defaultDateTime);
    } else {
      onChangeFun(date);
    }
  };

  const dateFormat = (value) => {
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, "0");
    const date = value.getDate().toString().padStart(2, "0");
    return `${year}/${month}/${date}`;
  };

  const timeFormat = (value) => {
    const hour = value.getHours().toString().padStart(2, "0");
    const minute = value.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
  };

  const isWeekday = (date) => {
    const formattedDate = dateFormat(date);
    return availableTime.map(({ date }) => date).indexOf(formattedDate) !== -1;
  };

  const DateInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <CalendarContainer onClick={onClick} ref={ref} disabled={disabled}>
        {value ? (
          <Content>{value}</Content>
        ) : (
          <Placeholder>
            {!disabled ? placeholder : "請先選擇診療師"}
          </Placeholder>
        )}

        <IconContainer>
          {selectDate === null ? (
            <CiCalendar />
          ) : (
            <IoIosClose onClick={() => onChangeFun(null)} />
          )}
        </IconContainer>
      </CalendarContainer>
    );
  });

  return (
    <Wrapper>
      <LabelEl>{title}</LabelEl>

      <DatePicker
        selected={selectDate}
        onChange={(date) => onDateChange(date)}
        onMonthChange={(date) => setCurrnetMonth(date.getMonth() + 1)}
        onYearChange={(date) => setCurrentYear(date.getFullYear())}
        dateFormat="dd/MM/yyyy h:mm aa"
        onCalendarClose={handleCalendarClose}
        onCalendarOpen={handleCalendarOpen}
        customInput={<DateInput ref={dateInputRef} isOpen={isOpen} />}
        showTimeSelect
        timeFormat="HH:mm"
        // peekNextMonth
        fixedHeight
        dropdownMode="select"
        maxDate={addDays(new Date(), 180)}
        minDate={new Date()}
        includeTimes={includeTimes}
        filterDate={isWeekday}
        disabled={disabled}
        shouldCloseOnSelect={false}
      />
    </Wrapper>
  );
};

export default Calendar;
