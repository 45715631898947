import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";

const frontendBaseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API}`,
  prepareHeaders: (headers) => {
    return headers.set("content-type", "application/json");
  },
  // prepareHeaders: (headers) => {
  //   const token = getAuthToken().token;
  //   if (token) {
  //     headers.set("authorization", `Bearer ${token}`);
  //   }
  //   headers.set("content-type", "application/json");
  //   return headers;
  // },
});

export default frontendBaseQuery;
