import { styled } from "styled-components";
import { useState, useEffect } from "react";
import Input from "../../../global/Input";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useGetDoctorInfoQuery } from "../../../services/doctorApi";
import Button from "../../../global/Button";
import {
  useGetDoctorListQuery,
  useEditDoctorMutation,
} from "../../../services/doctorApi";
import { ShiftSwal, DefaultSwal, ShifCancelSwal } from "../../../global/MySwal";
import { TiDelete } from "react-icons/ti";
import { useParams, useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  width: 95%;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
`;

const Content = styled.div`
  border: 1px sienna rebeccapurple;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2vw 3vw;
  background-color: rgba(248, 246, 242, 0.5);
`;

const Title = styled.h3`
  text-align: left;
  font-size: 25px;
  margin: 40px 0 20px 0;
  padding-bottom: 20px;
  width: 95%;
  color: ${({ theme }) => theme.colors.titleBrown};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`;

const SubTitleWrapper = styled.div`
  margin: 0 0 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubTitle = styled.h4`
  text-align: left;
  margin: 0;
  font-size: 17px;
  font-weight: 500;
`;

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 10px 10%;
  margin-bottom: 40px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LabelEl = styled.label`
  text-align: left;
  width: 30%;
  color: ${({ theme }) => theme.colors.titleBrown};
  padding: 10px 0;
`;

const InputEl = styled.input`
  padding: 10px 10px;
  border: 1px solid ${({ theme }) => theme.colors.copyRight};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  width: 70%;
`;

const ShiftWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.copyRight};
  background-color: ${({ theme }) => theme.colors.white};
`;

const ShiftList = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px 0;
  list-style: none;
  border-right: 1px solid ${({ theme }) => theme.colors.copyRight};
  padding: 0;
  flex-direction: column;
  margin: 0;

  &:last-child {
    border-right: none;
  }
`;

const ShiftWeekday = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.copyRight};
  width: 100%;
  padding: 8px 0;
  font-size: 15px;
`;

const ShiftItemWrapper = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const ShiftItem = styled.div`
  padding: 5px 10px;
  background-color: white;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.titleBrown};
  border: 1px solid ${({ theme }) => theme.colors.copyRight};
  width: 80%;
  border-radius: 5px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.titleBrown};
  position: relative;
`;

const ShiftDeleteIcon = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  color: ${({ theme }) => theme.colors.red};
  font-size: 25px;
  background-color: ${({ theme }) => theme.colors.gray};
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  ${ShiftItem}:hover & {
    opacity: 1;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
  width: 95%;
`;

const ButtonEl = styled.button`
  border: 1px solid #b77d38;
  background-color: #b77d38;
  padding: 10px 20px;
  border-radius: 0.25rem;
  color: #fcfcfc;
  min-width: 120px;
  cursor: pointer;
`;

const DoctorList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0px;
`;

const DoctorItem = styled.li`
  padding: 8px 20px;

  cursor: pointer;
  border-top: 2px solid
    ${({ $selected, theme }) =>
      $selected ? theme.colors.mainBrown : theme.colors.white};

  background-color: ${({ $selected, theme }) =>
    $selected ? "rgba(248, 246, 242, 0.5)" : theme.colors.white};
  font-weight: ${({ $selected, theme }) => ($selected ? "bold" : "normal")};
  color: ${({ $selected, theme }) =>
    $selected ? theme.colors.titleBrown : theme.colors.titleBrown};
`;

const ShiftButton = styled.button`
  border: 1px solid #f1dec7;
  background-color: #f1dec7;
  padding: 5px 10px;
  border-radius: 0.25rem;
  color: #b77d38;
  min-width: 100px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.3s ease;

  &:hover {
    border: 1px solid #b77d38;
  }
`;

const DoctorInfoPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [doctorId, setDoctorId] = useState("");

  useEffect(() => {
    if (params) {
      setDoctorId(params.doctorId);
    }
  }, [params]);

  const { data, error, isError, isSuccess, refetch, isFetching } =
    useGetDoctorInfoQuery({
      DoctorId: doctorId,
    });

  const [
    _editDoctorInfo,
    {
      data: editDoctorData,
      error: editDoctorError,
      isSuccess: editDoctorIsSuccess,
      isError: editDoctorIsError,
    },
  ] = useEditDoctorMutation();

  useEffect(() => {
    if (editDoctorIsSuccess) {
      DefaultSwal({
        icon: "success",
        confirmButtonText: "確定",
        title: "更新成功",
      });
    }

    if (editDoctorIsError) {
      DefaultSwal({
        icon: "error",
        confirmButtonText: "確定",
        title: "更新失敗",
        text: editDoctorError.data.message,
      });
    }
  }, [editDoctorData, editDoctorIsError]);

  // 取得醫生列表
  const {
    data: doctorData,
    isSuccess: doctorIsSuccess,
    isError: doctorIsError,
    error: doctorError,
  } = useGetDoctorListQuery();

  useEffect(() => {
    if (doctorIsSuccess) {
      setDoctorList(doctorData?.previewDoctorList);
    }
  }, [doctorData, doctorError]);

  const [doctorName, setDoctorName] = useState("");
  const [doctorPhone, setDoctorPhone] = useState("");
  const [doctorEmail, setDoctorEmail] = useState("");
  const [doctorTelegramId, setDoctorTelegramId] = useState("");
  const [consultationTimes, setConsultationTimes] = useState([]);
  const [clearReservation, setClearReservation] = useState(false);
  const [shiftData, setShiftData] = useState([]);
  const [shiftKey, setShiftKey] = useState([7, 1, 2, 3, 4, 5, 6]);
  const [doctorList, setDoctorList] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setDoctorName(data?.doctorInfo?.doctorName ?? "");
      setDoctorPhone(data?.doctorInfo?.doctorPhone ?? "");
      setDoctorEmail(data?.doctorInfo?.doctorEmail ?? "");
      setDoctorTelegramId(data?.doctorInfo?.doctorTelegramId ?? "");
      setConsultationTimes(data?.doctorInfo?.consultationTimes ?? "");

      // const groupedItems = data?.doctorInfo?.consultationTimes.reduce(
      //   (weekday, data) => {
      //     const key = data.weekday;
      //     if (!weekday[key]) weekday[key] = [];
      //     weekday[key].push(data);
      //     return weekday;
      //   },
      //   {}
      // );

      // setShiftData(groupedItems);
    }
  }, [data, isError]);

  const handleDeleteShift = (weekday, start, end) => {
    ShifCancelSwal({
      title: `確定刪除 ${start} - ${end} 時段？`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "取消",
      confirmButtonText: "確認",
    }).then((result) => {
      if (result.isConfirmed) {
        setClearReservation(result.value);
        const filteredShift = consultationTimes.filter(
          (time) =>
            time.weekday !== weekday ||
            time.startTime !== start ||
            time.endTime !== end
        );
        setConsultationTimes(filteredShift);
      }
    });
  };

  const handleCreateShitf = () => {
    ShiftSwal({
      title: "新增營業時段",
      text: "時間格式為24小時制 例如 09:00 或 14:30",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "取消",
      confirmButtonText: "確認",
      consultationTimes: consultationTimes,
    }).then((result) => {
      if (result.isConfirmed) {
        const newShift = {
          weekday: parseInt(result.value[2]),
          startTime: result.value[0],
          endTime: result.value[1],
        };
        setConsultationTimes([...consultationTimes, newShift]);
      }
    });
  };

  const handleUpdateDoctorInfo = () => {
    const data = {
      doctorId: doctorId,
      doctorName: doctorName,
      doctorPhone: doctorPhone,
      doctorEmail: doctorEmail,
      doctorTelegramId: doctorTelegramId,
      consultationTimes: consultationTimes,
      clearReservation: clearReservation === "true" ? true : false,
    };
    _editDoctorInfo(data);
    // DefaultSwal({
    //   title: "確定更新資訊?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   cancelButtonText: "取消",
    //   confirmButtonText: "確認",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //   }
    // });
  };

  const handleClickDoctorTab = (doctorId) => {
    setDoctorId(doctorId);
    navigate(`/backend/doctorInfo/${doctorId}`);
    refetch({
      DoctorId: doctorId,
    });
  };

  return (
    <Wrapper>
      <Title>診療師資訊</Title>
      <Container>
        <DoctorList>
          {doctorList.map((doctor) => (
            <DoctorItem
              $selected={params.doctorId == doctor.doctorId}
              onClick={() => handleClickDoctorTab(doctor.doctorId)}
            >
              {doctor.doctorName}
            </DoctorItem>
          ))}
        </DoctorList>

        <Content>
          <SubTitleWrapper>
            <SubTitle>基本資料</SubTitle>
          </SubTitleWrapper>

          <FormWrapper>
            <InputWrapper>
              <LabelEl>姓名</LabelEl>
              <InputEl
                value={doctorName}
                onChange={(e) => setDoctorName(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <LabelEl htmlFor="tel">電話</LabelEl>
              <InputEl
                id="tel"
                value={doctorPhone}
                onChange={(e) => setDoctorPhone(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <LabelEl htmlFor="email">Email</LabelEl>
              <InputEl
                id="email"
                type="email"
                value={doctorEmail}
                onChange={(e) => setDoctorEmail(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <LabelEl>Telegram ID</LabelEl>
              <InputEl
                value={doctorTelegramId}
                onChange={(e) => setDoctorTelegramId(e.target.value)}
              />
            </InputWrapper>
          </FormWrapper>

          <SubTitleWrapper>
            <SubTitle>營業時段</SubTitle>
            <ShiftButton onClick={handleCreateShitf}>新增營業時段</ShiftButton>
          </SubTitleWrapper>

          <ShiftWrapper>
            {shiftKey.map((data) => (
              <ShiftList key={data}>
                <ShiftWeekday>
                  {(() => {
                    switch (data) {
                      case 1:
                        return "星期一";
                      case 2:
                        return "星期二";
                      case 3:
                        return "星期三";
                      case 4:
                        return "星期四";
                      case 5:
                        return "星期五";
                      case 6:
                        return "星期六";
                      case 7:
                        return "星期日";
                    }
                  })()}
                </ShiftWeekday>
                {consultationTimes
                  .filter((time) => time.weekday == data)
                  ?.map((el) => (
                    <ShiftItemWrapper>
                      <ShiftItem
                        onClick={() =>
                          handleDeleteShift(data, el.startTime, el.endTime)
                        }
                      >
                        <ShiftDeleteIcon>
                          <TiDelete />
                        </ShiftDeleteIcon>
                        {el.startTime} - {el.endTime}
                      </ShiftItem>
                    </ShiftItemWrapper>
                  ))}
              </ShiftList>
            ))}
          </ShiftWrapper>
        </Content>
      </Container>
      <ButtonWrapper>
        <ButtonEl onClick={handleUpdateDoctorInfo}>儲存</ButtonEl>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default DoctorInfoPage;
