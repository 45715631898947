import styled, { css } from "styled-components";
import CreatableSelect from "react-select/creatable";

const Wrapper = styled.div`
  border: 1px solid blue;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 5;
  position: relative;
`;

const Title = styled.div`
  display: flex;
`;

// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" },
// ];

const Select = ({ optionData, setSelectedOption, title, selectedOption }) => {
  console.log(optionData);

  return (
    <Wrapper>
      <Title>{title}</Title>
      <CreatableSelect
        isMulti
        options={optionData}
        defaultValue={selectedOption}
        onChange={setSelectedOption}
      />
    </Wrapper>
  );
};

export default Select;
