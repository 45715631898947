import { styled, ThemeProvider } from "styled-components";
import theme from "../../../global/FrontendTheme";

// components
import Banner from "../components/Banner";
import News from "../components/News";
import Event from "../components/Event";
import Review from "../components/Review";
import Media from "../components/Media";
import AppointmentButton from "../components/AppointmentButton";
import TestBanner from "../components/TestBanner";
import AnimatedBanner from "../components/AnimatedBanner";

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { scroller } from "react-scroll";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
`;

const Container = styled.div`
  /* border: 1px solid yellow; */
`;

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.anchor) {
      scroller.scrollTo(location.state?.anchor, {
        duration: 1500,
        delay: 100,
        smooth: "easeInOutQuad",
      });
      navigate(location.pathname, { replace: true });
    }
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Container>
          <Banner />
          {/* <AnimatedBanner /> */}
          {/* ADX NEWS */}
          <News />
          {/* 近期活動 */}
          <Event />
          {/* 活動回顧 */}
          <Review />
          {/* 媒體報導 */}
          <Media />
          <AppointmentButton />
        </Container>
      </Wrapper>
    </ThemeProvider>
  );
};

export default HomePage;
