const theme = {
  colors: {
    mainBrown: "#8B7442",
    subBrown: "#B77D38",
    titleBrown: "#43310B",
    lightBrown: "#F1DEC7",
    tagBrown: "#AA5E19",
    white: "#FEFEFE",
    gray: "#F8F6F2",
    copyRight: "#BBAF95",
    yellow: "#FFB71B",
    blue: "#295EE8",
    red: "#AB3B3A",
  },
  fonts: ["Noto Sans TC", "Montserrat", "Poppins"],
  fontSize: {
    sectionTitle: "40px",
  },
};

export default theme;
