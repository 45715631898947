import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";

import Banner from "../../../../images/functionalDiagnosticNutrition/AFDNP-Logo.jpg";
import ADXBNI from "../../../../images/functionalDiagnosticNutrition/ADX BNI 6mins 20190806_Page_02.jpg";

import logo_1 from "../../../../images/functionalDiagnosticNutrition/wellness-mama.jpg";
import logo_2 from "../../../../images/functionalDiagnosticNutrition/the-whole-journey.png";
import logo_3 from "../../../../images/functionalDiagnosticNutrition/chek-institute.png";
import logo_4 from "../../../../images/functionalDiagnosticNutrition/underground-wellness.jpg";
import logo_5 from "../../../../images/functionalDiagnosticNutrition/hwba-health-and-wellness-business-association.png";
import logo_6 from "../../../../images/functionalDiagnosticNutrition/Bulletproof.png";
import logo_7 from "../../../../images/functionalDiagnosticNutrition/nanp.png";
import logo_8 from "../../../../images/functionalDiagnosticNutrition/SCD-Lifestyle.jpg";
import logo_9 from "../../../../images/functionalDiagnosticNutrition/PaleoFx-logo.png";
import logo_10 from "../../../../images/functionalDiagnosticNutrition/Dr-Ben-Lynch.jpg";
import logo_11 from "../../../../images/functionalDiagnosticNutrition/Aon_Logo_Tagline_RGB_Red.jpg";
import logo_12 from "../../../../images/functionalDiagnosticNutrition/AHS.png";
import logo_13 from "../../../../images/functionalDiagnosticNutrition/Quantum.jpg";
import logo_14 from "../../../../images/functionalDiagnosticNutrition/FDN-Vertical-Logo.png";

import Button from "../../../../global/Button";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: clamp(300px, 64.0625vw, 1230px);

  ${RWD_LL(css`
    width: 83.22vw;
  `)};
`;

const TitleWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 80px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: left;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const TopImgWrapper = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  /* border: 1px solid ${({ theme }) => theme.colors.copyRight}; */
  display: inline-flex;
  /* background: rgba(187, 175, 149, 0.1); */

  img {
    object-fit: cover;

    /* object-position: center; */
  }

  ${RWD_SM(css`
    height: 51.667vw;

    img {
      height: 51.667vw;
      object-fit: cover;
    }
  `)};
`;

const SubTitle = styled.h3`
  font-size: clamp(20px, 1.875vw, 36px);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
  text-align: left;
`;

const ContentWrapper = styled.div``;

const ArticleWrapper = styled.div``;

const ArticleContainer = styled.div`
  margin-bottom: 50px;
`;

const InnerTitle = styled.h5`
  font-size: clamp(18px, 1.145833vw, 22px);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.mainBrown};
  text-align: left;
  margin-top: 0;
`;

const ListWrapper = styled.ul`
  display: grid;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 50px;

  ${RWD_MD(css`
    grid-template-columns: repeat(3, 1fr);
  `)};

  ${RWD_SM(css`
    grid-template-columns: repeat(2, 1fr);
  `)};
`;

const ListItem = styled.li`
  border: 1px solid rebeccapurple;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.copyRight};
  font-weight: 600;
  border-radius: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${RWD_SM(css`
    padding: 10px;
  `)};
`;

const RecognizedWrapper = styled.div`
  padding-top: 25px;
`;

const RecognizedContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 50px 0;
`;

const RecognizedImg = styled.div`
  /* border: 1px solid rebeccapurple; */
  width: 20%;

  ${RWD_SM(css`
    width: 30%;
  `)};
`;

const LinkWrapper = styled.div`
  padding-bottom: 120px;
`;

const InfoList = styled.ul`
  list-style: none;
  padding: 0;
`;

const InfoItem = styled.li`
  text-align: center;
  padding: 10px 0;
  a {
    color: #1c1c1c;

    &:hover {
      color: ${({ theme }) => theme.colors.mainBrown};
    }
  }
`;

const FunctionalDiagnosticNutritionPage = () => {
  const introData = [
    {
      id: 1,
      title: "What is FDN ?",
      content: [
        "Functional Diagnostic Nutrition (FDN) is a type of detective work that seeks to identify the underlying causes of disease instead of treating symptoms. Using functional lab work, we identify healing opportunities and engage each client in a health building process using the potent, proven, professional protocols in our D.R.E.S.S. for Health Success program. This includes Diet, Rest, Exercise, Supplements and Stress Reduction. This natural, holistic approach yields the highest level of positive clinical outcomes. We don’t medicate, we educate!",
      ],
    },
    {
      id: 2,
      title: "D.R.E.S.S. for Health Success",
      content: [
        "DIET: This doesn't just mean filling out a food diary. Here we talk about not only what you eat, but HOW you eat. Where do you source your food. Are you eating on the run? Are you digesting well? Do you have cravings? Do you over or under eat? Are you getting sufficient micro and macro nutrients? If you aren't supplying your body with the nutrition it needs AND assimilating those nutrients, you won't ever reach optimal health.",
        "REST: Not just are you sleeping, but how well, how long, how consistently are you sleeping? Do you have a good bedtime routine? Do you wake much through the night? Do you take a long time to get to sleep? Do you wake rested? Do you overdo caffeine? Are you hydrated? Think you don't have time to sleep? You also don't have time to be sick!",
        "EXERCISE: Do you exercise at all? If so, are you getting the best type of workout for you? Is it challenging enough or too hard? Are you recovering from your workouts? Are you in a workout rut? Are you causing yourself more stress with exercising in excess? Figuring out how to exercise depending on your current needs and goals is not easy, and always a moving target!",
        "STRESS management: Stress is a natural and necessary part of life, but how you handle life's stressors is critical. How to do you shut down and regenerate yourself? Do you efficiently manage stressors and then allow yourself to step away? Do you internalize stressors?",
        "SUPPLEMENTATION:  This one is a little more oblique.  It's more about filling in the gaps and getting you a little relief in the short-term.  The reality is that it's not that easy to get all the nutrients we need from diet alone, so I can help you selectively find a few things to help you maximize your nutrition as well increase assimilation of those nutrients.",
      ],
    },
    {
      id: 3,
      title: "FDN services",
      content: [
        "Functional Diagnostic Nutrition® (FDN) provides a framework and logical, step by step method of restoring and maintaining health. FDN Practitioners use functional lab testing to identify malfunctions in the body instead of just treating the symptoms. We avoid diagnosing and treating specific disease and simply “build health” instead. This natural approach allows us to help just about anybody with a chronic stress-ralated health condition. We’re doing a world of good!",
        "Our view of symptoms like headaches, sleeplessness, fatigue, sinuses and allergies, joint and muscle pain, bad skin, gastric bloating and every other health complaint, is that they all have causal factors to be investigated and resolved at the functional level. Even in tough cases, opportunities for repair, restoration, rebuilding and healing can be abundant and, in our mind, the opportunities to employ FDN methods are equally apparent.",
        "FDN is a type of detective work that seeks to identify and correct the underlying causes and conditions that lead to the patients’ health complaints. In that sense, FDN is not diagnosing or treating any disease nor practicing medicine.",
        "FDN methods give licensed and non-licensed providers a leg up on other health systems you may have tried. We call our methods an “Opt-in Self-Care Model” vs the “Disease Care Model” used by modern medicine. Both are important, but different in many respects. You can choose to hire an FDN Practitioner and work on building health and disease prevention, or you may be forced later to acquire medical treatment.",
        "We feel very confident that we can help 100% of the people that we accept as clients in their pursuit of health and healing opportunities. While there are no guarantees (because we do not control outcomes) we very reasonably expect that our clients and patients will make improvements and, indeed, they often completely resolve their health complaints.",
      ],
    },
  ];

  const triedData = [
    "Can’t Lose/Gain Weight?",
    "High Cholesterol?",
    "Muscle Pain?",
    "Joint Pain?",
    "Low Energy?",
    "Foggy Thinking?",
    "Sleep Issues?",
    "Poor Libido?",
    "Depressed Moods?",
    "High Blood Pressure?",
    "Digestive Problems?",
    "Blood Sugar Problems?",
    "Family with Cancer & Diabetes?",
    "Skin & Hair Problems?",
    "Migraines and Headaches?",
    "Sinuses and Allergies?",
  ];

  const malfunctions = [
    "Hormone",
    "Immune",
    "Digestion",
    "Detoxification",
    "Energy Production",
    "Nervous Systems",
  ];

  const logoData = [
    logo_1,
    logo_2,
    logo_3,
    logo_4,
    logo_5,
    logo_6,
    logo_7,
    logo_8,
    logo_9,
    logo_10,
    logo_11,
    logo_12,
    logo_13,
    logo_14,
  ];

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>Functional Diagnostic Nutrition</Title>
        </TitleWrapper>
        <TopImgWrapper>
          <img src={Banner} alt="Functional Diagnostic Nutrition" />
        </TopImgWrapper>

        <TopImgWrapper>
          <img src={ADXBNI} alt="Functional Diagnostic Nutrition" />
        </TopImgWrapper>

        <ArticleWrapper>
          {introData.map((data) => (
            <ArticleContainer key={data.id}>
              <SubTitle>{data.title}</SubTitle>
              <ContentWrapper>
                {data.content.map((item) => (
                  <p>{item}</p>
                ))}
              </ContentWrapper>
            </ArticleContainer>
          ))}

          <InnerTitle>
            Have you “Tried everything” but are still concerned with:
          </InnerTitle>
          <ListWrapper>
            {triedData.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </ListWrapper>
          <InnerTitle>
            Then its time to stop chasing symptoms and start addressing the
            underlying causes!
          </InnerTitle>
          <ContentWrapper>
            <p>
              A Certified FDN Practitioner can provide a functional assessment
              of the most likely culprits. If we accept you as a client or
              patient you will learn to identify and correct malfunctions of
              the:
            </p>
          </ContentWrapper>
          <ListWrapper>
            {malfunctions.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </ListWrapper>
          <InnerTitle>
            Restoring these pillars of health can lead to vibrant health and
            vitality you have not experienced in many years!
          </InnerTitle>
        </ArticleWrapper>

        <RecognizedWrapper>
          <SubTitle>FDN is recognized by</SubTitle>
          <RecognizedContainer>
            {logoData.map((data) => (
              <RecognizedImg>
                <img src={data} />
              </RecognizedImg>
            ))}
          </RecognizedContainer>
        </RecognizedWrapper>
        <LinkWrapper>
          <InfoList>
            <InfoItem>
              If you want to know more about Functional Diagnostic Nutrition
              (FDN) you can click the link below for more information.
            </InfoItem>
            <InfoItem>
              <a
                href="https://www.functionaldiagnosticnutrition.com/"
                target="_blank"
              >
                https://www.functionaldiagnosticnutrition.com/
              </a>
            </InfoItem>
          </InfoList>
        </LinkWrapper>
      </Container>
    </Wrapper>
  );
};

export default FunctionalDiagnosticNutritionPage;
