import { styled, css } from "styled-components";
import { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetArticleListQuery } from "../../../services/articleApi";

import Icon from "../../../global/Icon";

import LogoImg from "../../../images/logo.png";

import Button from "../../../global/Button";
import { Link as ScrollLink } from "react-scroll";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

const Wrapper = styled.div`
  height: 9.25vh;
  height: calc(var(--vh, 1vh) * 9.25);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts[1]};
  position: relative;
  background: ${({ theme }) => theme.colors.white};
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0px 4px 10px 0px rgba(61, 42, 13, 0.05);

  ${RWD_SM(css`
    height: 12.5vh;
    height: calc(var(--vh, 1vh) * 12.5);
  `)};
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;

  ${RWD_LL(css`
    width: 86.11vw;
    justify-content: space-between;
  `)};
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  height: 100%;

  ${RWD_LL(css`
    position: absolute;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
    background: ${({ theme }) => theme.colors.white};
    z-index: 15;
    width: 100%;
    transition: transform 0.5s ease-in-out;
    transform: ${({ $status }) =>
      $status ? "translateX(0%)" : "translateX(100%)"};
  `)};
`;

const MenuContainer = styled.div`
  display: flex;
  height: 100%;

  ${RWD_LL(css`
    padding-top: 10vh;
    padding-top: calc(var(--vh, 1vh) * 10);
    flex-direction: column;
    width: 100%;
    align-items: center;
  `)};
`;

const Menu = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.subBrown};
  position: relative;
  align-items: center;
  margin-right: 3.9vw;
  cursor: pointer;

  &:last-child {
    margin-right: 0vw;
  }

  ${RWD_LL(css`
    margin: 0;
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
    align-items: center;
  `)};

  ${RWD_SM(css`
    align-items: flex-start;
  `)};
`;

const MenuMainWrapper = styled.div`
  display: flex;
  align-items: baseline;

  ${RWD_LL(css`
    padding: 10px 20px;
  `)};
`;

const MenuMainItem = styled.div`
  padding-right: 10px;
  font-weight: bold;
  font-size: clamp(16px, 1.041vw, 20px);
`;

const MenuArrow = styled.div`
  transition: all.3s;
  transform: ${({ $status }) => ($status ? "scaleY(-1)" : "scaleY(1)")};
`;

const MenuItemWrapper = styled.ul`
  position: absolute;
  top: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  z-index: 5;
  list-style-position: outside;
  background: ${({ theme }) => theme.colors.white};
  filter: drop-shadow(3px 3px 10px rgba(61, 42, 13, 0.05));
  /* width: 100%; */
  margin: 0;

  ${RWD_LL(css`
    position: static;
    display: flex;
    filter: none;
  `)};
`;

const MenuItem = styled.li`
  /* width: 100%; */
  padding: 10px 15px;
  font-size: 16px;

  width: auto;
  white-space: nowrap;
  transition: all 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.subBrown};
  }

  ${RWD_LL(css`
    padding: 20px 40px;
    /* border-bottom: 1px solid ${({ theme }) => theme.colors.subBrown}; */
    text-align: center;
  `)};

  ${RWD_SM(css`
    /* border-bottom: 1px solid ${({ theme }) => theme.colors.subBrown}; */
    text-align: left;
  `)};
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* padding-left: 8.5935vw; */
`;

const Logo = styled.img`
  width: 6.041vw;

  ${RWD_LL(css`
    width: 15vw;
  `)};

  ${RWD_SM(css`
    width: 18.33vw;
  `)};
`;

const HamburgerMenuList = styled.div`
  flex-direction: column;
  align-items: center;
  height: 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  z-index: 99;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.6s;
  padding-left: 30px;
`;

const HamburgerMenuItem = styled.span`
  display: inline-block;
  width: 32px;
  background: ${({ theme }) => theme.colors.subBrown};
  height: 2px;
  transition: all 0.2s ease-in-out;
  transform-origin: center center;

  &:nth-of-type(1) {
    transition-delay: 0.1s;
    width: 16px;
    align-self: flex-start;
    transform-origin: top;
    transform: ${({ $status }) =>
      $status
        ? "rotate(45deg) translateX(5px) translateY(0px)"
        : "rotate(0deg) translateX(0px) translateY(0px)"};
  }

  &:nth-of-type(2) {
    transform-origin: center;
    transform: ${({ $status }) =>
      $status ? "rotate(-45deg)" : "rotate(0deg)"};
  }

  &:nth-of-type(3) {
    transition-delay: 0.3s;
    width: 16px;
    transform-origin: bottom;
    align-self: flex-end;
    transform: ${({ $status }) =>
      $status
        ? "rotate(45deg) translateX(-5px) translateY(-1px)"
        : "rotate(0deg) translateX(0px) translateY(0px)"};
  }
`;

const UserIcon = styled.div`
  height: 45px;
  width: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 45px;
    width: 45px;
  }

  ${RWD_SM(css`
    width: 32px;
    height: 32px;

    svg {
      width: 32px;
      height: 32px;
    }
  `)};
`;

const MobWrapper = styled.div`
  display: none;

  ${RWD_LL(css`
    display: flex;
    align-items: center;
  `)};
`;

const SignInWrapper = styled.div`
  ${RWD_LL(css`
    display: none;
  `)};
`;

const SignInButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.subBrown};
  border-radius: 10px;
  padding: 14px 20px;
  background-color: transparent;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts[1]};
  transition: all 0.3s;
  font-size: clamp(16px, 1.041vw, 20px);
  color: ${({ theme }) => theme.colors.subBrown};
  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.subBrown};
  }
`;

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      handleSetActive("adxNews");
    }
  }, [location]);

  const { data, isSuccess, isError, error } = useGetArticleListQuery({
    page: 1,
  });

  const [articleId, setArticleId] = useState(0);

  useEffect(() => {
    if (isSuccess) {
      setArticleId(data?.previewArticleList?.[0]?.articleId);
    }
  }, [data, isError]);

  const [menuList, setMenuList] = useState([
    {
      id: 1,
      name: "關於我們",
      link: "/",
      subList: [
        { id: 1, name: "公司背景", link: "about/company_profile" },
        { id: 2, name: "功能醫學", link: "about/functional_medicine" },
        {
          id: 3,
          name: "Functional Diagnostic Nutrition (FDN)",
          link: "about/functional_diagnostic_nutrition ",
        },
        { id: 4, name: "ADX團隊", link: "about/team" },
      ],
      isOpen: false,
    },
    {
      id: 2,
      name: "TimeWaver",
      link: "timewaver",
      anchor: "",
      subList: [
        {
          id: 1,
          name: "關於TimeWaver",
          link: "timewaver/about",
        },
        {
          id: 2,
          name: "應用領域",
          link: "timewaver/application",
        },
        {
          id: 3,
          name: "產品介紹",
          link: "timewaver/product",
        },
      ],
    },
    {
      id: 3,
      name: "ADX新聞",
      link: "/",
      anchor: "adxNews",
      isOpen: false,
    },
    {
      id: 4,
      name: "健康管理方案",
      link: "/",
      anchor: "",
      subList: [
        { id: 1, name: "ADX身體檢查計劃", link: "services/basic" },
        { id: 2, name: "內分泌及荷爾蒙測試", link: "services/endocrine" },
        { id: 3, name: "腸漏症測試", link: "services/lgs" },
        { id: 4, name: "消化系統功能測試", link: "services/digestion" },
        { id: 5, name: "重金屬及毒素測試", link: "services/heavyMetal" },
        { id: 6, name: "延遲性/即時性食物過敏測試", link: "services/allergy" },
        { id: 7, name: "健康基因DNA解碼檢測", link: "services/dna" },
      ],
      isOpen: false,
    },
    {
      id: 5,
      name: "點我預約",
      link: "appointment",
      anchor: "",
    },
    {
      id: 6,
      name: "FAQs",
      link: "faqs",
      anchor: "",
    },
  ]);

  const handleMenuSlide = (id) => {
    setMenuList(
      menuList.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            isOpen: !data.isOpen,
          };
        } else {
          return {
            ...data,
            isOpen: false,
          };
        }
      })
    );
  };

  const handleOpenMenuSlide = (id) => {
    setMenuList(
      menuList.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            isOpen: true,
          };
        } else {
          return {
            ...data,
            isOpen: false,
          };
        }
      })
    );
  };

  const handleCloseMenuSlide = (id) => {
    setMenuList(
      menuList.map((data) => {
        if (data.id === id) {
          return {
            ...data,
            isOpen: false,
          };
        } else {
          return {
            ...data,
            isOpen: false,
          };
        }
      })
    );
  };

  const [hamIsOpen, setHamIsOpen] = useState(false);

  const ToggleHamMenu = () => {
    setHamIsOpen(!hamIsOpen);

    setMenuList(
      menuList.map((data) => {
        return {
          ...data,
          isOpen: false,
        };
      })
    );
  };

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setHamIsOpen(false);
  }, [scrollTop]);

  const handleLink = (link, status) => {
    if (!status) {
      setHamIsOpen(false);
      navigate(link);
    }
  };

  const handleSetActive = () => {};

  return (
    <Wrapper>
      <Container>
        <LogoWrapper onClick={() => navigate("/")}>
          <Logo src={LogoImg} />
        </LogoWrapper>
        <MenuWrapper $status={hamIsOpen}>
          <MenuContainer>
            {menuList.map((data) => (
              <Menu
                key={data.id}
                onClick={() => handleMenuSlide(data.id)}
                onMouseOver={() => handleOpenMenuSlide(data.id)}
                onMouseLeave={() => handleCloseMenuSlide(data.id)}
              >
                <MenuMainWrapper>
                  {data.anchor ? (
                    <Fragment>
                      {location.pathname === "/appointment" ? (
                        <MenuMainItem
                          onClick={() =>
                            handleLink(
                              `${data.link}`,
                              data?.subList?.length > 0
                            )
                          }
                        >
                          {data.name}
                        </MenuMainItem>
                      ) : location.pathname === "/" ? (
                        <MenuMainItem>
                          <ScrollLink
                            to={data.anchor}
                            smooth={true}
                            duration={500}
                            spy={true}
                            onSetActive={handleSetActive}
                          >
                            {data.name}
                          </ScrollLink>
                        </MenuMainItem>
                      ) : (
                        <MenuMainItem
                          onClick={() => handleLink(`news/${articleId}`, false)}
                        >
                          {data.name}
                        </MenuMainItem>
                      )}
                    </Fragment>
                  ) : (
                    <MenuMainItem
                      onClick={() =>
                        handleLink(`${data.link}`, data?.subList?.length > 0)
                      }
                    >
                      {data.name}
                    </MenuMainItem>
                  )}

                  {data?.subList?.length > 0 && (
                    <MenuArrow $status={data.isOpen}>
                      <Icon.Nav_arrow />
                    </MenuArrow>
                  )}
                </MenuMainWrapper>

                {data.isOpen && (
                  <MenuItemWrapper>
                    {data?.subList?.map((sub) => (
                      <MenuItem
                        key={sub.id}
                        onClick={() => handleLink(`${sub.link}`, false)}
                      >
                        {sub.name}
                      </MenuItem>
                    ))}
                  </MenuItemWrapper>
                )}
              </Menu>
            ))}
          </MenuContainer>
        </MenuWrapper>
        <SignInWrapper>
          <SignInButton onClick={() => navigate("/login")}>
            Sign In
          </SignInButton>
        </SignInWrapper>
        <MobWrapper>
          {/* <UserIcon onClick={() => navigate("/login")}>
            <Icon.Nav_user />
          </UserIcon> */}
          <HamburgerMenuList
            $status={hamIsOpen}
            onClick={() => ToggleHamMenu()}
          >
            <HamburgerMenuItem $status={hamIsOpen} />
            <HamburgerMenuItem $status={hamIsOpen} />
            <HamburgerMenuItem $status={hamIsOpen} />
          </HamburgerMenuList>
        </MobWrapper>
      </Container>
    </Wrapper>
  );
};

export default Header;
