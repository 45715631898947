import { styled, css } from "styled-components";
import { Fragment, useState, useEffect } from "react";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";

import Device from "./components/Device";
import Plate from "./components/Plate";
import Therapy from "./components/Therapy";
import Women from "./components/Women";
import Prime from "./components/Prime";
import Pro from "./components/Pro";
import { useOutletContext } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import parse from "html-react-parser";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: clamp(300px, 64.0625vw, 1230px);
  padding-bottom: 80px;

  ${RWD_LL(css`
    width: 83.22vw;
  `)};
`;

const TitleWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 80px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: left;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const TabWrapper = styled.div`
  padding-bottom: 50px;
  display: flex;
  width: 100%;

  ${RWD_MD(css`
    padding-bottom: 20px;
  `)};
`;

const TabContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-auto-flow: column;
  justify-content: flex-start;
  gap: 15px;
  overflow: auto;
  padding-bottom: 5px;

  /* 捲軸寬度及高度 */
  &::-webkit-scrollbar {
    width: 5px; /*右側捲軸寬度*/
    height: 5px; /*下方捲軸高度*/
  }
  /* 軌道背景底色 */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    background: ${({ theme }) => theme.colors.gray};
  }

  /* 滑桿顏色 */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.copyRight};
  }
  /* 滑桿滑鼠滑入時的顏色 */
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.mainBrown};
  }
  /* 捲軸兩側頂端的按鈕 */
  /* &::-webkit-scrollbar-button {
    background-color: ${({ theme }) => theme.colors.subBrown};
  } */
  /* 捲軸兩側頂端的按鈕滑鼠滑入時的顏色 */
  /* &::-webkit-scrollbar-button:hover {
    background-color: #65250f;
  } */
`;

const Tab = styled.div`
  border-radius: 10px;
  /* width: clamp(100px, 9.375vw, 180px); */
  /* width: 180px; */
  /* margin-right: 30px; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.titleBrown};
  border: 1px solid ${({ theme }) => theme.colors.copyRight};
  font-weight: ${({ $activeTab }) => ($activeTab ? "bold" : "normal")};
  cursor: pointer;
  background: ${({ $activeTab, theme }) =>
    $activeTab ? theme.colors.gray : "transparent"};

  ${RWD_SM(css`
    border-radius: 4px;
    /* margin-right: 16px; */
    /* white-space: nowrap; */
  `)};
`;

const TabText = styled.div`
  padding: 16px;
  font-size: clamp(16px, 0.9375vw, 18px);
  white-space: nowrap;

  ${RWD_MD(css`
    padding: 10px 12px;
  `)};
`;

export default function ProductPage() {
  const [activeTab, setActiveTab] = useState(1);
  const windowSize = useOutletContext();
  const [swiperSpace, setSwiperSpace] = useState(50);
  const tabList = [
    {
      id: 1,
      title: "A Large Coil Plate <br/> 頻率發射板",
    },
    {
      id: 2,
      title: "女性健康版本<br/>微電頻率儀 3.0",
    },
    {
      id: 3,
      title: "微電流頻率儀",
    },
    {
      id: 4,
      title: "微電流訊息場頻率療程<br/>(單次療程)",
    },
    {
      id: 5,
      title: "Timewaver<br/>精選組合套餐",
    },
    {
      id: 6,
      title: "Timewaver<br/>Pro 專業組合套餐",
    },
  ];

  useEffect(() => {
    if (windowSize[0] <= "1024") {
      if (windowSize[0] <= "834") {
        setSwiperSpace(16);
      }
    } else {
      setSwiperSpace(30);
    }
  }, [windowSize]);

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>產品介紹</Title>
        </TitleWrapper>
        <TabWrapper>
          <TabContainer>
            {tabList.map((tab) => (
              <Tab
                key={tab.id}
                $activeTab={activeTab === tab.id}
                onClick={() => setActiveTab(tab.id)}
              >
                <TabText>{parse(tab.title)}</TabText>
              </Tab>
            ))}
          </TabContainer>
        </TabWrapper>
        <Fragment>
          {(() => {
            switch (activeTab) {
              case 1:
                return <Plate />;
              case 2:
                return <Women />;
              case 3:
                return <Device />;
              case 4:
                return <Therapy />;
              case 5:
                return <Prime />;
              case 6:
                return <Pro />;
            }
          })()}
        </Fragment>
      </Container>
    </Wrapper>
  );
}
