import { createApi } from "@reduxjs/toolkit/query/react";
import telegramBaseQuery from "./query/telegramBaseQuery";

export const telegramApi = createApi({
  reducerPath: "telegramApi",
  baseQuery: telegramBaseQuery,
  endpoints: (builder) => ({
    sendMessage: builder.mutation({
      query: ({ chatId, message }) => ({
        url: "telegram/SendMessage",
        method: "POST",
        body: JSON.stringify({
          chatId,
          message,
        }),
      }),
    }),
  }),
});

export const { useSendMessageMutation } = telegramApi;
