import { styled, css } from "styled-components";
import { useState, useEffect } from "react";

import { useOutletContext } from "react-router-dom";

import EventImg1 from "../../../images/event/event_1.png";
import EventImg2 from "../../../images/event/event_2.png";
import EventImg3 from "../../../images/event/event_3.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import { useGetActivityListQuery } from "../../../services/activeApi";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.gray};
`;

const Container = styled.div`
  padding-top: 120px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${RWD_SM(css`
    padding-top: 50px;
  `)};
`;

const TitleWrapper = styled.div`
  padding-bottom: 50px;
  ${RWD_SM(css`
    width: 77.77%;
  `)};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.titleBrown};
  font-size: ${({ theme }) => theme.fontSize.sectionTitle};
  padding-bottom: 20px;
  font-family: ${({ theme }) => theme.fonts[2]};
  font-weight: bold;
  font-size: clamp(24px, 2.0833vw, 40px);
`;

const SubTitle = styled.div`
  color: ${({ theme }) => theme.colors.mainBrown};
  font-size: clamp(14px, 1.25vw, 24px);
`;

const EventWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
`;

const EventContainer = styled.div`
  width: clamp(calc(600px + 60px), calc(62.499vw + 60px), 1300px);

  ${RWD_SM(css`
    width: calc(199.98vw + 60px);
  `)};
`;

const EventBox = styled.div`
  width: 100%;
  /* width: 20.833vw; */
  height: clamp(380px, 27.08vw, 520px);
  /* height: 27.08vw;
  width: 20.833vw; */
  /* width: 400px; */

  /* height: calc(((67.70833vw - 100px) / 3) * 1.3); */
  /* height: 400px;
  
  width: 100%; */
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ $imgurl }) => `url(${$imgurl})`}; */
  margin-right: 50px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: stretch;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  ${RWD_SM(css`
    height: 111.11vw;

    /* background-size: cover; */

    box-shadow: 10px 10px 10px 1px rgba(248, 238, 232, 1);
  `)};
`;

const EventImg = styled.img`
  height: 100%;
  position: absolute;
  left: 0;
  object-fit: cover;
  transition: all 0.5s ease-in-out;

  ${EventBox}:hover & {
    transform: scale(1.2);
  }
`;

const EventContentWrapper = styled.div`
  width: 80%;
  text-align: left;
  /* padding-bottom: 2.0833vw; */
  top: 63.076923%;
  /* height: auto; */
  z-index: 2;

  position: absolute;

  transition: all 0.3s ease-in-out;

  ${EventBox}:hover & {
    filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.1));
  }

  ${RWD_SM(css`
    width: 83.33%;
    height: auto;
    padding-top: 0;
    /* height: 100%; */
    top: 67.5%;
    /* padding-bottom: 35px; */

    /* padding-top: 100%; */
  `)};
`;

const EventTime = styled.div`
  font-family: ${({ theme }) => theme.fonts[1]};
  font-size: clamp(24px, 2.0833vw, 40px);
  padding-bottom: clamp(6.97px, 0.520833vw, 10px);
`;

const EventTitle = styled.div`
  font-size: clamp(16px, 1.25vw, 24px);
  text-align: justify;
  font-family: ${({ theme }) => theme.fonts[1]};
  align-self: stretch;
  height: fit-content;
`;

const Shadow = styled.div`
  height: 65%;
  width: 100%;
  background-color: aliceblue;
  position: absolute;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.6));
`;

const Event = () => {
  const windowSize = useOutletContext();
  const [swiperSpace, setSwiperSpace] = useState(50);

  // const { data, isSuccess, isError, error } = useGetActivityListQuery({
  //   page: 1,
  // });

  // console.log(data);

  const [eventList, setEventList] = useState([
    {
      id: 1,
      img: EventImg1,
      time: "Fitness and Physical",
      title: "晨間瑜伽課程｜啟動一天的活力",
    },
    {
      id: 2,
      img: EventImg2,
      time: "Dietary and Nutrition",
      title: "健康飲食講座｜探索平衡飲食的重要性",
    },
    {
      id: 3,
      img: EventImg3,
      time: "Dietary and Nutrition",
      title: "意廚藝工作坊｜製作營養豐富的美食",
    },
    {
      id: 4,
      img: EventImg1,
      time: "Fitness and Physical",
      title: "晨間瑜伽課程｜啟動一天的活力",
    },
    {
      id: 5,
      img: EventImg2,
      time: "Dietary and Nutrition",
      title: "健康飲食講座｜探索平衡飲食的重要性",
    },
    {
      id: 6,
      img: EventImg3,
      time: "Dietary and Nutrition",
      title: "意廚藝工作坊｜製作營養豐富的美食",
    },
  ]);

  useEffect(() => {
    if (windowSize[0] <= "1024") {
      if (windowSize[0] <= "834") {
        setSwiperSpace(30);
      }
    } else {
      setSwiperSpace(50);
    }
  }, [windowSize]);

  const handleAlert = () => {
    MySwal.fire({
      title: "敬請期待",
      confirmButtonColor: "#B77D38",
      confirmButtonText: "確認",
    });
  };

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>近期活動</Title>
          <SubTitle>加入我們的行列 體驗ADX為你精心準備的健康管理項目</SubTitle>
        </TitleWrapper>
        <EventWrapper>
          <EventContainer>
            <Swiper
              spaceBetween={swiperSpace}
              slidesPerView={3}
              // autoplay={{
              //   disableOnInteraction: true,
              // }}
              // modules={[Autoplay]}
            >
              {eventList.map((data) => (
                <SwiperSlide key={data.id} onClick={handleAlert}>
                  <EventBox $imgurl={data.img}>
                    <EventImg src={data.img} />
                    <EventContentWrapper>
                      <EventTime>{data.time}</EventTime>
                      <EventTitle>{data.title}</EventTitle>
                    </EventContentWrapper>
                    <Shadow />
                  </EventBox>
                </SwiperSlide>
              ))}
            </Swiper>
          </EventContainer>
        </EventWrapper>
      </Container>
    </Wrapper>
  );
};

export default Event;
