import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";

import applicationImg from "../../../../images/timewaver/application/application.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: clamp(300px, 64.0625vw, 1230px);
  padding-bottom: 80px;

  ${RWD_LL(css`
    width: 83.22vw;
  `)};
`;

const TitleWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 80px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: left;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const IntroSection = styled.section`
  padding-bottom: 50px;
`;

const IntroImage = styled.div`
  display: flex;
`;

const IntroContent = styled.div``;

const ModuleSection = styled.section``;

const ModuleWrapper = styled.div`
  display: grid;
  gap: 40px 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const ModuleBox = styled.div`
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.copyRight};

  background: ${({ theme }) => theme.colors.white};
`;

const ModuleBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModuleBoxTitleWrapper = styled.div`
  color: ${({ theme }) => theme.colors.titleBrown};
  background: ${({ theme }) => theme.colors.gray};
  padding: 20px 0;
`;
const ModuleBoxTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const ModuleBoxTitleEn = styled.div``;

const ModuleBoxContent = styled.div`
  p {
    &:first-child {
      margin-top: 0;
    }
  }
`;

export default function ApplicationPage() {
  const moduleData = [
    {
      id: 1,
      title: [
        {
          ch: "群組波動模組",
          en: "Genowave module",
        },
      ],
      description: [
        "在GenoWave群組波動模組中以二維方式來協助解釋並分析人與人之間複雜的關係。可應用的範圍從家庭或家族到公司與組織內部的各種人際關係，透過不同的分析選項程序，了解與優化既存關係中的困難障礙，達致和諧共識！",
      ],
    },
    {
      id: 2,
      title: [
        {
          ch: "器官統整模組",
          en: "Organ coherence module",
        },
      ],
      description: [
        "器官分析是一項新穎的工作，能夠快速檢析身體器官擁有的信息場訊息並進行初步檢查，同時也可以對特別需要關注的單一器官做分析！每個器官都有電磁場，這是由電荷的振動引起的。生物光子研究也說明，當生物光子頻率偏離平譜時，代表在一定頻率上呈現微弱或缺乏，這是疾病出現的一種現象，因此通過恢復頻譜，可以平衡生命整體運作。",
        "當檢查各個個人器官，我們能勾選分析整體器官或者單一器官（血管系統、呼吸系統），經過能量場的分析會顯示個人器官受到過度控管或控管不足的狀態，並進行合適的平衡方法。",
      ],
    },
    {
      id: 3,
      title: [
        {
          ch: "經絡模組",
          en: "Meridians module",
        },
      ],
      description: [
        "根據經絡源於中醫（TCM），經絡是器官的『鑰匙』也表示主要能量『氣』的通道。在器官中，壓力和其他干擾會導致能量供應受阻， 在數以千計的能量和穴位中，經絡模組分析了在信息場域應刺激哪些能量和穴位，並針對顯示阻礙的經絡，進行優化以改善和平衡整體運作。",
      ],
    },
    {
      id: 4,
      title: [
        {
          ch: "能量點模組",
          en: "Energy Points module",
        },
      ],
      description: [
        "能量點模組中能夠廣泛運用在各種生活領域中。可以使用的圖檔如：X射線圖像，平面圖，公司組織結構圖等輸入，接著可進行建築物的能量優化、空間能量清理或優化。透過數據庫中的量子分析點，進行信息字段分析和優化，並透過所提供的內容，發覺可能潛在的場域空間能量干擾並進行調和，最後歸位正常能量流，改善整體能量品質。",
      ],
    },
    {
      id: 5,
      title: [
        {
          ch: "時間軸模組",
          en: "TimeLine module",
        },
      ],
      description: [
        "時間軸模組分析人從出生、現在與未來的生命線在信息領域中與特定問題產生共鳴的時間關鍵點，分析中以正面的方式來作用可能存在的障礙或干擾，可按照時間序偵測、標示訊息場，並顯示信息字段的時間順序。模組中的時間軸既是分隔符，又是三維空間與「信息字段」的更高維度之間的接口，因此我們認為，提供信息的關鍵字方法最為重要。",
      ],
    },
    {
      id: 6,
      title: [
        {
          ch: "音頻模組",
          en: "Soundtherapy module",
        },
      ],
      description: [
        "音頻療法模組根據個人狀態分析有效於你的頻率作為協助，應用生物體需求理論，使用正面震盪音頻直接轉移到信息場作為平衡，你可以選擇在意識水平（光亮子諧振器）和能量水平（雜訊二極體）達到身心靈的平衡！",
        "選定的頻率也可以手動選擇並作為單純的音波聲或調製為音樂。 在音頻模組中，可應用數據庫中存儲的以下頻率程序做音頻組合，包括：人體的基本共振，聲音和顏色，針對細胞和器官刺激的頻率，腦部頻率，Rife頻率，脈輪頻率，分配給症狀的頻率，以及治療的頻率HZ聲頻。",
      ],
    },
    {
      id: 7,
      title: [
        {
          ch: "光環模組",
          en: "Aura module",
        },
      ],
      description: [
        "此模組借助脈輪分析結果，分析可見光頻率下的信息場，以圖形方式將此分佈映射為你的光環和脈輪，人體脈輪與不同的內分泌系統，神經中樞和某些器官直接相連，且光環可以顯示與描繪脈輪狀態，因此可以針對不同脈輪進行分析與優化，另外，此模組也可紀錄建築物與公司的脈輪圖像。",
      ],
    },
    {
      id: 8,
      title: [
        {
          ch: "波動篩選模組",
          en: "WaveScreening module",
        },
      ],
      description: [
        "波動篩選模組可以快速找出目前你的健康狀況。你可以詳細檢視：細胞、細菌、病毒、真菌、寄生蟲、感官、泌尿道系統、牙科、免疫系統、呼吸道、內分泌腺、賀爾蒙、骨骼、心臟循環、代謝、消化。快速分析健康狀況後，有目標的去運用優化來調整身體器官功能上的阻塞與失衡。",
      ],
    },
    {
      id: 9,
      title: [
        {
          ch: "波動基因模組",
          en: "WaveGenetics module",
        },
      ],
      description: [
        "根據『生物電場』進行多項的調和震動幫助你找回健康！在波動基因模組中可以執行檢測可能的醫學疾病，在80個波動基因檔案劃分為五大類別：健康、新陳代謝、細胞、骨骼系統、血液與大腦、免疫系統。",
        "在分析前要先為自己選擇一項五大類別重點來做分析，如：免疫系統。為此，在數據庫中內建475個醫學偵測疾病的影響因素，模組能夠以既有的資訊，調節你的DNA波動基因場，因此可以建立MP3音頻檔案應用在你個人平衡生物能量。",
      ],
    },
  ];

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>應用領域</Title>
        </TitleWrapper>
        <IntroSection>
          <IntroContent>
            <p>
              TimeWaver分析旨在檢查來自選定數據庫的哪些信息與對象的信息字段產生了最大的共鳴。然後，在優化過程中，此信息將被傳輸到對象的信息字段中。因此，信息數據庫是系統的核心。
              從每一個不同維度，找到你生命的不同主題進行調和，儀器會鎖定個人的信息場，即刻分析即刻進行頻率的調整。根據德國的研究，頻率會從12個不同的維度所產生的信息進行分析解讀，範圍非常廣泛，提供給我們一個量子-全息態(Quantum-Holomorphism)的生物系統觀點，可以找出身體疾病所忽略的心智與心理層次的原因。
            </p>
            <p>
              透過TimeWaver調和的人將會發現，影響一個人的整體因素包含了從外在空間環境、人際互動的信念、自我認同和精神層面的議題如祖先議題，或是更高靈魂層次等等，這些互相關聯的原因都可以透過儀器不同的頻率模式，從不同的層次介面找出原因，進行調和與協助你全然全新的身心靈生活！
            </p>
          </IntroContent>
          <IntroImage>
            <img src={applicationImg} alt="timewaver_application" />
          </IntroImage>
        </IntroSection>
        <ModuleSection>
          <ModuleWrapper>
            {moduleData.map((data) => (
              <ModuleBox key={data.id}>
                <ModuleBoxContainer>
                  <ModuleBoxTitleWrapper>
                    {data.title.map((title) => (
                      <ModuleBoxTitle key={title.ch}>{title.ch}</ModuleBoxTitle>
                    ))}
                    {data.title.map((title) => (
                      <ModuleBoxTitleEn key={title.en}>
                        {title.en}
                      </ModuleBoxTitleEn>
                    ))}
                  </ModuleBoxTitleWrapper>
                  <ModuleBoxContent>
                    {data.description.map((item) => (
                      <p key={item}>{item}</p>
                    ))}
                  </ModuleBoxContent>
                </ModuleBoxContainer>
              </ModuleBox>
            ))}
          </ModuleWrapper>
        </ModuleSection>
      </Container>
    </Wrapper>
  );
}
