import { createApi } from "@reduxjs/toolkit/query/react";
import frontendBaseQuery from "./query/frontendBaseQuery";

export const doctorApi = createApi({
  reducerPath: "doctorApi",
  baseQuery: frontendBaseQuery,
  endpoints: (builder) => ({
    getDoctorList: builder.query({
      query: () => ({
        url: "/Doctor/DoctorList",
        method: "GET",
      }),
      providesTags: [{ type: "doctor", id: "DOCTOR" }],
    }),
    getDoctorInfo: builder.query({
      query: ({ DoctorId }) => ({
        url: `/Doctor/GetDoctorInfo?DoctorId=${DoctorId}`,
        method: "GET",
      }),
      providesTags: [{ type: "doctor", id: "DOCTOR" }],
    }),
    editDoctor: builder.mutation({
      query: ({
        doctorId,
        doctorName,
        doctorPhone,
        doctorEmail,
        doctorTelegramId,
        consultationTimes,
        clearReservation,
      }) => ({
        url: "/Doctor/EditDoctorInfo",
        method: "POST",
        body: {
          doctorId,
          doctorName,
          doctorPhone,
          doctorEmail,
          doctorTelegramId,
          consultationTimes,
          clearReservation,
        },
      }),
      invalidatesTags: [{ type: "doctor", id: "DOCTOR" }],
    }),
  }),
});

export const {
  useGetDoctorListQuery,
  useGetDoctorInfoQuery,
  useEditDoctorMutation,
} = doctorApi;
