import { styled } from "styled-components";
import { useState, useEffect } from "react";
import RemoteCalendar from "../../../global/RemoteCalendar";
import {
  useGetCalendarListQuery,
  useReserveCalendarMutation,
  useDeleteCalendarItemMutation,
} from "../../../services/remoteCalendarApi";
import { RemoteSwal, DefaultSwal } from "../../../global/MySwal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  width: 95%;
  height: 100%;
  justify-content: flex-start;
`;

const Title = styled.h3`
  text-align: left;
  font-size: 25px;
  margin: 40px 0 20px 0;
  padding-bottom: 20px;
  width: 95%;
  color: ${({ theme }) => theme.colors.titleBrown};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`;

const CalendarWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const RemoteSchedulePage = () => {
  const { data, error, isError, isSuccess } = useGetCalendarListQuery();

  const [reserveTimes, setReserveTimes] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setReserveTimes(data.calendarItemViewModels);
    }

    if (isError) {
      DefaultSwal({
        icon: "error",
        confirmButtonText: "確定",
        title: "取得預約時段失敗",
        text: error.data.message,
      });
    }
  }, [data, isError]);

  const [
    _reserveCalendar,
    {
      data: reserveData,
      isSuccess: reserveIsSuccess,
      isError: reserveIsError,
      error: reserveError,
    },
  ] = useReserveCalendarMutation();

  const [
    _deleteReserveCalendar,
    {
      data: deleteReserveData,
      isSuccess: deleteReserveIsSuccess,
      isError: deleteReserveIsError,
      error: deleteReserveError,
    },
  ] = useDeleteCalendarItemMutation();

  useEffect(() => {
    if (reserveIsSuccess) {
      DefaultSwal({
        icon: "success",
        confirmButtonText: "確定",
        title: "預約成功",
      });
    }

    if (reserveIsError) {
      DefaultSwal({
        icon: "error",
        confirmButtonText: "確定",
        title: "預約失敗",
        text: reserveError.data.message,
      });
    }
  }, [reserveData, reserveIsError]);

  useEffect(() => {
    if (deleteReserveIsSuccess) {
      DefaultSwal({
        icon: "success",
        confirmButtonText: "確定",
        title: "取消成功",
      });
    }

    if (deleteReserveIsError) {
      DefaultSwal({
        icon: "error",
        confirmButtonText: "確定",
        title: "取消失敗",
        text: deleteReserveError.data.message,
      });
    }
  }, [deleteReserveData, deleteReserveIsError]);

  const handleReserveCalendar = (date) => {
    console.log(date);

    RemoteSwal({
      title: `確定於 ${date} 預約視訊嗎？`,
      text: "時間格式為24小時制 例如 09:00 或 14:30",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "確定",
      cancelButtonText: "取消",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(result.value);
        const data = {
          RecipientEmail: result.value[2],
          StartDateTime: `${date}T${result.value[0]}:00`,
          EndDateTime: `${date}T${result.value[1]}:00`,
        };
        console.log(data);
        _reserveCalendar(data);
      }
    });
  };

  const timeFormat = (value) => {
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, "0");
    const date = value.getDate().toString().padStart(2, "0");
    const hour = value.getHours().toString().padStart(2, "0");
    const minute = value.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${date} ${hour}:${minute}`;
  };

  const handleDeleteReserve = (info) => {
    console.log(timeFormat(info.event.start).split(" ")[0]);
    DefaultSwal({
      icon: "warning",
      title: `確定取消 ${
        timeFormat(info.event.start).split(" ")[0]
      } 的預約嗎？`,
      confirmButtonText: "確定",
      cancelButtonText: "取消",
      showCancelButton: true,
      text: `${timeFormat(info.event.start).split(" ")[1]} - ${
        timeFormat(info.event.end).split(" ")[1]
      }`,
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(result.value);
        const data = {
          ScheduledId: Number(info.event.id),
        };
        console.log(data);
        _deleteReserveCalendar(data);
      }
    });
  };

  return (
    <Wrapper>
      <Title>遠端時段</Title>
      <Container>
        <CalendarWrapper>
          <RemoteCalendar
            reserveTimes={reserveTimes}
            handleReserveCalendar={handleReserveCalendar}
            handleDeleteReserve={handleDeleteReserve}
          />
        </CalendarWrapper>
      </Container>
    </Wrapper>
  );
};

export default RemoteSchedulePage;
