import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";

import aboutImg from "../../../../images/timewaver/about/about.svg";
import energyImg from "../../../../images/timewaver/about/energy.svg";
import cureImg_1 from "../../../../images/timewaver/about/cure_level.svg";
import cureImg_2 from "../../../../images/timewaver/about/cure_level2.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: clamp(300px, 64.0625vw, 1230px);
  padding-bottom: 80px;

  ${RWD_LL(css`
    width: 83.22vw;
  `)};
`;

const TitleWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 80px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: left;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const AboutSection = styled.section`
  display: flex;
  gap: 50px;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const AboutImage = styled.div`
  width: 50%;
  display: flex;

  img {
    display: block;
  }

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const AboutContent = styled.div`
  width: 50%;
  text-align: left;

  p {
    &:first-child {
      margin-top: 0;
    }
  }

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const InformationSection = styled.section`
  /* border: 1px solid blue; */
`;

const SubTitle = styled.h4`
  font-size: clamp(20px, 1.666vw, 32px);

  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;

const InformationContent = styled.div``;

const InformationImage = styled.div`
  display: flex;
  width: 500px;

  img {
    display: block;
  }

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const DataSection = styled.section`
  padding-bottom: 50px;
  padding-top: 50px;
`;

const DataBoxWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  ${RWD_SM(css`
    flex-direction: column;
    gap: 20px;
  `)};
`;

const DataBox = styled.div`
  padding: 40px;
  border-radius: 10px;
  width: 30%;
  color: ${({ theme }) => theme.colors.titleBrown};
  background: ${({ theme }) => theme.colors.gray};

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const DataValue = styled.div`
  font-size: 40px;
  font-weight: 700;
`;

const DataDescription = styled.div`
  font-size: 20px;
`;

const DataContent = styled.div``;

const CureSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const CureImage = styled.div`
  display: flex;
  width: 50%;

  img {
    display: block;
  }

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const CureImage2 = styled.div`
  display: flex;
  width: 100%;

  img {
    display: block;
  }
`;

const CureContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const CureContent = styled.div`
  width: 50%;

  p {
    &:first-child {
      margin-top: 0;
    }
  }

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

export default function AboutPage() {
  const data = [
    {
      id: 1,
      numericalValue: "14年",
      description: "技術發展",
    },
    {
      id: 2,
      numericalValue: "2,000+ 人",
      description: "全球專家使用",
    },
    {
      id: 3,
      numericalValue: "NO.1",
      description: "量子設備",
    },
  ];

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>關於TimeWaver</Title>
        </TitleWrapper>
        <AboutSection>
          <AboutImage>
            <img src={aboutImg} alt="timewaver_about" />
          </AboutImage>
          <AboutContent>
            <p>
              TimeWaver 時空波系統，是由德國量子物理學家 Marcus Schmieke
              所開發，他在少年時已展現鋼琴演奏及西洋棋才華，其西洋棋可蒙眼與多人對弈。大學攻讀物理學和哲學，之後與德國物理學家
              Burkhard Heim
              學習與研究宇宙12維度的模型，並整合信息場裝置及自然醫學資料庫，成功將
              <b>信息場 (Information Field)</b>
              技術應用在自然醫學方面，創新開發出 TimeWaver
              系統。經不斷研發，TimeWaver
              系統已廣泛應用在家庭與個人身、心、靈、健康、潛能等多元領域。
            </p>
          </AboutContent>
        </AboutSection>
        <InformationSection>
          <SubTitle>什麼是信息場？</SubTitle>
          <InformationContainer>
            <InformationContent>
              <p>
                人類有兩個層面: 一個是身體，另一個是心靈
                (意識)。信息場就是物質和靈性之間，身體和心靈之間的聯繫者，將身體的語言翻譯成意識的語言。
                心靈可以在信息場內顯現出物質可以辨認的形狀，物質世界也可以透個信息場來影響心靈。當心靈和身體之間無法溝通，無法在同一個方向上運作的時候，人就會生病。信息場療法就是讓身體和意識、心靈和物質兩者能夠和諧運作。
              </p>
            </InformationContent>
            <InformationImage>
              <img src={energyImg} alt="timewaver_energy" />
            </InformationImage>
          </InformationContainer>
        </InformationSection>
        <DataSection>
          <DataBoxWrapper>
            {data.map((item) => (
              <DataBox key={item.id}>
                <DataValue>{item.numericalValue}</DataValue>
                <DataDescription>{item.description}</DataDescription>
              </DataBox>
            ))}
          </DataBoxWrapper>
          <DataContent>
            <p>
              TimeWaver
              Pro系統，是集合了物理學、量子力學、天文學、能量學、印度古老吠陀、環境學、中醫經絡學、全人全息等等知識及智慧。其特點是透過量子技術，分析和調整平衡在人類、動植物、生活環境等等不同的面向，其中當然包含了我們一般認知的有形及無形的能量。
            </p>
            <p>
              無形的能量亦包括了，宇宙能量波動、地球磁場變化、家族業力、集體意識、情緒干擾等等！同時TimeWaver
              Pro系統更可以將一直很難去探索的個人潛意識的信息，直接透過文字呈現出來在我們眼前。幫助我們提升自己的醒覺能力、快速的自我內在療癒。讓一切過程都發生的那麼自然與自在！
            </p>
          </DataContent>
        </DataSection>

        <CureSection>
          <SubTitle>療癒層級</SubTitle>
          <CureContainer>
            <CureImage>
              <img src={cureImg_1} alt="timewaver_cure_level_1" />
            </CureImage>
            <CureContent>
              <p>
                療癒有六種不同的層級，健康問題的主因通常都起始於心靈及情緒層級，通過能量層級，最後顯現在生化及身體層級。越早察覺那個層級出現阻塞並作出調理，你的身體就能越快回復平衡。如果只針對身體或生化層級去處理，而忽略了源頭的層級，問題便難以根治。
              </p>
              <p>
                TimeWaver運用的光子技術能分析多達七至八維度，可在早期察覺阻塞的層級並作出適當的調理。
              </p>
            </CureContent>
          </CureContainer>
          <CureImage2>
            <img src={cureImg_2} alt="timewaver_cure_level_2" />
          </CureImage2>
        </CureSection>
      </Container>
    </Wrapper>
  );
}
