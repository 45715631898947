import { styled, css, withTheme } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import Input from "../../../global/Input";
import Calendar from "../../../global/Calendar";
import RadioButton from "../../../global/RadioButton";
import { useEffect, useState, useCallback, Fragment } from "react";
import {
  emailFormat,
  phoneFormat,
  appointmentDateTimeFormat,
} from "../../../utils";
import Button from "../../../global/Button";
import { useSendMessageMutation } from "../../../services/telegramApi";
import { useCreateReservationMutation } from "../../../services/reservationApi";
import { useGetConsultationTimeMutation } from "../../../services/consultationTimeApi";

import {
  useGetDoctorListQuery,
  useGetDoctorInfoQuery,
} from "../../../services/doctorApi";

import Loading from "../../../global/Loading";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AppointmentSwal, CalendarSwal } from "../../../global/MySwal";
import DoctorSelect from "../../../global/DoctorSelect";
import moment from "moment";

const Alert = withReactContent(Swal);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: clamp(300px, 64.0625vw, 1230px);

  ${RWD_LL(css`
    width: 83.22vw;
  `)};
`;

const TitleWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 80px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: left;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const TableWrapper = styled.div`
  margin-bottom: 40px;
  overflow: auto;
`;

const TableEl = styled.table`
  border: 1px solid ${({ theme }) => theme.colors.mainBrown};
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  width: clamp(900px, 64.0625vw, 1230px);
`;

const TheadEl = styled.thead``;

const TbodyEl = styled.tbody``;

const ThEl = styled.th`
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.colors.mainBrown};
  background: ${({ theme }) => theme.colors.lightBrown};

  &:first-child {
    position: sticky;
    left: 0;
  }
`;

const TrEl = styled.tr`
  border: 1px solid ${({ theme }) => theme.colors.mainBrown};
`;

const TdEl = styled.td`
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.colors.mainBrown};
`;

const TimeBox = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.titleBrown};
  padding: 5px 0;
  white-space: nowrap;
`;

const Circle = styled.span`
  width: 10px;
  display: inline-flex;
  height: 10px;
  border-radius: 50%;

  background: ${({ theme }) => theme.colors.subBrown};
`;

const FormWrapper = styled.div`
  padding-bottom: 120px;
`;

const FormContainer = styled.div`
  background: rgba(187, 175, 149, 0.1);
  border-radius: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;

  ${RWD_SM(css`
    padding: 20px 20px 40px 20px;
  `)};
`;

const ButtonWrapper = styled.div`
  padding-top: 80px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;

  ${RWD_SM(css`
    padding-top: 40px;
  `)};
`;

const ButtonEl = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.subBrown};
  border-radius: 10px;
  /* padding: 0.729vw 1.041vw; */
  padding: clamp(14px, 0.729vw, 16px) clamp(23.5px, 1.041vw, 45px);
  font-family: ${({ theme }) => theme.fonts[1]};
  transition: all 0.3s;

  background-color: ${({ $isValid, theme }) =>
    $isValid ? theme.colors.subBrown : theme.colors.gray};

  cursor: ${({ $isValid }) => ($isValid ? "pointer" : "default")};
`;

const ButtonText = styled.div`
  font-size: 20px;
  font-size: clamp(16px, 1.041vw, 20px);
  transition: all 0.3s;

  color: ${({ $isValid, theme }) =>
    $isValid ? theme.colors.white : theme.colors.subBrown};
`;

const AppointmentPage = () => {
  const [_createReservation, { isSuccess, data, isError, error, isLoading }] =
    useCreateReservationMutation();

  const [doctorInfo, setDoctorInfo] = useState([]);

  const [consultationTimes, setConsultationTimes] = useState([]);
  const [startTimes, setStartTimes] = useState([]);
  const [endTimes, setEndTimes] = useState([]);
  const shiftKey = [7, 1, 2, 3, 4, 5, 6];

  const {
    data: doctorData,
    isError: doctorDataIsError,
    isSuccess: doctorDataIsSuccess,
    error: doctorDataError,
  } = useGetDoctorListQuery();

  useEffect(() => {
    if (doctorDataIsSuccess) {
      setDoctorList(doctorData?.previewDoctorList);
    }

    if (doctorDataIsError) {
      console.log(doctorDataError?.data?.Message);
    }
  }, [doctorData, doctorDataIsError]);

  const {
    data: doctorInfoData,
    error: doctorInfoError,
    isError: doctorInfoIsError,
    isSuccess: doctorInfoIsSuccess,
  } = useGetDoctorInfoQuery({
    DoctorId: 1,
  });

  useEffect(() => {
    if (doctorInfoIsSuccess) {
      setConsultationTimes(doctorInfoData?.doctorInfo?.consultationTimes);
      setStartTimes(
        doctorInfoData?.doctorInfo?.consultationTimes.map((time) =>
          moment(time.startTime, "HH:mm")
        )
      );

      setEndTimes(
        doctorInfoData?.doctorInfo?.consultationTimes.map((time) =>
          moment(time.endTime, "HH:mm")
        )
      );
    }
  }, [doctorInfoData, doctorInfoIsError]);

  const [follow, setFollow] = useState(null);

  const [doctorList, setDoctorList] = useState([]);

  useEffect(() => {
    if (doctorList.length > 0) {
      Promise.all(
        doctorList.map((doctor) =>
          fetch(
            `${process.env.REACT_APP_API}Doctor/GetDoctorInfo?DoctorId=${doctor.doctorId}`
          ).then((response) => response.json())
        )
      )
        .then((data) => {
          setDoctorInfo(data.map((item) => item.doctorInfo));
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [doctorList]);

  // 初診 -> 填寫google form -> 要不要預約複診 -> 預約成功
  const handleFirstAppointment = () => {
    AppointmentSwal({
      title: "預約成功",
      text: "請點擊下方按鈕填寫初診問卷",
      confirmButtonText: "初診問卷",
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        // 顯示表單
        window.open(
          "https://docs.google.com/forms/d/e/1FAIpQLScJRDkUx9x5fHegFJzOKMwdSSIaNTHY6rbOJA_PDvBmh4otuw/viewform?usp=sf_link",
          "_blank"
        );
        AppointmentSwal({
          title: "預約複診",
          text: "因預約人數眾多，建議提早預約下次複診時間",
          icon: "info",
          showCancelButton: true,
          cancelButtonText: "暫不預約",
          confirmButtonText: "立即預約",
        }).then((result) => {
          if (result.isConfirmed) {
            setAppointmentType("follow");
            handleOpenFollowUpCalendar();
          } else {
            handleClear();
          }
        });
      }
    });
  };

  const handleOpenFollowUpCalendar = async () => {
    await CalendarSwal({
      title: "預約複診",
      text: "請選擇複診時間",
      dateTime: dateTime,
      setFollow: setFollow,
      follow: follow,
      showCancelButton: true,
      cancelButtonText: "取消預約",
      confirmButtonText: "確認預約",
      doctor: doctor,
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(result);

        const date = moment(result.value, "DD/MM/YYYY h:mm A");
        const formattedDate = date.format("YYYY-MM-DD HH:mm");

        const data = {
          doctorId: doctor,
          reservationType: 2,
          bookerName: name,
          bookerPhone: phone,
          bookerEmail: email,
          bookerTime: formattedDate,
        };

        // const data = {
        //   chatId: process.env.REACT_APP_TGID,
        //   message: `姓名：${name}\n手機號碼：${phone}\nEmail：${email}\n預約時間：${result.value}\n預約類型：複診`,
        // };

        _createReservation(data);
      } else {
        handleClear();
      }
    });
  };

  const handleFollweUpAppointment = () => {
    AppointmentSwal({
      title: "預約成功",
      icon: "success",
      showCancelButton: false,
      confirmButtonText: "確認",
    }).then((result) => {
      if (result.isConfirmed) {
        handleClear();
      }
    });
  };

  useEffect(() => {
    if (isSuccess) {
      if (appointmentType === "first") {
        handleFirstAppointment();
      } else {
        handleFollweUpAppointment();
      }

      // Alert.fire({
      //   title: "預約成功",
      //   text: appointmentType === "first" ? "請點擊下方連結填寫初診問卷" : "",
      //   // text:
      //   //   appointmentType === "first"
      //   //     ? "當日請提前30分鐘至診所填寫問券，諮詢結束後30分鐘將進行即場治療及相關行政作業"
      //   //     : "",
      //   icon: "success",
      //   confirmButtonText: "下一步",
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     handleClear();
      //   }
      // });
    }

    if (isError) {
      console.log(error?.data?.message);
      AppointmentSwal({
        title: "預約失敗",
        text: `${error?.data?.message}`,
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "確認",
      });
    }
  }, [data, isError]);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [dateTime, setDateTime] = useState(null);
  const [appointmentType, setAppointmentType] = useState("");
  const [doctor, setDoctor] = useState("");

  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [nameErrorMsg, setNameErrorMsg] = useState("");

  const isValid =
    name !== "" &&
    phone !== "" &&
    email !== "" &&
    doctor !== "" &&
    dateTime &&
    appointmentType !== "";

  const handleForm = async () => {
    const checkData = await Promise.all([
      phoneFormat(phone),
      emailFormat(email),
      name.trim().length > 20 ? false : true,
    ]);

    if (!checkData.every((data) => data)) {
      setPhoneErrorMsg(checkData[0] ? "" : "手機格式錯誤");
      setEmailErrorMsg(checkData[1] ? "" : "Email格式錯誤");
      setNameErrorMsg(checkData[2] ? "" : "姓名不得超過20個字元");
    } else {
      // const data = {
      //   chatId: process.env.REACT_APP_TGID,
      //   message: `姓名：${name}\n手機號碼：${phone}\nEmail：${email}\n預約時間：${appointmentDateTimeFormat(
      //     dateTime
      //   )}\n預約類型：${appointmentType === "follow" ? "複診" : "初診"}${
      //     appointmentType !== "follow"
      //       ? `\n問卷：https://docs.google.com/spreadsheets/d/1y5jYuT4a4UzwocnNyL3nYjq1jwW3y7PLWTjBsZn6FYg/edit?usp=sharing`
      //       : ""
      //   }`,
      // };

      const data = {
        doctorId: doctor,
        reservationType: appointmentType === "follow" ? 2 : 1,
        bookerName: name,
        bookerPhone: phone,
        bookerEmail: email,
        bookerTime: appointmentDateTimeFormat(dateTime),
      };

      _createReservation(data);
    }
  };

  useEffect(() => {
    setPhoneErrorMsg("");
  }, [phone]);

  useEffect(() => {
    setEmailErrorMsg("");
  }, [email]);

  useEffect(() => {
    setNameErrorMsg("");
  }, [name]);

  const header = [
    {
      id: 1,
      name: "星期一",
      value: "monday",
      time: "10:00 - 12:00",
    },
    {
      id: 2,
      name: "星期四",
      value: "thursday",
      time: "14:00 - 16:00",
    },
    {
      id: 3,
      name: "星期五",
      value: "friday",
      time: "16:00 - 17:00",
    },
  ];

  const body = [
    {
      id: 1,
      monday: false,
      thursday: true,
      friday: true,
    },
    {
      id: 2,
      monday: true,
      thursday: true,
      friday: true,
    },
    {
      id: 3,
      monday: true,
      thursday: false,
      friday: true,
    },
  ];

  const handleClear = () => {
    setName("");
    setPhone("");
    setAppointmentType("");
    setEmail("");
    setDateTime(null);
    setDoctor("");
  };

  useEffect(() => {
    if (dateTime) {
      setDateTime(null);
    }
  }, [appointmentType, doctor]);

  return (
    <Wrapper>
      {isLoading && <Loading />}
      <Container>
        <TitleWrapper>
          <Title>諮詢預約</Title>
        </TitleWrapper>

        <TableWrapper>
          <TableEl>
            <TheadEl>
              <TrEl>
                <ThEl colSpan="8">門診時間</ThEl>
              </TrEl>
              <TrEl>
                <ThEl>診療師</ThEl>
                {shiftKey.map((data) => (
                  <ThEl key={data}>
                    {(() => {
                      switch (data) {
                        case 1:
                          return "星期一";
                        case 2:
                          return "星期二";
                        case 3:
                          return "星期三";
                        case 4:
                          return "星期四";
                        case 5:
                          return "星期五";
                        case 6:
                          return "星期六";
                        case 7:
                          return "星期日";
                      }
                    })()}
                  </ThEl>
                ))}
              </TrEl>
            </TheadEl>

            <TbodyEl>
              {doctorInfo.map((doctor) => (
                <TrEl key={doctor.doctorId}>
                  <ThEl>{doctor.doctorName}</ThEl>
                  {shiftKey.map((el) => (
                    <TdEl key={el}>
                      {doctor.consultationTimes
                        .filter((time) => time.weekday == el)
                        .map((data, index) => (
                          <TimeBox key={index}>
                            {`${data.startTime} - ${data.endTime}`}
                          </TimeBox>
                        ))}
                    </TdEl>
                  ))}
                </TrEl>
              ))}
            </TbodyEl>
          </TableEl>
        </TableWrapper>

        <FormWrapper>
          <FormContainer>
            <Input
              title={"姓名"}
              placeholder={"請輸入姓名"}
              value={name}
              onChangeFun={setName}
              errorMsg={nameErrorMsg}
            />
            <RadioButton
              title={"預約類型"}
              onChangeFun={setAppointmentType}
              value={appointmentType}
            />
            <DoctorSelect
              title={"預約診療師"}
              data={doctorList}
              onChangeFun={setDoctor}
              content={doctor}
              disabled={!appointmentType}
            />
            <Calendar
              title={"預約時間"}
              onChangeFun={setDateTime}
              selectDate={dateTime}
              placeholder={"請選擇預約時間"}
              appointmentType={appointmentType}
              disabled={!appointmentType || !doctor}
              doctor={doctor}
            />

            <Input
              title={"手機號碼"}
              placeholder={"請輸入手機號碼"}
              value={phone}
              onChangeFun={setPhone}
              errorMsg={phoneErrorMsg}
              type={"tel"}
            />
            <Input
              title={"Email"}
              placeholder={"請輸入Email"}
              value={email}
              onChangeFun={setEmail}
              errorMsg={emailErrorMsg}
              type={"email"}
              htmlFor="email"
            />
          </FormContainer>

          <ButtonWrapper>
            <Button onClickFun={handleClear} text={"清除"}></Button>
            <ButtonEl
              disabled={!isValid}
              $isValid={isValid}
              onClick={handleForm}
              type="button"
            >
              <ButtonText $isValid={isValid}>預約</ButtonText>
            </ButtonEl>
          </ButtonWrapper>
        </FormWrapper>
      </Container>
    </Wrapper>
  );
};

export default AppointmentPage;
