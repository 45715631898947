import { styled, ThemeProvider, css } from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthToken, setAuthToken, clearAuthToken } from "../../../utils";
import theme from "../../../global/BackendTheme";

import BgImg from "../../../images/backend/background.png";
import LogoImg from "../../../images/backend/logo.png";

import Icon from "../../../global/Icon";
import { DefaultSwal } from "../../../global/MySwal";
import Loading from "../../../global/Loading";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

const Wrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: ${({ theme }) => theme.colors.gray};
`;

const Container = styled.div`
  display: flex;
  height: 100%;

  ${RWD_MD(css`
    flex-direction: column-reverse;
  `)};
`;

const ImgWrapper = styled.div`
  width: 33.69vw;
  background-image: url(${BgImg});
  background-size: cover;
  background-position: center center;

  img {
    width: 33.69vw;
    object-fit: cover;
  }

  ${RWD_MD(css`
    width: 100%;
    height: calc(var(--vh, 1vh) * 15.625);

    img {
      width: 100%;
      height: 100px;
    }
  `)};
`;

const FormWrapper = styled.div`
  padding: clamp(30px, 2.604vw, 50px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${RWD_MD(css`
    height: 84.375vh;
  `)};
`;

const FormContainer = styled.div`
  margin: auto;

  /* margin: auto; */
  width: clamp(300px, 23.95vw, 460px);

  ${RWD_SM(css`
    width: 83.33vw;
    display: flex;
    justify-content: center;
    border-radius: 24px;
    padding: 40px 0 30px 0;
    margin: 0;
    filter: drop-shadow(3px 3px 10px rgba(61, 42, 13, 0.05));
    background: ${({ theme }) => theme.colors.white};
  `)};
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  cursor: pointer;
  img {
    width: clamp(50px, 6.25vw, 120px);
  }

  ${RWD_SM(css`
    padding-bottom: 9.722vw;
  `)};
`;

const FormEl = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${RWD_SM(css`
    width: 66.66vw;
  `)};
`;

const SiteName = styled.div`
  font-family: "Vidaloka";
  font-size: 40px;
  padding-bottom: clamp(41px, 3.333vw, 64px);
  text-align: left;
  font-size: clamp(24px, 2.0833vw, 40px);
`;

const InputWrapper = styled.div`
  padding-bottom: clamp(24.5px, 1.666vw, 32px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &:last-child {
    padding-bottom: 23px;
  }
`;

const Labelel = styled.label`
  color: ${({ theme }) => theme.colors.darkBrown};
  font-size: clamp(12px, 1.041677vw, 20px);
`;

const Inputel = styled.input`
  background: transparent;
  width: 100%;
  max-width: 100%;
  border: none;
  padding: clamp(9.7px, 1.041677vw, 20px) clamp(15px, 1.25vw, 24px);
  font-size: clamp(12px, 1.041677vw, 20px);
  border-bottom: 1px solid ${({ theme }) => theme.colors.brown};

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.colors.lightBrown};
    opacity: 1; /* Firefox */
  }
`;

const ForgotPasswordWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 70px;
  padding-bottom: clamp(40px, 3.64588vw, 70px);
`;

const ForgotPassword = styled.div`
  font-size: 16px;
  cursor: pointer;
  font-size: clamp(10px, 0.833vw, 16px);
  color: ${({ theme }) => theme.colors.lightBrown};
`;

const ButtonWrapper = styled.div`
  width: 100%;
`;

const ButtonEl = styled.button`
  background-color: ${({ $isValid, theme }) =>
    $isValid ? theme.colors.subBrown : theme.colors.lightGray};
  border: none;
  padding: clamp(12.5px, 1.041677vw, 20px) 0;
  width: 100%;
  font-size: 22px;
  font-size: clamp(14px, 1.145833vw, 22px);
  border-radius: 4px;
  transition: all 0.3s;
  color: ${({ $isValid, theme }) =>
    $isValid ? theme.colors.white : theme.colors.lightBrown};
  cursor: ${({ $isValid }) => ($isValid ? "pointer" : "default")};
`;

const IconWrapper = styled.div`
  position: absolute;
  width: clamp(11px, 1.25vw, 24px);
  height: auto;
  right: clamp(15px, 1.25vw, 24px);
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);

  svg {
    width: clamp(11px, 1.25vw, 24px);
    height: auto;
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
`;

const LoginPage = () => {
  const navigiate = useNavigate();
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState("password");
  const [isLoggedIn, setIsLoggedIn] = useState(getAuthToken().token);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // 登入後導頁到 backend 頁面
    if (isLoggedIn) {
      console.log("已登入");
      navigiate("/backend/appointmentSchedule");
    }
  }, [isLoggedIn]);

  const handlePassword = (e) => {
    if (e.type === "mousedown") {
      setShowPassword("text");
    } else {
      setShowPassword("password");
    }
  };
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    refreshViewHeight();
  }, []);

  useEffect(() => {
    refreshViewHeight();
  }, [windowSize[0]]);

  const refreshViewHeight = () => {
    const vh = windowSize[1] * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const _mockRequest = () => {
    const data = [];
    return new Promise((resolve) => {
      setTimeout(() => {
        // 假 token
        data.push({
          AccessToken:
            "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTUxMiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2N",
        });
        setIsLoading(false);
        resolve(data);
      }, 1000);
    });
  };

  const handleLogin = async () => {
    console.log("點擊登入");
    if (account.trim() === "adxlife" && password.trim() === "adxlife@3488") {
      // account adxlife
      // password adxlife@3488
      // 登入成功
      // 將 token 存入 cookie
      setIsLoading(true);
      console.log("登入成功");
      const res = await _mockRequest();
      setAuthToken(res[0].AccessToken, account);

      navigiate("/backend/appointmentSchedule");
      // setIsLoggedIn(true);
    } else if (account !== "adxlife") {
      // 登入失敗
      DefaultSwal({
        title: "登入失敗",
        text: "帳號錯誤",
        icon: "error",
        confirmButtonText: "確認",
      });
      // 清除 token
      clearAuthToken();
    } else {
      // 登入失敗
      DefaultSwal({
        title: "登入失敗",
        text: "密碼錯誤",
        icon: "error",
        confirmButtonText: "確認",
      });
      // 清除 token
      clearAuthToken();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {isLoading && <Loading />}
        <Container>
          <ImgWrapper></ImgWrapper>
          <FormWrapper>
            <LogoWrapper onClick={() => navigiate("/")}>
              <img src={LogoImg} />
            </LogoWrapper>
            <FormContainer>
              <FormEl>
                <SiteName>ADX Lifestyle</SiteName>
                <InputWrapper>
                  <Labelel>帳號</Labelel>
                  <Inputel
                    value={account}
                    placeholder="example"
                    type="account"
                    onChange={(e) => setAccount(e.target.value)}
                  />
                </InputWrapper>

                <InputWrapper>
                  <Labelel>密碼</Labelel>
                  <InputContainer>
                    <Inputel
                      value={password}
                      placeholder="6-18 位數密碼，請區分大小寫"
                      type={showPassword}
                      autoComplete="current-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <IconWrapper
                      onMouseDown={handlePassword}
                      onMouseUp={handlePassword}
                    >
                      {showPassword === "text" ? (
                        <Icon.Eye_visible />
                      ) : (
                        <Icon.Eye_invisible />
                      )}
                    </IconWrapper>
                  </InputContainer>
                </InputWrapper>
                <ForgotPasswordWrapper>
                  <ForgotPassword>忘記密碼？</ForgotPassword>
                </ForgotPasswordWrapper>
                <ButtonWrapper>
                  <ButtonEl
                    onClick={handleLogin}
                    type="button"
                    $isValid={account !== "" && password !== ""}
                    disabled={account === "" && password === ""}
                  >
                    登入
                  </ButtonEl>
                </ButtonWrapper>
              </FormEl>
            </FormContainer>
          </FormWrapper>
        </Container>
      </Wrapper>
    </ThemeProvider>
  );
};

export default LoginPage;
