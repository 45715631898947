import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Time: [],
};

export const consultationSlice = createSlice({
  name: "consultation",
  initialState,
  reducers: {
    setConsultation: (state, action) => {
      state.Time = action.payload?.consultationTimes;
    },
  },
});

export const { setConsultation } = consultationSlice.actions;

export const selectConsultation = (state) => state.consultation;

export default consultationSlice.reducer;
