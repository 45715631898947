import { useState } from "react";
import { styled } from "styled-components";

const Wrapper = styled.div`
  border: 1px solid red;
  position: sticky;
  right: 0;
  width: calc(100% - 180px);
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
`;

const Header = () => {
  return (
    <Wrapper>
      <Nav>header</Nav>
    </Wrapper>
  );
};

export default Header;
