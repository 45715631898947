import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { selectDropDown } from "../../slices/referenceSlice";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import ArrowImg from "../images/footer/arrow.svg";
import LanguageImg from "../images/footer/language.svg";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../utils/rwd";

const Wrapper = styled.div``;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
  `)};
`;

const LanguageIcon = styled.div`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  img {
    width: 30px;
  }
`;

const SelectLabelButton = styled.button`
  background-color: #fff;
  border: none;
  border-radius: 3px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* cursor: pointer; */
  width: 100%;
  display: flex;
  padding: 0 5px;
  border: 1px solid ${({ theme }) => theme.colors.subBrown};
  border-radius: 5px;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      border-radius: 5px 5px 0 0;
    `}/* border: 1px solid blue; */
`;

const SelectLabelButtonValue = styled.div`
  padding: 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
`;

const ArrowIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-size: 22px;
  color: #b77d38;
  transition: 0.3s ease;

  ${({ $isVisible }) =>
    $isVisible &&
    css`
      transform: scaleY(-1);
    `}/* border: 1px solid blue; */
`;

const DropdownStyle = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  display: flex;
  border-radius: 0 0 5px 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
  /* overflow-y: scroll; */
  border: 1px solid ${({ theme }) => theme.colors.subBrown};
  border-top: none;
  z-index: 5;
  width: 100%;
  background: #fff;
  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      max-height: 40px;
      visibility: hidden;
    `};
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  padding: 10px 15px;
  cursor: pointer;
  width: 100%;
  font-size: clamp(12px, 0.9375vw, 18px);
  border-bottom: 1px solid ${({ theme }) => theme.colors.subBrown};

  &:last-child {
    border-bottom: none;
  }

  /* ${(props) =>
    props.active &&
    css`
      color: #009a63;
      font-weight: 500;
    `} */
  &:hover,
  :focus,
  :focus:hover {
    background-color: ${({ theme }) => theme.colors.lightBrown};
    color: ${({ theme }) => theme.colors.titleBrown};
    outline: none;
  }
`;

const LabelEl = styled.label`
  font-size: clamp(16px, 1.25vw, 24px);
  text-align: left;

  color: ${({ theme }) => theme.colors.titleBrown};

  ${RWD_SM(css`
    padding-bottom: 5px;
  `)};
`;

const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
`;

const Placeholder = styled(SelectLabelButtonValue)`
  color: #999;
`;

const DoctorSelect = ({ title, onChangeFun, data, content, disabled }) => {
  //   const data = useSelector(selectDropDown).List;

  const [currentValue, setCurrentValue] = useState("");
  const [open, setOpen] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    setDataList(data);
  }, [data]);

  useEffect(() => {
    setCurrentValue(content);
  }, [content]);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleValueChange = (value) => {
    setCurrentValue(value);
  };
  const handleChange = (value) => {
    handleValueChange(value);
    // call method, if it exists
    if (onChangeFun) onChangeFun(value);
    // close, after all tasks are finished
    handleClose();
  };

  return (
    <Wrapper>
      <Container>
        <LabelEl>{title}</LabelEl>
        <SelectContainer>
          <SelectLabelButton
            onClick={handleOpen}
            $isVisible={open}
            disabled={disabled}
          >
            {currentValue ? (
              <SelectLabelButtonValue>
                {
                  dataList?.filter((data) => data.doctorId == currentValue)[0]
                    ?.doctorName
                }
              </SelectLabelButtonValue>
            ) : (
              <Placeholder>
                {!disabled ? "請選擇診療師" : "請先選擇預約類型"}
              </Placeholder>
            )}

            <ArrowIcon $isVisible={open}>
              <MdOutlineKeyboardArrowDown />
            </ArrowIcon>
          </SelectLabelButton>
          <DropdownStyle $isVisible={open}>
            {/* {values.map((value, index) => (
          <DropdownItem
            onClick={() => handleChange(value)}
            active={value === currentValue}
            key={index}
          >
            {value}
          </DropdownItem>
        ))} */}

            {dataList &&
              dataList.map((data) => (
                <DropdownItem
                  value={data.doctorId}
                  key={data.doctorId}
                  onClick={() => handleChange(data.doctorId)}
                >
                  {data.doctorName}
                </DropdownItem>
              ))}
          </DropdownStyle>
        </SelectContainer>
      </Container>
    </Wrapper>
  );
};

export default DoctorSelect;
