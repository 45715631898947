import { createApi } from "@reduxjs/toolkit/query/react";
import frontendBaseQuery from "./query/frontendBaseQuery";

export const activeApi = createApi({
  reducerPath: "activeApi",
  baseQuery: frontendBaseQuery,
  endpoints: (builder) => ({
    getActivityList: builder.query({
      query: ({ page }) => ({
        url: `/Active/GetActivityList?page=${page}`,
        method: "GET",
      }),
    }),
    getActivityDetail: builder.query({
      query: ({ id }) => ({
        url: `/Active/GetActivityDetail?id=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetActivityListQuery, useGetActivityDetailQuery } = activeApi;
