import { styled } from "styled-components";
import { useState, useEffect } from "react";
import {
  useGetConsultationTimeMutation,
  useCreateConsultationOffTimeMutation,
  useDeleteConsultationOffTimeMutation,
  useGetConsultationOffTimeListMutation,
} from "../../../services/consultationTimeApi";

import { useGetReservationListMutation } from "../../../services/reservationApi";
import { useGetDoctorListQuery } from "../../../services/doctorApi";

import FullCalendarComponent from "../../../global/FullCalendar";
import { DefaultSwal } from "../../../global/MySwal";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  width: 95%;
  height: 100%;
  justify-content: flex-start;
`;

const Title = styled.h3`
  text-align: left;
  font-size: 25px;
  margin: 40px 0 20px 0;
  padding-bottom: 20px;
  width: 95%;
  color: ${({ theme }) => theme.colors.titleBrown};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray};
`;

const ListWrapper = styled.div`
  width: 10%;
  padding: 0 20px 0 0;
`;

const ListTitle = styled.div`
  margin: 65px 0 0 0;
  font-size: 16px;
`;

const DoctorList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const DoctorItem = styled.li`
  border-radius: 10px;
  padding: clamp(14px, 0.729vw, 16px) 10px;
  cursor: pointer;
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.colors.gray : theme.colors.white};
  font-weight: ${({ $selected, theme }) => ($selected ? "bold" : "normal")};
  color: ${({ $selected, theme }) =>
    $selected ? theme.colors.titleBrown : theme.colors.titleBrown};
  border: 1px solid ${({ theme }) => theme.colors.copyRight};
`;

const CalendarWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const AppointmentSchedulePage = () => {
  const [consultationTimes, setConsultationTimes] = useState([]);
  const [consultationOffTimes, setConsultationOffTimes] = useState([]);
  const [reservationEventData, setRservationEventData] = useState([]);
  const [doctorList, setDoctorList] = useState([]);

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [doctorId, setDoctorId] = useState(1);

  useEffect(() => {
    console.log(year, month, doctorId);
    handleGetConsultation();
    handleGetReservation();
    handleGetConsultationOffTime();
  }, [month, year, doctorId]);

  // 取得看診時間
  const [_getConsultation, { data, isSuccess, isError, error, isLoading }] =
    useGetConsultationTimeMutation();

  // 取得休診時間
  const [
    _getConsultationOffTime,
    {
      data: offTimeData,
      isSuccess: offTimeIsSuccess,
      isError: offTimeIsError,
      error: offTimeError,
    },
  ] = useGetConsultationOffTimeListMutation();

  useEffect(() => {
    if (offTimeIsSuccess) {
      setConsultationOffTimes(offTimeData?.consultationOffTimeList);
    }

    if (offTimeIsError) {
      console.log(offTimeError?.data?.Message);
    }
  }, [offTimeData, offTimeIsError]);

  // 取得醫生列表
  const {
    data: doctorData,
    isSuccess: doctorIsSuccess,
    isError: doctorIsError,
    error: doctorError,
  } = useGetDoctorListQuery();

  useEffect(() => {
    if (doctorIsSuccess) {
      setDoctorList(doctorData?.previewDoctorList);
    }
  }, [doctorData, doctorError]);

  useEffect(() => {
    if (doctorList.length > 0) {
      setDoctorId(doctorList[0].doctorId);
    }
  }, [doctorList]);

  const [
    _getReservation,
    {
      data: reservationData,
      isSuccess: reservationIsSuccess,
      isError: reservationIsError,
      error: reservationError,
    },
  ] = useGetReservationListMutation();

  useEffect(() => {
    if (reservationIsSuccess) {
      setRservationEventData(reservationData?.reservations);
      console.log(reservationData);
    }
  }, [reservationData, reservationIsError]);

  // 設定休診時間
  const [
    _createConsultationOffTime,
    {
      data: createConsultationData,
      isSuccess: createConsultationIsSuccess,
      isError: createConsultationIsError,
      error: createConsultationError,
    },
  ] = useCreateConsultationOffTimeMutation();

  useEffect(() => {
    if (createConsultationIsSuccess) {
      DefaultSwal({
        icon: "success",
        confirmButtonText: "確定",
        title: "設定成功",
      }).then((result) => {
        if (result.isConfirmed) {
          handleGetConsultation();
          handleGetConsultationOffTime();
          handleGetReservation();
        }
      });
    }

    if (createConsultationIsError) {
      DefaultSwal({
        icon: "error",
        confirmButtonText: "確定",
        title: "設定失敗",
        text: createConsultationError.data.message,
      });
    }
  }, [createConsultationData, createConsultationIsError]);

  // 刪除休診時間
  const [
    _deleteConsultationOffTime,
    {
      data: deleteConsultationData,
      isSuccess: deleteConsultationIsSuccess,
      isError: deleteConsultationIsError,
      error: deleteConsultationError,
    },
  ] = useDeleteConsultationOffTimeMutation();

  useEffect(() => {
    if (deleteConsultationIsSuccess) {
      DefaultSwal({
        icon: "success",
        confirmButtonText: "確定",
        title: "取消成功",
      }).then((result) => {
        if (result.isConfirmed) {
          handleGetConsultation();
          handleGetConsultationOffTime();
          handleGetReservation();
        }
      });
    }

    if (deleteConsultationIsError) {
      DefaultSwal({
        icon: "error",
        confirmButtonText: "確定",
        title: "取消失敗",
        text: deleteConsultationError.data.message,
      });
    }
  }, [deleteConsultationData, deleteConsultationIsError]);

  const handleGetConsultation = async () => {
    const data = {
      DoctorId: doctorId,
      Type: 2,
      Year: year,
      Month: month,
    };
    await _getConsultation(data);
  };

  const handleGetConsultationOffTime = async () => {
    const data = {
      DoctorId: doctorId,
      Year: year,
      Month: month,
    };
    await _getConsultationOffTime(data);
  };

  const handleGetReservation = async () => {
    console.clear();
    const data = {
      doctorId: doctorId,
      year: year,
      month: month,
    };
    console.log(data);

    await _getReservation(data);
  };

  const handleCreateConsultationOffTime = async (time) => {
    await _createConsultationOffTime({
      DoctorId: doctorId,
      OffTime: timeFormat(time),
    });
  };

  const handleDeleteConsultationOffTime = async (time) => {
    console.log(timeFormat(time));
    await _deleteConsultationOffTime({
      DoctorId: doctorId,
      OffTime: timeFormat(time),
    });
  };

  const timeFormat = (value) => {
    console.log(value);
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, "0");
    const date = value.getDate();
    const hour = value.getHours().toString().padStart(2, "0");
    const minute = value.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${date} ${hour}:${minute}`;
  };

  useEffect(() => {
    if (isSuccess) {
      setConsultationTimes(data.consultationTimes);
    }
  }, [data, isError]);

  return (
    <Wrapper>
      <Title>門診時段</Title>
      <Container>
        <ListWrapper>
          <ListTitle>診療師列表</ListTitle>
          <DoctorList>
            {doctorList?.map((doctor) => (
              <DoctorItem
                key={doctor.doctorId}
                $selected={doctorId === doctor.doctorId}
                onClick={() => setDoctorId(doctor.doctorId)}
              >
                {doctor.doctorName}
              </DoctorItem>
            ))}
          </DoctorList>
        </ListWrapper>
        <CalendarWrapper>
          <FullCalendarComponent
            consultationTimes={consultationTimes}
            consultationOffTimes={consultationOffTimes}
            reservationEventData={reservationEventData}
            setMonth={setMonth}
            setYear={setYear}
            handleCreateConsultationOffTime={handleCreateConsultationOffTime}
            handleDeleteConsultationOffTime={handleDeleteConsultationOffTime}
          />
        </CalendarWrapper>
      </Container>
    </Wrapper>
  );
};

export default AppointmentSchedulePage;
