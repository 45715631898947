import { styled, ThemeProvider, css } from "styled-components";
import theme from "../../global/FrontendTheme";
import { useState, useEffect } from "react";

import { Outlet } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import { useLoginMutation } from "../../services/authApi";

import { useGetArticleListQuery } from "../../services/articleApi";
import { useDispatch } from "react-redux";
import { setUser } from "../../Slices/authSlice";

import TopAnimation from "./components/TopAnimation";

const Wrapper = styled.div`
  overflow: hidden;
`;

const Container = styled.div`
  /* border: 1px solid blue; */
`;

const Layout = () => {
  const dispatch = useDispatch();

  // const [_login, { isSuccess, data, isError, error }] = useLoginMutation();

  // const log = {
  //   UserName: "TestAccount",
  //   Password: "T123456",
  // };

  // useEffect(() => {
  //   _login(log);
  // }, []);

  // useEffect(() => {
  //   if (isSuccess) {
  //     dispatch(setUser(data));
  //   }
  // }, [data, isError]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    refreshViewHeight();
  }, []);

  useEffect(() => {
    refreshViewHeight();
  }, [windowSize[0]]);

  const refreshViewHeight = () => {
    const vh = windowSize[1] * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {/* <TopAnimation /> */}
        <Header windowSize={windowSize} />
        <Container>
          <Outlet context={windowSize} />
        </Container>
        <Footer windowSize={windowSize} />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Layout;
