import { styled, css, keyframes } from "styled-components";
import { useState, useEffect, useRef, useCallback } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import BannerImg from "../../../images/banner/banner.png";
import WaveImg from "../../../images/banner/wave.png";

import BannerImg_Mob from "../../../images/banner/banner_mobile.png";

import BannerImg_Mob1 from "../../../images/banner/banner_mob_1.png";
import BannerImg_Mob2 from "../../../images/banner/banner_mob_2.png";
import BannerImg_Mob3 from "../../../images/banner/banner_mob_3.png";

import Button from "../../../global/Button";

// import Banner1 from "../../../images/banner/banner_1.png";
// import Banner2 from "../../../images/banner/banner_2.png";
// import Banner3 from "../../../images/banner/banner_3.png";

import EventImg1 from "../../../images/event/event_1.jpg";
import EventImg2 from "../../../images/event/event_2.jpg";
import EventImg3 from "../../../images/event/event_3.jpg";

import Banner_1 from "../../../images/banner/banner1.jpg";
import Banner_2 from "../../../images/banner/banner2.png";
import Banner_3 from "../../../images/banner/banner3.png";
import Banner_4 from "../../../images/banner/banner4.png";
import Banner_5 from "../../../images/banner/banner5.png";

import CoverImg from "../../../images/banner/cover.png";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import {
  useTransition,
  animated,
  useSpringRef,
  useTrail,
  useChain,
  useSpring,
  useSprings,
} from "@react-spring/web";

import { motion, AnimatePresence } from "framer-motion";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import "../../../index.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Wrapper = styled.div``;

const Container = styled.div`
  /* background: #f8f6f2; */
  position: relative;
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
`;

const BannerWrapper = styled.div`
  /* background: ${({ theme }) => theme.colors.gray}; */
  /* background-image: url(${BannerImg}); */
  /* background-color: teal; */
  width: 100%;

  /* height: 90.74vh; */
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  background-size: cover;
  background-position: center center;
  position: relative;

  ${RWD_SM(css`
    height: 87.5vh;
    height: calc(var(--vh, 1vh) * 87.5);
    /* background-image: url(${BannerImg_Mob}); */
    display: flex;
    align-items: flex-end;
    justify-content: center;
  `)};
`;

const WaveWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  transition: all 1.5s ease-in-out;

  ${RWD_SM(css`
    position: static;
    display: flex;

    img {
      display: none;
      height: auto;
    }
  `)};
`;

const W = styled.div`
  height: 17vh;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
`;

const WW = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='341' viewBox='0 0 1920 341' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 341L1920 155.674C1920 155.674 1757 0.000168856 1299 0.000128816C818.296 8.67914e-05 630 155.674 1.62017e-05 155.674L0 341L1920 341Z' fill='%23EEEEEC' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
  position: absolute;
  width: 200%;
  height: 100%;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
`;

const SloganWrapper = styled.div`
  position: absolute;
  bottom: 10%;
  color: white;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts[2]};
  z-index: 9;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 90%;
  /* background-image: url(${({ $bannerImg }) => $bannerImg}); */

  opacity: 1;

  /* &:nth-child(2) {
    animation-delay: 5s;
  }

  &:nth-child(3) {
    animation-delay: 10s;
  }

  &:nth-child(4) {
    animation-delay: 15s;
  }

  &:nth-child(5) {
    animation-delay: 20s;
  } */

  ${RWD_LG(css`
    width: 90%;
  `)};

  ${RWD_SM(css`
    /* position: static;
    transform: translateY(0%); */
    color: ${({ theme }) => theme.colors.white};
    /* width: 55.55vw; */
    bottom: 5%;
  `)};
`;

const SloganMain = styled.div`
  font-weight: bolder;
  font-size: clamp(40px, 4.166vw, 70px);
  padding-right: 20px;
  /* color: ${({ theme }) => theme.colors.titleBrown}; */
`;

const SloganSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 2px solid ${({ theme }) => theme.colors.white};
  padding-left: 20px;
`;

const SloganTitle = styled.div`
  font-weight: bold;
  font-size: clamp(24px, 2.0833vw, 40px);
  /* color: ${({ theme }) => theme.colors.mainBrown}; */
`;

const SloganContent = styled.div`
  /* color: ${({ theme }) => theme.colors.mainBrown}; */
  font-size: clamp(18px, 1.25vw, 24px);
`;

const Cover = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  clip-path: polygon(35% 0%, 100% 0%, 100% 100%, 15% 100%);

  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: ${({ theme }) => theme.fonts[1]};
  opacity: 1;
  top: 0;

  /* transform: translateX(100%);
  transition: all 1.5s ease-in-out; */

  /* ${BannerWrapper}:hover & {
    transform: translateX(0%);
    opacity: 1;
  } */

  /* ${RWD_SM(css`
    display: none;
  `)}; */

  img {
    width: 100%;
    height: calc(100vh - 9.25vh);
    height: calc(var(--vh, 1vh) * 90.75);
    position: absolute;
    right: 0;
    z-index: 1;
    object-fit: cover;
  }
`;

const CoverContentWrapper = styled.div`
  width: 47.86vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-right: 13.52vw;
  position: relative;
  z-index: 2;

  ${RWD_SM(css`
    width: 58vw;
    margin-right: 6vw;
  `)};
`;

const CoverTitle = styled.div`
  color: ${({ theme }) => theme.colors.titleBrown};
  font-size: 2.5vw;
  padding-bottom: 10px;
  font-weight: bold;
  text-align: left;
  font-size: clamp(38px, 2.5vw, 48px);
  opacity: 1;
  transition: all 1.5s linear;

  ${RWD_SM(css`
    font-size: 24px;
  `)};

  /* ${BannerWrapper}:hover & {
    opacity: 1;
    transition-delay: 0.3s;
  } */
`;

const CoverContent = styled.div`
  text-align: left;

  // 32px
  color: ${({ theme }) => theme.colors.mainBrown};
  font-size: 1.666vw;
  padding-bottom: 70px;
  font-weight: 500;
  font-size: clamp(22px, 1.666vw, 32px);
  opacity: 1;
  transition: all 1s ease-in-out;

  ${RWD_SM(css`
    font-size: 16px;
  `)};

  /* ${BannerWrapper}:hover & {
    opacity: 1;
    transition-delay: 0.5s;
  } */
`;

const CoverButtonWrapper = styled.div`
  opacity: 1;
  transition: all 1s ease-in-out;
  /* ${BannerWrapper}:hover & {
    opacity: 1;
    transition-delay: 0.7s;
  } */
`;

const ParallaxWrapperFirst = styled.div`
  /* z-index: 1; */
  position: absolute;
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  display: flex;
  width: 100%;

  img {
    height: calc(100vh - 9.25vh);
    height: calc(var(--vh, 1vh) * 90.75);
    object-fit: cover;
    object-position: center;
  }

  ${RWD_LG(css`
    img {
      object-position: top;
    }
  `)};

  /* clip-path: polygon(36% 0%, 100% 0%, 100% 100%, 15% 100%); */

  clip-path: polygon(0 0, 35% 0, 15% 100%, 0 100%);
  /* background-color: yellow; */
  /* background-image: url(${EventImg1}); */
  /* background-size: contain; */
  background-position: center center;
`;

const ParallaxWrapperSecond = styled.div`
  /* z-index: 2; */
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  width: 100%;
  position: absolute;

  img {
    height: calc(100vh - 9.25vh);
    height: calc(var(--vh, 1vh) * 90.75);
    object-fit: cover;
    object-position: center;
  }

  /* background-image: url(${BannerImg});
  background-size: cover;
  background-position: center center; */
  clip-path: polygon(35% 0, 82% 0, 61% 100%, 15% 100%);
  background-color: aqua;
`;

const ParallaxWrapperThird = styled.div`
  /* z-index: 3; */
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  position: absolute;
  width: 100%;
  right: 0;
  /* border: 1px solid yellow; */

  img {
    height: calc(100vh - 9.25vh);
    height: calc(var(--vh, 1vh) * 90.75);
    object-fit: cover;
    object-position: center;
  }

  ${RWD_LG(css`
    img {
      object-position: bottom;
    }
  `)};
  /* background-image: url(${BannerImg});
  background-size: cover;
  background-position: center center; */
  clip-path: polygon(81% 0, 100% 0, 100% 100%, 61% 100%);
  background-color: pink;
`;

const ParallaxWrapper = styled.div`
  /* z-index: 3; */
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  position: absolute;
  width: 100%;
  right: 0;
  background-position: center center;
  overflow: hidden;

  ${RWD_SM(css`
    height: 87.5vh;
    height: calc(var(--vh, 1vh) * 87.5);
  `)};

  /* border: 1px solid yellow; */

  /* ${RWD_LG(css`
    img {
      object-position: bottom;
    }
  `)}; */
  /* background-image: url(${BannerImg});
  background-size: cover;
  background-position: center center; */
  /* clip-path: polygon(81% 0, 100% 0, 100% 100%, 61% 100%);
  background-color: pink; */
`;

const slide = keyframes`
  0% {
        left: 100%;
        width: 100%;
        opacity: 1;
    }

    5% {
        left: 0%;
    }

    20% {
        left: 0%;
    }

     25% {
        left: -100%;
        width: 100%;
        opacity: 1;
    }

    25.0001% {
        left: -100%;
        width: 0%;
        opacity: 0;
    } 

    100% {
        left: 100%;
        width: 100%;
        opacity: 0;
    } 
`;

const SliderWrapper = styled.div`
  display: block;
  width: 100%;
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  overflow: hidden;
  position: relative;

  ${RWD_SM(css`
    height: 87.5vh;
    height: calc(var(--vh, 1vh) * 87.5);
    /* background-image: url(${BannerImg_Mob}); */
    display: flex;
    align-items: flex-end;
    justify-content: center;
  `)};
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  /* aspect-ratio: calc(16 / 9); */
  position: relative;
  overflow: hidden;
  /* background-size: cover;
  background-position: center;
  animation: ${slide} 15s infinite;
  background-image: ${({ $BannerImage }) => `url(${$BannerImage})`};
  background-size: cover;
  background-position: center; */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }

  /* &:nth-child(1) {
    left: 0%;
    animation-delay: -1s;
  }

  &:nth-child(2) {
    left: 100%;
    animation-delay: 2s;
  }

  &:nth-child(3) {
    left: 100%;
    animation-delay: 5s;
  }

  &:nth-child(4) {
    left: 100%;
    animation-delay: 8s;
  }

  &:nth-child(5) {
    left: 100%;
    animation-delay: 11s;
  } */
`;

const SlideContent = styled.div`
  position: absolute;
  bottom: 0;
  height: 40%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
  z-index: 2;
`;

const SlideButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: space-between;
  z-index: 2;
`;

const SlideButton = styled.div`
  display: inline-flex;
  color: rgba(255, 255, 255, 0.6);
  font-size: 30px;
  padding: 0 20px;
  cursor: pointer;
`;

const handleAnimationType = (active, state, index) => {
  switch (state) {
    case false:
      if (index === 0) {
        return "translate3d(-35%, 0, 0)";
      } else if (index === 2) {
        return "translate3d(35%, 0, 0)";
      }

    default:
      return "translate3d(0%, 0, 0)";
  }
};

const ParallaxImg = styled.img`
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  transition: all 1s linear;
  transform: ${({ $active, $state, $index }) =>
    handleAnimationType($active, $state, $index)};

  ${RWD_SM(css`
    height: 87.5vh;
    height: calc(var(--vh, 1vh) * 87.5);
  `)};
`;

// const Trail = () => {
//   const [state, setState] = useState(false);
//   const [open, setOpen] = useState(true);
//   const springRef = useSpringRef();
//   const springProps = useSpring({
//     ref: springRef,
//     config: { mass: 1, tension: 1000, duration: 1500 },

//     from: {
//       transform: "translate3d(100%, 0, 0)",
//       position: "absolute",
//       top: 0,
//       width: "100%",
//     },
//     to: {
//       transform: state ? "translate3d(0%, 0, 0)" : "translate3d(100%, 0, 0)",
//       opacity: state ? 1 : 0,
//     },
//     onProps: ({ to }) => {
//       if (to.transform === "translate3d(0%, 0, 0)") {
//         setOpen(false);
//       } else {
//         setOpen(true);
//       }
//     },

//     onRest: () => {
//       (function () {
//         window.setTimeout(function () {
//           setState(false);

//           if (active == "0" && !state) {
//             console.log("現在的active" + active);
//             handleClick(1);
//           }
//           if (active == "1" && !state) {
//             console.log("現在的active" + active);

//             handleClick(2);
//           }
//           if (active == "2" && !state) {
//             console.log("現在的active" + active);

//             handleClick(0);
//           }
//         }, 2500);
//       })();
//     },
//   });
//   // const items = [Banner1, Banner2, Banner3];

//   const [active, setActive] = useState("");

//   const slides = [
//     "photo-1544511916-0148ccdeb877",
//     "photo-1544572571-ab94fd872ce4",
//     "reserve/bnW1TuTV2YGcoh1HyWNQ_IMG_0207.JPG",
//   ];

//   const BannerList = [Banner_1, Banner_2, Banner_3, Banner_4];
//   const EventList = [
//     {
//       title: "Al Physio",
//       content: "精準診斷，智慧分析，運動姿勢的科學追蹤。輕鬆掌握身體平衡與肌",
//     },
//     {
//       title: "身",
//       content:
//         "深入探索您的身體健康：透過內分泌檢測、消化系統評估、重金屬檢測，以及食物過敏測試，揭露身體內在的平衡與需求。",
//     },
//     {
//       title: "心",
//       content:
//         "強化心靈韌性，培養堅定的意志力：透過專業訓練，提升面對挑戰的能力，塑造更堅強的心靈。",
//     },
//     {
//       title: "靈",
//       content:
//         "通過禪坐和脈輪平衡，連結您的內在精神：開啟心靈之門，探索內在和諧與平靜的奧秘。",
//     },
//   ];

//   // const SloganList = [
//   //   {
//   //     title: "身：檢測與調整",
//   //     content: "全面檢測身體健康，提供個性化調整方案，重建身體平衡。",
//   //   },
//   //   {
//   //     title: "心：訓練與強化",
//   //     content: "透過專業訓練，提高心靈韌性，強化個人意志力，增強心理素質。",
//   //   },
//   //   {
//   //     title: "靈：穩定與和諧",
//   //     content: "結合禪坐與脈輪平衡技巧，引導內在和諧，促進靈性穩定。",
//   //   },
//   // ];

//   const config = { mass: 5, tension: 2000, friction: 500 };
//   const trailRef = useSpringRef();
//   // const trail = useTrail(items.length, {
//   //   config,
//   //   ref: trailRef,
//   //   // opacity: state ? 1 : 1,
//   //   // x: state ? 0 : 0,
//   //   // opacity: state ? 1 : 1,

//   //   // from: { opacity: 0, x: 20 },
//   //   from: {
//   //     opacity: 0.5,
//   //     x: 5,
//   //   },
//   //   to: {
//   //     opacity: 1,
//   //     x: 0,
//   //   },

//   //   // to: { opacity: state ? 1 : 0, x: state ? 20 : 10 },
//   // });

//   const wordTrail = useTrail(3, {
//     config: { mass: 5, tension: 2000, friction: 1000 },

//     opacity: state ? 1 : 0,
//     x: state ? 0 : 20,
//   });

//   const handleClick = (i) => {
//     setActive(i);
//     setState(true);
//   };

//   const coverProps = useSpring({
//     from: { opacity: 0 },
//     to: { opacity: open ? 1 : 0 },
//     config: { duration: 500 },
//     delay: 500,
//   });
//   // const [coverProps = useSpring({
//   //   config: { duration: 1800 },

//   //   from: {
//   //     opacity: 0,
//   //   },
//   //   to: {
//   //     opacity: 1,
//   //   },
//   //   // onRest: () => {
//   //   //   if (active == "") {
//   //   //     console.log("active 是空的");
//   //   //   } else {
//   //   //     console.log("active 不是空的");
//   //   //   }
//   //   //   // console.log(active == "");
//   //   // },

//   //   // handleClick(0);
//   // });

//   const boxApi = useSpringRef();

//   // useEffect(() => {
//   //   boxApi.start();
//   // });

//   useChain([springRef], [0, 0.6]);
//   // useChain(true ? [boxApi, springRef] : [boxApi, springRef], [0, 0.6]);

//   // const boxSprings = useSprings(
//   //   items.length,
//   //   items.map((item, i) => ({
//   //     from: {
//   //       opacity: 0.5,
//   //       x: 5,
//   //     },
//   //     to: {
//   //       opacity: 1,
//   //       x: 0,
//   //     },
//   //     delay: i * 200,
//   //     config: {
//   //       mass: 2,
//   //       tension: 220,
//   //     },
//   //     onStart: () => {
//   //       console.log("start222");
//   //     },
//   //     onRest: () => {
//   //       if (i == "2") {
//   //         (function () {
//   //           window.setTimeout(function () {
//   //             handleClick(0);
//   //           }, 2500);
//   //         })();
//   //       }
//   //     },
//   //   }))
//   // );

//   // const [boxSprings] = useSprings(
//   //   3,
//   //   (i) => ({
//   //     ref: boxApi,
//   //     from: {
//   //       opacity: 0.5,
//   //       x: 5,
//   //     },
//   //     to: {
//   //       opacity: 1,
//   //       x: 0,
//   //     },
//   //     delay: i * 200,
//   //     config: {
//   //       mass: 2,
//   //       tension: 220,
//   //     },
//   //     onStart: () => {
//   //       console.log("start");
//   //     },
//   //     onRest: () => {
//   //       if (i == "2") {
//   //         (function () {
//   //           window.setTimeout(function () {
//   //             handleClick(0);
//   //           }, 2500);
//   //         })();
//   //       }
//   //     },
//   //   }),
//   //   []
//   // );

//   const props = [
//     useSpring({
//       config: { duration: 2000, mass: 2, tension: 2000, friction: 1000 },

//       from: {
//         // clipPath: "polygon(0% 0%, 35% 0% 15% 100%, 0% 100%)",
//         position: "absolute",
//         width: "100%",
//       },
//       to: {
//         // opacity: state && active != "0" ? 0 : 1,

//         transform:
//           state && active != "0"
//             ? "translate3d(-42.5%, 0, 0)"
//             : "translate3d(0%, 0, 0)",

//         clipPath:
//           state && active == "0"
//             ? "polygon(0% 0%, 129% 0%, 107% 100%, 0% 100%)"
//             : "polygon(0% 0%, 37% 0%, 17% 100%, 0% 100%)",
//       },
//     }),
//     useSpring({
//       config: { duration: 2000, mass: 2, tension: 2000, friction: 1000 },

//       from: {
//         // clipPath: "polygon(35% 0%, 82% 0%, 61% 100%, 15% 100%)",
//         position: "absolute",
//         width: "100%",
//       },
//       to: {
//         // opacity: state && active != "1" ? 0 : 1,

//         transform:
//           state && active != "1"
//             ? active == "0"
//               ? "translate3d(85%, 0, 0)"
//               : "translate3d(-85%, 0, 0)"
//             : "translate3d(0%, 0, 0)",
//         clipPath:
//           state && active == "1"
//             ? "polygon(-12% 0%, 129% 0%, 109% 100%, -32% 100%)"
//             : "polygon(37% 0%, 80% 0%, 59% 100%, 17% 100%)",
//       },
//     }),
//     useSpring({
//       config: { duration: 2000, mass: 2, tension: 2000, friction: 1000 },

//       from: {
//         // clipPath: "polygon(82% 0%, 100% 0%, 100% 100%, 61% 100%)",
//         position: "absolute",
//         width: "100%",
//       },
//       to: {
//         opacity: state && active != "2" ? 0 : 1,
//         transform:
//           state && active != "2"
//             ? "translate3d(42.5%, 0, 0)"
//             : "translate3d(0%, 0, 0)",
//         clipPath:
//           state && active == "2"
//             ? "polygon(-12% 0%, 100% 0%, 100% 100%, -31% 100%)"
//             : "polygon(80% 0%, 100% 0%, 100% 100%, 59% 100%)",
//         // state
//         //   ? active == "2"
//         //     ? "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
//         //     : "polygon(81% 0, 100% 0, 100% 100%, 61% 100%)"
//         //   : "polygon(81% 0, 100% 0, 100% 100%, 61% 100%)",
//       },
//     }),
//   ];

//   return (
//     //
//     <ParallaxWrapper>
//       {boxSprings.map(({ x, ...otherProps }, i) => (
//         <animated.div
//           key={items[i]}
//           style={{
//             ...otherProps,
//             position: "absolute",
//             width: "100%",
//             transform: x.to((x) => `translate3d(${x}px, 0px, 0px)`),
//           }}
//         >
//           <animated.div
//             style={{
//               ...props[i],
//             }}
//           >
//             <ParallaxImg
//               $index={i}
//               $state={state}
//               $active={active}
//               src={BannerList[i]}
//               // onClick={() => handleClick(i)}
//             />
//           </animated.div>
//         </animated.div>
//       ))}
//       <animated.div
//         style={{
//           ...coverProps,
//           position: "absolute",
//           width: "100%",
//           bottom: 0,
//         }}
//       >
//         {/* <WaveWrapper>
//           <img src={WaveImg} />

//           <SloganWrapper>
//             <SloganTitle>{SloganList[active]?.title}</SloganTitle>
//             <SloganContent>{SloganList[active]?.content}</SloganContent>
//           </SloganWrapper>
//         </WaveWrapper> */}
//       </animated.div>
//       <animated.div style={springProps}>
//         <Cover>
//           <CoverContentWrapper>
//             <CoverTitle> {EventList[active]?.title}：</CoverTitle>
//             <CoverContent>{EventList[active]?.content}</CoverContent>

//             {/* <CoverButtonWrapper>
//               <Button text={"See More About Body"} />
//             </CoverButtonWrapper> */}
//           </CoverContentWrapper>
//         </Cover>
//       </animated.div>

//       {/* <div class="vertical-container">
//         <div class="vertical"></div>
//         <div class="vertical"></div>
//         <div class="vertical"></div>
//       </div> */}
//     </ParallaxWrapper>
//   );
// };

// const Transition = () => {
//   const divList = [
//     "polygon(0 0, 35% 0, 15% 100%, 0 100%)",
//     "polygon(35% 0, 82% 0, 61% 100%, 15% 100%)",
//     "polygon(81% 0, 100% 0, 100% 100%, 61% 100%)",
//   ];

//   const [imgList, setImgList] = useState([Banner1, Banner2, Banner3]);

//   const slides = [
//     "photo-1544511916-0148ccdeb877",
//     "photo-1544572571-ab94fd872ce4",
//     "reserve/bnW1TuTV2YGcoh1HyWNQ_IMG_0207.JPG",
//   ];
//   const [index, set] = useState(0);
//   const transitions = useTransition(index, {
//     key: index,
//     from: { opacity: 0, transform: "translateX(60px)" },
//     enter: { opacity: 1, transform: "translateX(0px)" },
//     leave: { opacity: 0, transform: "translateX(30px)" },
//     config: { duration: 2500 },
//     // onRest: (_a, _b, item) => {
//     //   if (index === item) {
//     //     set((state) => (state + 1) % slides.length);
//     //   }
//     // },
//     exitBeforeEnter: true,
//   });

//   const handleClick = (i) => {
//     console.log(i);
//   };

//   return (
//     <>
//       {Array.from({ length: 3 }, (v, index) => (
//         <ParallaxWrapper>
//           {transitions((style, i) => (
//             <animated.div
//               // className={"bg"}
//               style={{
//                 ...style,
//                 // clipPath: `${divList[index]}`,
//                 // backgroundImage: `url(https://images.unsplash.com/${slides[index]}?w=1920&q=80&auto=format&fit=crop)`,
//               }}
//             >
//               <img
//                 style={
//                   {
//                     // ...style,
//                     // clipPath: `${divList[index]}`,
//                     // backgroundImage: `url(https://images.unsplash.com/${slides[index]}?w=1920&q=80&auto=format&fit=crop)`,
//                   }
//                 }
//                 onClick={(index) => handleClick(index)}
//                 src={`${imgList[index]}`}
//               />
//             </animated.div>
//             // <animated.div
//             //   className={"bg"}
//             //   style={{
//             //     ...style,
//             //     clipPath: `${divList[index]}`,
//             //     backgroundImage: `url(https://images.unsplash.com/${slides[index]}?w=1920&q=80&auto=format&fit=crop)`,
//             //   }}
//             // />
//           ))}
//         </ParallaxWrapper>
//       ))}
//     </>
//   );
// };

// const Page = ({ offset, gradient, onClick }) => {
//   const windowSize = useOutletContext();
//   const [imgList, setImgList] = useState([Banner1, Banner2, Banner3]);
//   useEffect(() => {
//     if (windowSize[0] <= "1024") {
//       setImgList([BannerImg_Mob1, BannerImg_Mob2, BannerImg_Mob3]);
//     } else {
//       setImgList([Banner1, Banner2, Banner3]);
//     }
//   }, [windowSize]);

//   return (
//     <>
//       <ParallaxLayer offset={offset} speed={0.5} onClick={onClick}>
//         {/* <ParallaxWrapperFirst /> */}
//         <ParallaxWrapperFirst>
//           {/* <img src={imgList[0]} /> */}
//         </ParallaxWrapperFirst>

//         {/* <div className={styles.slopeBegin} /> */}
//       </ParallaxLayer>

//       <ParallaxLayer offset={offset} speed={0.4} onClick={onClick}>
//         {/* <ParallaxWrapperSecond /> */}
//         <ParallaxWrapperSecond>
//           {/* <img src={imgList[1]} /> */}
//         </ParallaxWrapperSecond>
//       </ParallaxLayer>

//       <ParallaxLayer offset={offset} speed={0.3}>
//         <ParallaxWrapperThird>
//           {/* <img src={imgList[2]} /> */}
//         </ParallaxWrapperThird>
//       </ParallaxLayer>
//     </>
//   );
// };

const SliderComponent = () => {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const interval = 3000;
  const autoPlay = true;

  const sliderRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);

  const intervalId = useRef(null);

  const images = [Banner_1, Banner_2, Banner_3, Banner_4, Banner_5];
  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: -1,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    setSlideIndex(sliderRef.current?.swiper.realIndex);
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    setSlideIndex(sliderRef.current?.swiper.realIndex);
    sliderRef.current.swiper.slideNext();
  }, []);

  const navigate = useNavigate();
  const BannerList = [Banner_5, Banner_1, Banner_2, Banner_3, Banner_4];

  const EventList = [
    {
      main: "功能醫學",
      title: "整合與創新",
      content: "結合傳統與現代科學之醫學，塑造個人化健康之道",
      link: "about/functional_medicine",
      img: Banner_5,
    },
    {
      main: "Al Physio",
      title: "診斷與分析",
      content: "精準診斷，智慧分析，運動姿勢的科學追蹤。輕鬆掌握身體平衡與肌",
      img: Banner_1,
    },
    {
      main: "身",
      title: "檢測與調整",
      content:
        "深入探索您的身體健康：透過內分泌檢測、消化系統評估、重金屬檢測，以及食物過敏測試，揭露身體內在的平衡與需求",
      img: Banner_2,
    },
    {
      main: "心",
      title: "訓練與強化",
      content:
        "強化心靈韌性，培養堅定的意志力：透過專業訓練，提升面對挑戰的能力，塑造更堅強的心靈",
      img: Banner_3,
    },
    {
      main: "靈",
      title: "穩定與和諧",
      content:
        "通過禪坐和脈輪平衡，連結您的內在精神：開啟心靈之門，探索內在和諧與平靜的奧秘",
      img: Banner_4,
    },
  ];

  return (
    <SliderWrapper>
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        ref={sliderRef}
        modules={[Autoplay, Navigation]}
        className="banner-slide"
      >
        {EventList.map((item, index) => (
          <SwiperSlide key={index}>
            <Slide onClick={() => navigate(EventList[index].link)}>
              <img src={item.img} />
              <SlideContent>
                <SloganWrapper>
                  <SloganMain>{item.main}</SloganMain>
                  <SloganSubWrapper>
                    <SloganTitle>{item.title}</SloganTitle>
                    <SloganContent>{item.content}</SloganContent>
                  </SloganSubWrapper>
                </SloganWrapper>
              </SlideContent>
            </Slide>
          </SwiperSlide>
        ))}
      </Swiper>
      <SlideButtonWrapper>
        <SlideButton onClick={handlePrev}>
          <IoIosArrowBack />
        </SlideButton>
        <SlideButton onClick={handleNext}>
          <IoIosArrowForward />
        </SlideButton>
      </SlideButtonWrapper>

      {/* {BannerList.map((item, index) => (
        <Slide
          $BannerImage={item}
          onClick={() => navigate(EventList[index].link)}
        ></Slide>
      ))} */}
    </SliderWrapper>
  );
};

const Banner = () => {
  const windowSize = useOutletContext();
  const parallax = useRef(null);
  const scroll = (to) => {
    if (parallax.current) {
      parallax.current.scrollTo(to);
    }
  };

  useEffect(() => {
    scroll(1);
  }, [windowSize]);

  const pages = [
    ({ style }) => (
      <animated.div
        style={{
          ...style,
          backgroundImage:
            "url(https://images.unsplash.com/photo-1545569341-9eb8b30979d9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)",
        }}
      />
    ),
  ];

  return (
    <Wrapper>
      <Container>
        {/* {Array.from({ length: 3 }, (v, i) => (
          <ProgressItem key={i} status={activeStep >= i}>
            <ProgressItemNumber status={activeStep >= i}>
              {i + 1}
            </ProgressItemNumber>
            <ProgressItemText status={activeStep >= i}>
              {stepDirection[i]}
            </ProgressItemText>
          </ProgressItem>
        ))} */}

        {/* <Parallax
          className={"ParallaxContainer"}
          ref={parallax}
          pages={2}
          horizontal
          //   horizontal
        >
          <Page offset={0} windowSize={windowSize} />
          <Page offset={1} windowSize={windowSize} />
          <Page offset={2} windowSize={windowSize} />
        </Parallax> */}

        <SliderComponent />

        {/* <Trail /> */}

        {/* <Transition pages={pages} /> */}
        {/* <WaveWrapper>
            <img src={WaveImg} />

            <SloganWrapper>
              <SloganTitle>Achieve Wholeness:</SloganTitle>
              <SloganContent>Nurturing Body, Mind, and Spirit.</SloganContent>
            </SloganWrapper>
          </WaveWrapper> */}

        {/* <W>
            <WW>
              <SloganWrapper>
                <SloganTitle>Achieve Wholeness:</SloganTitle>
                <SloganContent>Nurturing Body, Mind, and Spirit.</SloganContent>
              </SloganWrapper>
            </WW>
          </W> */}

        {/* <Cover>
            <img src={CoverImg} />
            <CoverContentWrapper>
              <CoverTitle>This is the title of the feature.</CoverTitle>
              <CoverContent>
                This is the copy of the introduction. This is the copy of the
                introduction. This is the copy of the introduction. This is the
                copy of the introduction.
              </CoverContent>

              <CoverButtonWrapper>
                <Button text={"See More About Body"} />
              </CoverButtonWrapper>
            </CoverContentWrapper>
          </Cover> */}
        {/* <BannerWrapper></BannerWrapper> */}
      </Container>
    </Wrapper>
  );
};

export default Banner;
