import { styled, css } from "styled-components";
import { RWD_MD, RWD_SM } from "../../../../../utils/rwd";

import therapy from "../../../../../images/timewaver/product/therapy.jpg";
import plan from "../../../../../images/timewaver/product/plan.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const TitleWrapper = styled.div`
  padding-bottom: 50px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};

  ${RWD_SM(css`
    text-align: left;
  `)};
`;

const IntroSection = styled.section`
  padding-bottom: 100px;
  display: flex;
  gap: 40px;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const IntroImage = styled.div`
  width: 28vw;
  display: flex;
  background: ${({ theme }) => theme.colors.gray};

  img {
    display: block;
    object-fit: contain;
  }

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const IntroContent = styled.div`
  width: calc(100% - 28vw - 40px);
  text-align: left;

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const InformationSection = styled.section`
  width: 100%;

  padding-bottom: 20px;
  margin-bottom: 50px;
`;

const SubTitle = styled.h4`
  font-size: clamp(18px, 1.666vw, 32px);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
  text-align: left;
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.copyRight};
`;

const InformationContent = styled.div``;

const PriceWrappaer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-family: ${({ theme }) => theme.fonts[2]};
  margin-top: auto;
  justify-content: flex-end;
`;

const Price = styled.div`
  font-size: 48px;
  font-weight: 600;
`;

const PriceCurrency = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const ProductTitle = styled.div`
  font-size: clamp(24px, 2.0833vw, 36px);
  font-weight: 600;
  text-align: left;
`;

const ProductSubTitle = styled.div`
  font-size: clamp(24px, 2.0833vw, 30px);
  font-weight: 600;
  text-align: left;
  margin-bottom: 10px;
`;

const ProductDescription = styled.div``;

const PlanSubTitle = styled.div`
  font-weight: 700;
  font-size: clamp(18px, 2.0833vw, 20px);
  color: ${({ theme }) => theme.colors.mainBrown};
`;

const ProductList = styled.ul`
  margin-top: auto;
  padding-top: 16px;
  padding-left: 20px;
`;

const ProductListItem = styled.li`
  padding: 5px 0;
`;

export default function Therapy() {
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>TimeWaver</Title>
        {/* <Title>A Large Coil Plate 頻率發射板</Title> */}
      </TitleWrapper>
      <IntroSection>
        <IntroImage>
          <img src={therapy} alt="therapy" />
        </IntroImage>
        <IntroContent>
          {/* <ProductTitle>TimeWaver</ProductTitle> */}
          <ProductSubTitle>微電流訊息場頻率療程 (單次療程)</ProductSubTitle>
          <ProductDescription>
            <p>
              TimeWaver創新地將量子訊息技術，以及微電流頻率結合在一起，透過連繫肉眼看不到量子訊息牆，就可以分析你所需的頻率和能量。而TimeWaver身心調頻是一套系統，只需用手提電腦操作，再通過不同的電極裝置和軟件，就可以將適合你的頻率導入身體。TimeWaver
              Frequency所使用的頻率，是由葡萄牙整合醫學專家 Nuno
              Nina經過多年臨床經驗與實證所提供，現已收集超過50,000條頻率程式。
            </p>
            <p>
              個人化的電流頻率應用，在TimeWaver身心調頻系統中是很嶄新的概念。系統會即時選取出用家所需的頻率，同時將之輸出及套用在他們身上。配合同步分析功能，系統會監測著頻率的應用時間和進程，在適合的時間點，就會自動轉去下一個需要被調整的頻率，直至完成調頻為止。
            </p>
          </ProductDescription>
          <PriceWrappaer>
            <PriceCurrency>HKD</PriceCurrency>
            <Price>2,800</Price>
          </PriceWrappaer>
        </IntroContent>
      </IntroSection>
      <InformationSection>
        <SubTitle>為何要定期用TimeWaver調頻？</SubTitle>
        <InformationContent>
          <p>
            在原子及次原子的層面上，沒有東西是靜止的，一切都在振動，並會彼此共振出以Hz作為量度單位的頻率；頻率也有高低頻之分，以它們在同一段時間內的振蕩次數來劃分。
          </p>
          <p>
            我們體內的細胞都被分配以不同的振頻，去維持不同部分的運作，就像一隊交響樂團。但如果其中一件「樂器」走音了，整個人體結構的和諧都會受影響。所以TimeWaver就可以幫我們調整好不對調的頻率，確保身體的協調平衡。
          </p>
          <p>
            頻率也是細胞之間溝通的重要語言，Nuno
            Nina對於頻率具備豐富研究和學識，讓他成功開發一套黃金頻率，有效促進細胞壁的復原和身體正常運作。
          </p>
        </InformationContent>
      </InformationSection>

      <InformationSection>
        <SubTitle>生物電子醫學的好處？</SubTitle>
        <InformationContent>
          <p>
            如果頻率是細胞之間的溝通語言，只要用上對的電流幅度、震波和頻率，就可以更容易直達疾病根源，根治身體問題。因為每一種病都有自己專屬的特定頻率，所以很多疾病都可以透過導入另一種不同的頻率來治癒。
          </p>
          <p>
            當我們想維持或回復身體健康，我們需要確保離子返回了他的所屬位置，以達至身體平衡。因為離子是帶有正或負電荷的原子，所以如果電子身處錯的位置，就需要用電流去重整身體秩序。
          </p>
        </InformationContent>
      </InformationSection>

      <InformationSection>
        <SubTitle>TimeWaver調頻是怎樣運作？</SubTitle>
        <InformationContent>
          <p>
            TimeWaver的作用，就是讓錯位的電子，重回其正確位置，以維持細胞的正常運作。
          </p>
          <p>
            TimeWaver的系統是利用微電流頻率，來直接進行疾病治療，或與其他傳統療法相輔相成。
          </p>
        </InformationContent>
      </InformationSection>

      <InformationSection>
        <SubTitle>甚麼情況可以用TimeWaver調頻？</SubTitle>
        <InformationContent>
          <p>
            TimeWaver身心調頻是從細胞層面上處理身體問題，所以無論在緩解急性、慢性健康失衡情，或預防疾病上，都可以廣泛使用，包括：
          </p>
          <p>- 身體上的痛症，例如頭痛、關節炎、肌肉酸痛、三叉神經痛、經痛⋯⋯</p>
          <p>
            -
            心理上的疑難雜症，例如抑鬱、焦慮、壓力、精神疲勞、專注力失調、記憶力衰退⋯⋯
          </p>
          <p>
            - 腫瘤或退化性疾病
            生物電子醫學適用於不同個案，除了在病患時幫助恢復身體健康，平日都可以發揮日常保健的作用。
          </p>
        </InformationContent>
      </InformationSection>

      {/* <IntroSection>
        <IntroImage>
          <img src={plan} alt="timewaver_plan" />
        </IntroImage>
        <IntroContent>
          <ProductSubTitle>Timewaver 精選組合套餐</ProductSubTitle>
          <ProductDescription>
            <PlanSubTitle>健康專業，輕鬆掌握！</PlanSubTitle>
            <p>
              選擇 Timewaver
              頻率專業組合套裝，體驗先進的頻率療法科技。無論是專業醫療機構或個人健康管理，這套設備都能助您輕鬆掌控。
            </p>
            <p>
              套裝內含完整設備、配套電腦、專業配件、培訓課程，以及一整年技術支援，讓您從零到專家，全程無後顧之憂！
            </p>
            <PlanSubTitle>本套餐組合包含以下內容</PlanSubTitle>
            <ProductList>
              <ProductListItem>Timewaver Frequency 設備一套</ProductListItem>
              <ProductListItem>配套電腦</ProductListItem>
              <ProductListItem>所有必要專業配件</ProductListItem>
              <ProductListItem>專業培訓課程</ProductListItem>
              <ProductListItem>一年技術支援</ProductListItem>
            </ProductList>
          </ProductDescription>
          <PriceWrappaer>
            <PriceCurrency>HKD</PriceCurrency>
            <Price>210,000</Price>
          </PriceWrappaer>
        </IntroContent>
      </IntroSection> */}

      {/* <IntroSection>
        <IntroImage>
          <img src={plan} alt="timewaver_plan" />
        </IntroImage>
        <IntroContent>
          <ProductSubTitle>Timewaver Pro 專業組合套餐</ProductSubTitle>
          <ProductDescription>
            <PlanSubTitle>突破極限，選擇 Timewaver Pro！</PlanSubTitle>
            <p>
              擁有 Timewaver Pro
              專業設備組合，體驗創新科技與專業支援的全方位健康方案。從深度療癒到精準診斷，Timewaver
              Pro 帶來不可思議的健康突破，幫助您提升治療效果、加速恢復！
            </p>
            <p>
              立即升級您的健康管理系統，擁有專業級配件、功能強大電腦、專業培訓課程和一年技術支援，全方位支持您的專業需求！
            </p>
            <PlanSubTitle>本套餐組合包含以下內容</PlanSubTitle>
            <ProductList>
              <ProductListItem>Timewaver Frequency 設備一套</ProductListItem>
              <ProductListItem>配套電腦</ProductListItem>
              <ProductListItem>所有必要專業配件</ProductListItem>
              <ProductListItem>Timewaver Pro 專屬培訓課程</ProductListItem>
              <ProductListItem>一年技術支援</ProductListItem>
            </ProductList>
          </ProductDescription>
          <PriceWrappaer>
            <PriceCurrency>HKD</PriceCurrency>
            <Price>480,000</Price>
          </PriceWrappaer>
        </IntroContent>
      </IntroSection> */}
    </Wrapper>
  );
}
