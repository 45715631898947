import { createApi } from "@reduxjs/toolkit/query/react";
import defaultBaseQuery from "./query/defaultBaseQuery";

export const manageApi = createApi({
  reducerPath: "manageApi",
  baseQuery: defaultBaseQuery,
  endpoints: (builder) => ({
    createArticle: builder.mutation({
      query: ({ Title, Content, TagList }) => ({
        url: "/Manage/CreateArticle",
        method: "POST",
        body: JSON.stringify({
          Title,
          Content,
          TagList,
        }),
      }),
    }),
    getArticleDetail: builder.mutation({
      query: ({ articleId }) => ({
        url: `/Manage/EditArticle?articleId=${articleId}`,
        method: "GET",
      }),
    }),
    updateArticle: builder.mutation({
      query: ({ articleId, articleTitle, articleContent, tagList }) => ({
        url: `/Manage/EditArticle`,
        method: "POST",
        body: JSON.stringify({
          articleId,
          articleTitle,
          articleContent,
          tagList,
        }),
      }),
    }),
    getTagList: builder.query({
      query: () => ({
        url: "/Manage/TagList",
        method: "GET",
      }),
    }),
    createTag: builder.mutation({
      query: ({ Tag }) => ({
        url: "/Manage/CreateTag",
        method: "POST",
        body: JSON.stringify(Tag),
      }),
    }),
  }),
});

export const {
  useCreateArticleMutation,
  useGetArticleDetailMutation,
  useUpdateArticleMutation,
  useGetTagListQuery,
  useCreateTagMutation,
} = manageApi;
