import { styled, css } from "styled-components";
import { useState } from "react";
import { RWD_MD, RWD_SM } from "../../../../../utils/rwd";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

import plate_1 from "../../../../../images/timewaver/product/plate_1.jpg";
import plate_2 from "../../../../../images/timewaver/product/plate_2.jpg";
import plate_3 from "../../../../../images/timewaver/product/plate_3.png";
import plate_4 from "../../../../../images/timewaver/product/plate_4.png";
import plate_5 from "../../../../../images/timewaver/product/plate_5.png";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const TitleWrapper = styled.div`
  padding-bottom: 50px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};

  ${RWD_SM(css`
    text-align: left;
  `)};
`;

const Slide = styled.div`
  width: 28vw;

  img {
    display: block;
  }

  ${RWD_MD(css`
    width: 83.22vw;
  `)};
`;

const SlideWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 40px;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const SlideContent = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;

  ul {
    padding-left: 20px;
  }

  li {
    text-align: left;
  }

  ${RWD_SM(css`
    /* padding: 20px; */
  `)};
`;

const SlideNotice = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;

  ${RWD_SM(css`
    padding-top: 40px;
  `)};
`;

const SlideTitle = styled.div`
  font-weight: 500;
  text-align: justify;
`;

const ProductList = styled.ul`
  padding-top: 16px;
`;

const ProductListItem = styled.li`
  padding: 5px 0;
`;

const PriceWrappaer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-family: ${({ theme }) => theme.fonts[2]};
  margin-top: auto;
  justify-content: flex-end;
`;

const Price = styled.div`
  font-size: 48px;
  font-weight: 600;
`;

const PriceCurrency = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const ProductTitle = styled.div`
  font-size: clamp(24px, 2.0833vw, 36px);
  font-weight: 600;
`;

const ProductSubTitle = styled.div`
  font-size: clamp(24px, 2.0833vw, 30px);
  font-weight: 600;
`;

const DetailWrapper = styled.div`
  width: 100%;
  padding: 30px;
  background: ${({ theme }) => theme.colors.gray};
  margin-top: 100px;

  ${RWD_SM(css`
    padding: 20px;
  `)};
`;

const NoticeTitle = styled.div`
  font-size: clamp(25px, 1.5625vw, 30px);
  font-weight: 700;
  padding-bottom: 20px;

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const NoticeContent = styled.div`
  text-align: left;
  padding-bottom: 15px;
  font-weight: 500;
`;

const NoticeMainContent = styled.div`
  text-align: left;
  padding-bottom: 20px;
`;

const NoticeSubTitle = styled.div`
  font-weight: 600;
  text-align: left;
  font-size: 20px;
`;

const NoticeSubList = styled.ul`
  list-style-type: circle;
  padding-left: 20px;
  padding-bottom: 50px;
  margin: 0;

  ${RWD_SM(css`
    padding-bottom: 20px;
  `)};
`;

const NoticeSubListItem = styled.li`
  padding: 5px 0;
  text-align: left;
`;

const NoticeList = styled.ul`
  list-style-type: square;
  padding-left: 20px;
  padding-bottom: 50px;

  ${RWD_SM(css`
    padding-bottom: 20px;
  `)};
`;

const NoticeListItem = styled.li`
  padding: 5px 0;
  text-align: left;
`;

const ContactWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.copyRight};
  padding-top: 40px;
  text-align: left;
`;

const ContactTitle = styled.div`
  font-weight: 500;
  padding: 5px 0;
  font-size: 18px;
  text-align: left;
`;

const ContactItem = styled.div`
  font-weight: 500;
  padding: 5px 0;
`;

export default function Plate() {
  const [thumbsSwiper, setThumbsSwiper] = useState("");

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>TimeWaver Home Device Accessory</Title>
        {/* <Title>A Large Coil Plate 頻率發射板</Title> */}
      </TitleWrapper>
      <SlideWrapper>
        <Slide>
          <Swiper
            loop={true}
            spaceBetween={10}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
          >
            {[plate_1, plate_2, plate_3, plate_4, plate_5].map(
              (data, index) => (
                <SwiperSlide key={index}>
                  <img src={data} />
                </SwiperSlide>
              )
            )}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={5}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            style={{ paddingTop: "15px" }}
          >
            {[plate_1, plate_2, plate_3, plate_4, plate_5].map(
              (data, index) => (
                <SwiperSlide key={index}>
                  <img src={data} />
                </SwiperSlide>
              )
            )}
          </Swiper>
        </Slide>
        <SlideContent>
          {/* <ProductTitle>TimeWaver Home Device Accessory</ProductTitle> */}
          <ProductSubTitle>A Large Coil Plate 頻率發射板</ProductSubTitle>

          <ProductList>
            <ProductListItem>產品尺寸：32cm x 32cm</ProductListItem>
            <ProductListItem>覆蓋範圍：3 米左右</ProductListItem>
            <ProductListItem>適用於所有 Program</ProductListItem>
            <ProductListItem>
              適用於無法雙手握 Electrodes (Metal Rods)時候 (i.e.在睡眠期間)
            </ProductListItem>
            <ProductListItem>可以貼近身體使用</ProductListItem>
            <ProductListItem>頻率發射板只傳導磁波(不導電)</ProductListItem>
          </ProductList>
          <PriceWrappaer>
            <PriceCurrency>HKD</PriceCurrency>
            <Price>5,000</Price>
          </PriceWrappaer>

          {/* <SlideTitle>
            **此乃 TimeWaver Home 的額外配件，需要配合儀器一起使用**
          </SlideTitle>
          <ul>
            <li>產品尺寸：32cm x 32cm</li>
            <li>覆蓋範圍：3 米左右</li>
            <li>適用於所有 Program</li>
            <li>
              適用於無法雙手握 Electrodes (Metal Rods)時候 (i.e.在睡眠期間)
            </li>
            <li>可以貼近身體使用</li>
            <li>頻率發射板只傳導磁波(不導電)</li>
          </ul>
          <SlideTitle>**敬請注意**</SlideTitle>
          <SlideTitle>
            如果想達致最佳效果：Electrodes 導電最好，其次是 Gel Pads
          </SlideTitle>
          <SlideNotice>
            <SlideTitle>聯絡方式</SlideTitle>
            <SlideTitle>+852 2323 3488</SlideTitle>
            <SlideTitle>info@adxlife.com</SlideTitle>
          </SlideNotice> */}
        </SlideContent>
      </SlideWrapper>
      <DetailWrapper>
        <NoticeTitle>TimeWaver 的應用</NoticeTitle>
        <NoticeMainContent>
          TimeWaver
          技術被廣泛應用於各個領域，旨在分析和調和信息場，這被認為對身體、情緒和心理健康有著影響。
        </NoticeMainContent>

        <NoticeSubTitle>主要的應用範疇</NoticeSubTitle>
        <NoticeSubList>
          <NoticeSubListItem>
            醫療保健：醫生使用 TimeWaver
            節省時間，並提供全面的患者護理，分析患者生活的各個方面，包括身體、心理和環境因素。
          </NoticeSubListItem>
          <NoticeSubListItem>
            健康：自然療法和替代療法從業者使用
            TimeWaver，提供可持續和整體的患者護理。
          </NoticeSubListItem>
          <NoticeSubListItem>
            運動：運動員和教練使用 TimeWaver 增強表現並管理壓力。
          </NoticeSubListItem>
          <NoticeSubListItem>
            商業：企業家和商業教練使用 TimeWaver 做出明智決策，改善商業表現。
          </NoticeSubListItem>
          <NoticeSubListItem>
            教練輔導：教練使用 TimeWaver 解決複雜問題並提供個性化的輔導。
          </NoticeSubListItem>
        </NoticeSubList>

        <NoticeSubTitle>基礎理論</NoticeSubTitle>
        <NoticeContent>TimeWaver 建立在多種科學和心理學理論之上</NoticeContent>
        <NoticeSubList>
          <NoticeSubListItem>
            量子物理學：認為現實在最深層次上不是物質，而是信息。
          </NoticeSubListItem>
          <NoticeSubListItem>
            深度心理學：例如 C.G. 榮格的集體無意識概念，TimeWaver
            將其整合來分析和調和不平衡。
          </NoticeSubListItem>
          <NoticeSubListItem>
            統一場理論：Burkhard Heim 的理論，TimeWaver 使用此理論探索信息場。
          </NoticeSubListItem>
        </NoticeSubList>

        <NoticeSubTitle>現實應用結果</NoticeSubTitle>
        <NoticeContent>
          TimeWaver 在現實中的應用顯示了有前景的結果
        </NoticeContent>
        <NoticeSubList>
          <NoticeSubListItem>
            提升福祉：使用者報告在使用 TimeWaver
            後，福祉水平有所提升，調查數據顯示了這一趨勢。
          </NoticeSubListItem>
          <NoticeSubListItem>
            增強表現：運動員和專業人士的表現和壓力管理得到改善。
          </NoticeSubListItem>
          <NoticeSubListItem>
            全面護理：醫生和自然療法業者發現 TimeWaver
            是提供全面護理的寶貴工具。
          </NoticeSubListItem>
        </NoticeSubList>

        <NoticeTitle>** 敬請注意 **</NoticeTitle>
        <NoticeList>
          <NoticeListItem>
            此乃 TimeWaver Home 的額外配件，需要配合儀器一起使用
          </NoticeListItem>
          <NoticeListItem>
            如果想達致最佳效果：Electrodes 導電最好，其次是 Gel Pads
          </NoticeListItem>
        </NoticeList>
        <ContactWrapper>
          <ContactTitle>聯絡方式</ContactTitle>
          <ContactItem>聯絡電話：+852 2323 3488</ContactItem>
          <ContactItem>Email：info@adxlife.com</ContactItem>
        </ContactWrapper>
      </DetailWrapper>
    </Wrapper>
  );
}
