import { styled, ThemeProvider } from "styled-components";
import theme from "../../global/BackendTheme";

import Header from "./components/Header";
import Menu from "./components/Menu";
import { Outlet } from "react-router-dom";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  width: calc(100% - 180px);
`;

const BackendLayout = () => {
  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {/* <Header /> */}
        <Menu />
        <Container>
          <Outlet />
        </Container>
      </Wrapper>
    </ThemeProvider>
  );
};

export default BackendLayout;
