import styled, { css } from "styled-components";
import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { selectDropDown } from "../../slices/referenceSlice";

import ArrowImg from "../images/footer/arrow.svg";
import LanguageImg from "../images/footer/language.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  font-size: 16px;
`;

const LanguageIcon = styled.div`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  img {
    width: 30px;
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
`;

const SelectLabelButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 3px;
  align-items: center;
  justify-content: space-between;
  /* cursor: pointer; */
  transition: 0.3s ease;
  width: 100%;
  display: flex;
  padding: 0;
  color: ${({ theme }) => theme.colors.white};
`;

const ArrowIcon = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  transition: 0.3s ease;
  padding-left: 10px;
  ${({ $isVisible }) =>
    $isVisible &&
    css`
      transform: scaleY(-1);
    `}/* border: 1px solid blue; */
`;

const DropdownStyle = styled.div`
  position: absolute;
  bottom: 120%;
  right: 0;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.copyRight};
  overflow: hidden;
  /* overflow-y: scroll; */
  max-height: 20vw;
  z-index: 5;
  width: 100%;
  background: ${({ theme }) => theme.colors.titleBrown};

  ${({ $isVisible }) =>
    !$isVisible &&
    css`
      max-height: 40px;
      visibility: hidden;
    `}
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
  padding: 10px 0px;
  cursor: pointer;
  width: 100%;
  font-size: clamp(12px, 0.9375vw, 18px);

  /* ${(props) =>
    props.active &&
    css`
      color: #009a63;
      font-weight: 500;
    `} */
  &:hover,
  :focus,
  :focus:hover {
    background-color: ${({ theme }) => theme.colors.copyRight};
    color: ${({ theme }) => theme.colors.titleBrown};
    outline: none;
  }
`;

const Select = ({ title, onChange, group, content }) => {
  //   const data = useSelector(selectDropDown).List;

  const dataList = [
    {
      Value: 1,
      Text: "中文",
    },
    {
      Value: 2,
      Text: "English",
    },
  ];

  const [currentValue, setCurrentValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setCurrentValue(content);
  }, [content]);
  const handleOpen = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleValueChange = (value) => {
    setCurrentValue(value);
  };
  const handleChange = (value) => {
    handleValueChange(value);
    // call method, if it exists
    if (onChange) onChange(value);
    // close, after all tasks are finished
    handleClose();
  };

  return (
    <Wrapper>
      <LanguageIcon>
        <img src={LanguageImg} />
      </LanguageIcon>
      <SelectContainer>
        {/* <SelectLabelButton onClick={handleOpen}>
          {dataList.filter((data) => data.Value == currentValue)[0]?.Text}
          <ArrowIcon $isVisible={open}>
            <img src={ArrowImg}></img>
          </ArrowIcon>
        </SelectLabelButton> */}
        <SelectLabelButton>
          {dataList.filter((data) => data.Value == currentValue)[0]?.Text}
          <ArrowIcon $isVisible={open}>
            <img src={ArrowImg}></img>
          </ArrowIcon>
        </SelectLabelButton>
        <DropdownStyle $isVisible={open}>
          {/* {values.map((value, index) => (
          <DropdownItem
            onClick={() => handleChange(value)}
            active={value === currentValue}
            key={index}
          >
            {value}
          </DropdownItem>
        ))} */}

          {dataList &&
            dataList.map((data) => (
              <DropdownItem
                value={data.Value}
                key={data.Value}
                onClick={() => handleChange(data.Value)}
              >
                {data.Text}
              </DropdownItem>
            ))}
        </DropdownStyle>
      </SelectContainer>
    </Wrapper>
  );
};

export default Select;
