import { createApi } from "@reduxjs/toolkit/query/react";
import defaultBaseQuery from "./query/defaultBaseQuery";

export const crawlerArticleApi = createApi({
  reducerPath: "crawlerArticleApi",
  baseQuery: defaultBaseQuery,
  endpoints: (builder) => ({
    getCrawlerArticleList: builder.query({
      query: ({ page }) => ({
        url: `/CrawlerArticle/CrawlerArticleList?page=${page}`,
        method: "GET",
      }),
    }),
    getCrawlerArticle: builder.query({
      query: ({ startDate, endDate, SearchTitle, page }) => ({
        url: `/CrawlerArticle/GetCrawlerArticle?startDate=${startDate}&endDate=${endDate}&SearchTitle=${SearchTitle}&page=${page}`,
        method: "GET",
      }),
    }),
    getCrawlerArticleDetail: builder.mutation({
      query: ({ originalArticleId }) => ({
        url: `/CrawlerArticle/GetCrawlerArticleDetail?originalArticleId=${originalArticleId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetCrawlerArticleListQuery,
  useGetCrawlerArticleQuery,
  useGetCrawlerArticleDetailMutation,
} = crawlerArticleApi;
