import { styled, css } from "styled-components";
import { useState } from "react";
import LogoImg from "../../../images/backend/logo.png";
import { IoCalendarSharp } from "react-icons/io5";
import { RiComputerFill } from "react-icons/ri";
import { AiFillProfile } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { clearAuthToken } from "../../../utils";

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  height: 100vh;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray};
`;

const Container = styled.div``;

const LogoWrapper = styled.div`
  width: 100px;
  margin-top: 40px;
`;

const SiteName = styled.div`
  font-family: "Vidaloka";
  padding-top: 5px;
  font-size: 18px;
`;

const MenuList = styled.ul`
  padding: 50px 0;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MenuItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin: 0 10px;
  color: ${({ $active, theme }) =>
    $active ? theme.colors.titleBrown : "rgba(67, 49, 11, 0.25)"};
`;

const MenuIcon = styled.div`
  display: flex;
  margin-right: 10px;
  padding: 8px;
  font-size: 20px;
`;

const LogoutWrapper = styled.div`
  margin-top: auto;
  padding-bottom: 30px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuData, setMenuData] = useState([
    {
      id: 1,
      name: "門診時段",
      key: "appointmentSchedule",
      link: "appointmentSchedule",
      icon: <IoCalendarSharp />,
    },
    {
      id: 2,
      name: "遠端時段",
      key: "remoteSchedule",
      link: "remoteSchedule",
      icon: <RiComputerFill />,
    },
    {
      id: 3,
      name: "診療師資訊",
      key: "doctorInfo",
      link: "doctorInfo/1",
      icon: <AiFillProfile />,
    },
  ]);

  const handleLogout = () => {
    console.log("logout");
    clearAuthToken();
    navigate("/login");
  };

  return (
    <Wrapper>
      <LogoWrapper>
        <img src={LogoImg} alt="logo" />
      </LogoWrapper>
      <SiteName>ADX Life</SiteName>
      <MenuList>
        {menuData.map((data) => (
          <MenuItem
            onClick={() => navigate(data.link)}
            key={data.id}
            $active={location.pathname.includes(data.key)}
          >
            <MenuIcon $active={location.pathname.includes(data.key)}>
              {data.icon}
            </MenuIcon>
            {data.name}
          </MenuItem>
        ))}
      </MenuList>
      <LogoutWrapper onClick={handleLogout}>Logout</LogoutWrapper>
    </Wrapper>
  );
};

export default Menu;
