import { styled, css, keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSpring, animated } from "@react-spring/web";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { useEffect, useRef } from "react";

const Wrapper = styled.div`
  position: fixed;
  right: 2%;
  bottom: 3%;
  z-index: 99;

  ${RWD_SM(css`
    right: 20px;
    bottom: 5%;
  `)};
`;

const dot = keyframes`
  0% {
        transform: scale(0.5);
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
    100% {
        transform: scale(1.4);
        opacity: 0;
    }
`;

const bounce = keyframes`

0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
            40% {transform: translateY(-18px);} 
            60% {transform: translateY(-15px);} 
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const AppointmentBtn = styled.div`
  width: clamp(48px, 3.645vw, 70px);
  height: clamp(48px, 3.645vw, 70px);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.mainBrown};
  color: ${({ theme }) => theme.colors.white};

  cursor: pointer;
  filter: drop-shadow(3px 3px 10px rgba(61, 42, 13, 0.05));
  position: relative;
  z-index: 10;
  transition: 0.5s;
  border: 1px solid ${({ theme }) => theme.colors.mainBrown};

  &:hover {
    background: ${({ theme }) => theme.colors.gray};
    color: ${({ theme }) => theme.colors.mainBrown};
  }
`;

const Dot = styled.div`
  width: clamp(50px, 3.75vw, 72px);
  height: clamp(50px, 3.75vw, 72px);
  position: absolute;
  left: -50%;
  top: -50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  animation: ${dot} 2s linear infinite;
  background-color: rgba(139, 116, 66, 0.5);
  z-index: 1;
`;

const DotWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const DotContainer = styled.div``;

const Text = styled.span`
  font-size: clamp(12px, 0.729vw, 16px);
`;

const AppointmentButton = () => {
  const navigate = useNavigate();
  const handleToAppointmentPage = () => {
    navigate(`/appointment`);
  };

  const container = useRef();

  const { contextSafe } = useGSAP({ scope: container });

  const AppointmentBtnAnim = contextSafe(() => {
    gsap.fromTo(
      ".appointmentBtn",
      {
        y: 50,
        opacity: 0,
        duration: 2,
        stagger: 0.3,
        ease: "power4.inOut",
      },
      {
        y: 0,
        opacity: 1,
        duration: 2,
        stagger: 0.3,
        ease: "power4.inOut",
        delay: 10,
      }
    );
  });

  useEffect(() => {
    AppointmentBtnAnim();
  }, []);

  return (
    <Wrapper ref={container}>
      <Container className="appointmentBtn">
        <DotWrapper>
          <Dot></Dot>
        </DotWrapper>
        <AppointmentBtn onClick={handleToAppointmentPage}>
          <Text>點我</Text>
          <Text>預約</Text>
        </AppointmentBtn>
      </Container>
    </Wrapper>
  );
};

export default AppointmentButton;
