import { styled } from "styled-components";
import { MdOutlineError, MdSystemUpdate } from "react-icons/md";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useRef, forwardRef, useEffect } from "react";
import { CiCalendar } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import { addDays, getDay, setHours, setMinutes } from "date-fns";
import { emailFormat } from "../utils";
import moment from "moment";

const MySwal = withReactContent(Swal);

const Wrapper = styled.div``;

const Title = styled.div`
  font-weight: bold;
  font-size: min(max(1.5625vw, 28px), 30px);
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: min(max(1.145vw, 18px), 20px);
  padding-bottom: 20px;
`;

const Link = styled.a``;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfirmButton = styled.button`
  border: 1px solid #b77d38;
  background-color: #b77d38;
  padding: 10px 20px;
  border-radius: 0.25rem;
  color: #fcfcfc;
  min-width: 120px;
  cursor: pointer;
`;

const CancelButton = styled.button`
  border: 1px solid #b77d38;
  padding: 10px 20px;
  border-radius: 0.25rem;
  color: #b77d38;
  margin-right: 10px;
  min-width: 120px;
  cursor: pointer;
  background-color: transparent;
`;

const CalendarWrapper = styled.div`
  padding-bottom: 20px;
  /* width: 100%; */
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(1, 1fr);
`;

const CalendarContainer = styled.button`
  border: 1px solid #b77d38;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  cursor: pointer;
  background: #fefefe;
  border-radius: 5px;
  width: 75%;
  margin: 0 auto;

  /* border-color: ${(props) =>
    props.error ? "#0aecd1" : "rgba(255, 255, 255, 0.15)"}; */
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 10px;
  /* font-size: 0.729vw; */
`;

const IconContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-size: 22px;
  color: #b77d38;
`;

const InputEl = styled.input`
  border: 1px solid #8b7442;
  padding: 10px;
  width: 100%;
  border-radius: 0.25rem;
  font-size: 14px;
  text-align: center;
  background-color: transparent;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const ErrorMessage = styled.div`
  margin-bottom: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 16px;
  color: #cb1b45;
`;

const ErrorIcon = styled.div`
  color: #cb1b45;
  font-size: 20px;
  display: inline-flex;
`;

const AppointmentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  margin-bottom: 20px;
  border: 1px solid #f1dec7;
`;

const InfoTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #f1dec7;

  &:last-child {
    border-bottom: none;
  }
`;

const InfoText = styled.div`
  width: 70%;
  text-align: left;
  padding: 10px;
  font-weight: 500;
`;

const InfoLabel = styled.div`
  width: 30%;
  padding: 10px;
  background-color: #f8f6f2;
`;

const SelectEl = styled.select`
  border: 1px solid #8b7442;
  width: 100%;
  border-radius: 0.25rem;
  font-size: 14px;
  text-align: center;
  background-color: transparent;
  padding: 10px;
`;

const OptionEl = styled.option`
  color: rebeccapurple;

  color: black;

  &:first-of-type {
    color: #9e9e9e;
  }
`;

const CheckBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0 30px 0;
  font-size: 16px;
`;

const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

const CheckBoxInput = styled.input.attrs({ type: "checkbox" })`
  accent-color: #b77d38;
  width: 20px;
  height: 20px;
  margin: 0 0 -3px 0;
`;

export const AppointmentSwal = ({
  title,
  text,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
  icon,
}) => {
  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#B77D38",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: icon,
    allowOutsideClick: false,
    html: (
      <Wrapper>
        <Title>{title}</Title>
        <Text>{text}</Text>

        <ButtonWrapper>
          {showCancelButton && (
            <CancelButton onClick={() => MySwal.clickCancel()}>
              {cancelButtonText}
            </CancelButton>
          )}
          <ConfirmButton onClick={() => MySwal.clickConfirm()}>
            {confirmButtonText}
          </ConfirmButton>
        </ButtonWrapper>
      </Wrapper>
    ),
  });
};

export const CalendarSwal = ({
  title,
  text,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
  icon,
  follow,
  dateTime,
  setFollow,
  doctor,
}) => {
  const Calendar = () => {
    const [availableTime, setAvailableTime] = useState([]);
    const [includeTimes, setIncludeTimes] = useState([]);
    const [currentMonth, setCurrnetMonth] = useState();
    const [currentYear, setCurrentYear] = useState();
    const [selectDate, setSelectDate] = useState(null);

    const _getConsultation = async (year, month) => {
      const apiUrl = `${process.env.REACT_APP_API}ConsultationTime/GetConsultationTime`;
      const header = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      await fetch(apiUrl, {
        method: "POST",
        body: JSON.stringify({
          DoctorId: doctor,
          Type: 2,
          Year: year,
          Month: month,
        }),
        headers: header,
      })
        .then((response) => response.json())
        .then((json) => {
          setAvailableTime(json.consultationTimes);
          handleIncludeTimes(json.consultationTimes, selectDate);
        });
    };

    useEffect(() => {
      _getConsultation(currentYear, currentMonth);
      console.log(currentYear, currentMonth);
    }, [currentMonth, currentYear]);

    const dateInputRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false);

    const handleClearDateTime = () => {
      setSelectDate(null);
      setFollow(null);
    };

    const handleCalendarClose = () => {
      setIsOpen(false);
    };
    const handleCalendarOpen = () => {
      setIsOpen(true);
    };

    const haneChangeDateTime = (date) => {
      setSelectDate(date);
      console.log(date);
      setFollow(date);
      handleIncludeTimes(availableTime, date);
    };

    const handleIncludeTimes = (timeData, date) => {
      const Time = timeData.filter((data) => data.date === dateFormat(date));

      if (Time.length) {
        setIncludeTimes(
          Time[0]?.hours.map((data) =>
            setHours(
              setMinutes(
                new Date(date),
                data.split(":")[1] === "00" ? 0 : data.split(":")[1]
              ),
              data.split(":")[0]
            )
          )
        );
      } else {
        setIncludeTimes([]);
      }
    };

    useEffect(() => {
      if (isOpen) {
        console.log("打開");
        _getConsultation(currentYear, currentMonth);

        // setCurrentYear(new Date().getFullYear());
        // setCurrnetMonth(new Date().getMonth() + 1);
      } else {
        console.log("關閉");
        if (selectDate) {
          setCurrentYear(selectDate?.getFullYear());
          setCurrnetMonth(selectDate?.getMonth() + 1);
        } else {
          setCurrentYear(new Date().getFullYear());
          setCurrnetMonth(new Date().getMonth() + 1);
        }
      }
    }, [isOpen]);

    const dateFormat = (value) => {
      const year = value?.getFullYear();
      const month = (value?.getMonth() + 1).toString().padStart(2, "0");
      const date = value?.getDate().toString().padStart(2, "0");
      return `${year}/${month}/${date}`;
    };
    const isWeekday = (date) => {
      const formattedDate = dateFormat(date);

      return (
        availableTime.map(({ date }) => date).indexOf(formattedDate) !== -1
      );
    };

    useEffect(() => {
      haneChangeDateTime(addDays(new Date(dateTime), 7));
      setCurrentYear(new Date(dateTime).getFullYear());
      setCurrnetMonth(new Date(dateTime).getMonth() + 1);
    }, [dateTime]);

    const DateInput = forwardRef(({ value, onClick }, ref) => {
      return (
        <CalendarContainer onClick={onClick} ref={ref}>
          <Content className="followUpTime">
            {value ? value : " 請選擇複診時間"}
          </Content>
          <IconContainer>
            {selectDate === null ? (
              <CiCalendar />
            ) : (
              <IoIosClose onClick={() => handleClearDateTime()} />
            )}
          </IconContainer>
        </CalendarContainer>
      );
    });

    return (
      <CalendarWrapper>
        <DatePicker
          selected={selectDate}
          onChange={(date) => haneChangeDateTime(date)}
          onMonthChange={(date) => setCurrnetMonth(date.getMonth() + 1)}
          onYearChange={(date) => setCurrentYear(date.getFullYear())}
          dateFormat="dd/MM/yyyy h:mm aa"
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          customInput={<DateInput ref={dateInputRef} isOpen={isOpen} />}
          showTimeSelect
          timeFormat="HH:mm"
          // peekNextMonth
          fixedHeight
          dropdownMode="select"
          maxDate={addDays(new Date(), 180)}
          minDate={new Date()}
          includeTimes={includeTimes}
          filterDate={isWeekday}
        />
      </CalendarWrapper>
    );
  };

  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#B77D38",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: icon,
    allowOutsideClick: false,
    preConfirm: () => {
      return new Promise((resolve) => {
        const followUpTime =
          document.getElementsByClassName("followUpTime")[0].textContent;
        resolve(followUpTime);
      });
    },
    html: (
      <Wrapper>
        <Title>{title}</Title>
        <Text>{text}</Text>

        <Calendar />
        <ButtonWrapper>
          {showCancelButton && (
            <CancelButton onClick={() => MySwal.clickCancel()}>
              {cancelButtonText}
            </CancelButton>
          )}
          <ConfirmButton onClick={() => MySwal.clickConfirm()}>
            {confirmButtonText}
          </ConfirmButton>
        </ButtonWrapper>
      </Wrapper>
    ),
  });
};

export const RemoteSwal = ({
  title,
  text,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
  icon,
}) => {
  const handleOnFocus = () => {
    Swal.resetValidationMessage();
  };

  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#B77D38",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: icon,
    allowOutsideClick: false,

    preConfirm: () => {
      const remoteStartTime = document.getElementById("remoteStartTime").value;
      const remoteEndTime = document.getElementById("remoteEndTime").value;
      const remoteEmail = document.getElementById("remoteEmail").value;

      const isValidTime = (time) => {
        const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
        return regex.test(time);
      };

      if (!remoteStartTime || !remoteEndTime || !remoteEmail) {
        Swal.showValidationMessage("欄位尚未填妥");
      } else if (!isValidTime(remoteStartTime)) {
        Swal.showValidationMessage("開始時間格式錯誤");
      } else if (!isValidTime(remoteEndTime)) {
        Swal.showValidationMessage("結束時間格式錯誤");
      } else if (!emailFormat(remoteEmail)) {
        Swal.showValidationMessage("Email格式錯誤");
      } else if (
        new Date().setHours(
          remoteStartTime.split(":")[0],
          remoteStartTime.split(":")[1]
        ) >=
        new Date().setHours(
          remoteEndTime.split(":")[0],
          remoteEndTime.split(":")[1]
        )
      ) {
        Swal.showValidationMessage("結束時間必須大於開始時間");
      } else {
        return new Promise((resolve) =>
          resolve([remoteStartTime, remoteEndTime, remoteEmail])
        );
      }
    },

    html: (
      <Wrapper>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <InputWrapper>
          <InputEl
            type="text"
            placeholder="請輸入開始時間"
            id="remoteStartTime"
            className=""
            onFocus={handleOnFocus}
          />
          <InputEl
            type="text"
            placeholder="請輸入結束時間"
            id="remoteEndTime"
            onFocus={handleOnFocus}
          />
        </InputWrapper>
        <InputWrapper>
          <InputEl
            type="email"
            placeholder="請輸入Email"
            id="remoteEmail"
            onFocus={handleOnFocus}
          />
        </InputWrapper>
        <ErrorMessage id="error">
          <ErrorIcon>
            <MdOutlineError />
          </ErrorIcon>
          <span></span>
        </ErrorMessage>
        <ButtonWrapper>
          {showCancelButton && (
            <CancelButton onClick={() => MySwal.clickCancel()}>
              {cancelButtonText}
            </CancelButton>
          )}
          <ConfirmButton onClick={() => MySwal.clickConfirm()}>
            {confirmButtonText}
          </ConfirmButton>
        </ButtonWrapper>
      </Wrapper>
    ),
  });
};

export const DefaultSwal = ({
  title,
  text,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
  icon,
}) => {
  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#B77D38",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: icon,
    allowOutsideClick: false,
    html: (
      <Wrapper>
        <Title>{title}</Title>
        <Text>{text}</Text>

        <ButtonWrapper>
          {showCancelButton && (
            <CancelButton onClick={() => MySwal.clickCancel()}>
              {cancelButtonText}
            </CancelButton>
          )}
          <ConfirmButton onClick={() => MySwal.clickConfirm()}>
            {confirmButtonText}
          </ConfirmButton>
        </ButtonWrapper>
      </Wrapper>
    ),
  });
};

export const AppointmentInfoSwal = ({
  title,
  name,
  email,
  phone,
  type,
  time,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
  icon,
}) => {
  const handleOpenQuestionnaire = () => {
    window.open(
      "https://docs.google.com/spreadsheets/d/1y5jYuT4a4UzwocnNyL3nYjq1jwW3y7PLWTjBsZn6FYg/edit?usp=sharing",
      "_blank"
    );
  };

  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#B77D38",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: icon,
    allowOutsideClick: false,
    html: (
      <Wrapper>
        <Title>{title}</Title>

        <AppointmentInfoWrapper>
          <InfoTextWrapper>
            <InfoLabel>姓名</InfoLabel>
            <InfoText>{name}</InfoText>
          </InfoTextWrapper>
          <InfoTextWrapper>
            <InfoLabel>電話</InfoLabel>
            <InfoText>{phone}</InfoText>
          </InfoTextWrapper>
          <InfoTextWrapper>
            <InfoLabel>Email</InfoLabel>
            <InfoText>{email}</InfoText>
          </InfoTextWrapper>
          <InfoTextWrapper>
            <InfoLabel>預約類型</InfoLabel>
            <InfoText>{type}</InfoText>
          </InfoTextWrapper>
          <InfoTextWrapper>
            <InfoLabel>預約時間</InfoLabel>
            <InfoText>{time}</InfoText>
          </InfoTextWrapper>
        </AppointmentInfoWrapper>

        <ButtonWrapper>
          <CancelButton onClick={handleOpenQuestionnaire}>
            查看問卷
          </CancelButton>
          <ConfirmButton onClick={() => MySwal.clickConfirm()}>
            {confirmButtonText}
          </ConfirmButton>
        </ButtonWrapper>
      </Wrapper>
    ),
  });
};

export const ShiftSwal = ({
  title,
  text,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
  icon,
  consultationTimes,
}) => {
  const handleOnFocus = () => {
    Swal.resetValidationMessage();
  };

  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#B77D38",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: icon,
    allowOutsideClick: false,

    preConfirm: () => {
      const shiftStartTime = document.getElementById("shiftStartTime").value;
      const shiftEndTime = document.getElementById("shiftEndTime").value;
      const shiftWeekday = document.getElementById("shiftWeekday").value;

      const isValidTime = (time) => {
        const regex = /^(?:[01]?[0-9]|2[0-3]):[03]0$/;
        return regex.test(time);
      };

      const isOverlaps = () => {
        const start1 = moment(shiftStartTime, "HH:mm");
        const end1 = moment(shiftEndTime, "HH:mm");
        let overlaps;
        consultationTimes
          .filter((data) => data.weekday == shiftWeekday)
          .forEach((time) => {
            const start2 = moment(time.startTime, "HH:mm");
            const end2 = moment(time.endTime, "HH:mm");
            overlaps = start1.isBefore(end2) && start2.isBefore(end1);
          });

        return overlaps;
      };

      const isTwoHoursDuration = () => {
        return (
          moment
            .duration(
              moment(shiftEndTime, "HH:mm").diff(
                moment(shiftStartTime, "HH:mm")
              )
            )
            .asHours() >= 2
        );
      };

      const isEndTimeBeforeStartTime = () => {
        const start = moment(shiftStartTime, "HH:mm");
        const end = moment(shiftEndTime, "HH:mm");
        return end.isAfter(start);
      };

      if (!shiftStartTime || !shiftEndTime || !shiftWeekday) {
        Swal.showValidationMessage("欄位尚未填妥");
      } else if (!isValidTime(shiftStartTime)) {
        Swal.showValidationMessage("開始時間格式錯誤");
      } else if (!isValidTime(shiftEndTime)) {
        Swal.showValidationMessage("結束時間格式錯誤");
      } else if (!isEndTimeBeforeStartTime()) {
        Swal.showValidationMessage("結束時間必須大於開始時間");
      } else if (!isTwoHoursDuration()) {
        Swal.showValidationMessage("營業時段最少需為2小時");
      } else if (isOverlaps()) {
        Swal.showValidationMessage("營業時段不可重疊");
      } else {
        return new Promise((resolve) =>
          resolve([shiftStartTime, shiftEndTime, shiftWeekday])
        );
      }
    },

    html: (
      <Wrapper>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <InputWrapper>
          <SelectEl id="shiftWeekday" onFocus={handleOnFocus}>
            <OptionEl value="" disabled selected>
              請選擇星期
            </OptionEl>
            <OptionEl value="1">星期一</OptionEl>
            <OptionEl value="2">星期二</OptionEl>
            <OptionEl value="3">星期三</OptionEl>
            <OptionEl value="4">星期四</OptionEl>
            <OptionEl value="5">星期五</OptionEl>
            <OptionEl value="6">星期六</OptionEl>
            <OptionEl value="7">星期日</OptionEl>
          </SelectEl>
          <InputEl
            type="text"
            placeholder="請輸入開始時間"
            id="shiftStartTime"
            onFocus={handleOnFocus}
          />
          <InputEl
            type="text"
            placeholder="請輸入結束時間"
            id="shiftEndTime"
            onFocus={handleOnFocus}
          />
        </InputWrapper>

        <ButtonWrapper>
          {showCancelButton && (
            <CancelButton onClick={() => MySwal.clickCancel()}>
              {cancelButtonText}
            </CancelButton>
          )}
          <ConfirmButton onClick={() => MySwal.clickConfirm()}>
            {confirmButtonText}
          </ConfirmButton>
        </ButtonWrapper>
      </Wrapper>
    ),
  });
};

export const ShifCancelSwal = ({
  title,
  text,
  showCancelButton,
  cancelButtonText,
  confirmButtonText,
  icon,
}) => {
  return MySwal.fire({
    showCloseButton: false,
    showConfirmButton: false,
    showCancelButton: false,
    confirmButtonColor: "#B77D38",
    background: "#fcfcfc",
    backdrop: `rgba(11,11,11,0.8)`,
    icon: icon,
    allowOutsideClick: false,
    preConfirm: () => {
      return new Promise((resolve) => {
        const clearValue = document.getElementById("clear").checked
          ? "true"
          : "false";
        resolve(clearValue);
      });
    },
    html: (
      <Wrapper>
        <Title>{title}</Title>
        <CheckBoxWrapper>
          <CheckBoxLabel>
            <CheckBoxInput id="clear" />
            更新營業時間並同步取消今天以後的預約
          </CheckBoxLabel>
        </CheckBoxWrapper>

        <ButtonWrapper>
          {showCancelButton && (
            <CancelButton onClick={() => MySwal.clickCancel()}>
              {cancelButtonText}
            </CancelButton>
          )}
          <ConfirmButton onClick={() => MySwal.clickConfirm()}>
            {confirmButtonText}
          </ConfirmButton>
        </ButtonWrapper>
      </Wrapper>
    ),
  });
};
