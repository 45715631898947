import Cookies from "js-cookie";

const TOKEN = "adxToken";

export const setAuthToken = (token, user) => {
  Cookies.set(TOKEN, token, { expires: 365 });
};

export const getAuthToken = () => {
  return {
    token: Cookies.get(TOKEN),
  };
};

export const clearAuthToken = () => {
  Cookies.remove(TOKEN);
};

export const dateTimeFormat = (value) => {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return `${month[new Date(value).getMonth()]} ${new Date(
    value
  ).getDate()} , ${new Date(value).getFullYear()}`;
};

export const appointmentDateTimeFormat = (value) => {
  const year = value.getFullYear();
  const month = (value.getMonth() + 1).toString().padStart(2, "0");
  const date = value.getDate().toString().padStart(2, "0");
  const hour = value.getHours().toString().padStart(2, "0");
  const minute = value.getMinutes().toString().padStart(2, "0");
  return `${year}-${month}-${date} ${hour}:${minute}`;
};

export const emailFormat = (value) => {
  const regex = new RegExp(
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
  );

  if (!regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const phoneFormat = (value) => {
  const regex = new RegExp(/^(5|6|9)\d{7}$/);
  if (!regex.test(value)) {
    return false;
  } else {
    return true;
  }
};

export const isSameDay = (firstValue, secondValue) => {
  return (
    firstValue.getFullYear() === secondValue.getFullYear() &&
    firstValue.getMonth() === secondValue.getMonth() &&
    firstValue.getDate() === secondValue.getDate() &&
    firstValue.getHours() === secondValue.getHours() &&
    firstValue.getMinutes() === secondValue.getMinutes()
  );
};
