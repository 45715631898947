import { styled } from "styled-components";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

const RemoteCalendar = ({
  handleReserveCalendar,
  reserveTimes,
  handleDeleteReserve,
}) => {
  const handleTimeSelect = (e) => {
    const today = eventDateFormat(new Date());
    if (new Date(e.date) >= new Date(`${today} 00:00:00`)) {
      handleReserveCalendar(e.dateStr);
    }
  };

  const handleEventClick = (e) => {
    handleDeleteReserve(e);
  };

  const eventDateFormat = (value) => {
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, "0");
    const date = value.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${date}`;
  };

  return (
    <Wrapper>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        // headerToolbar={{
        //   right: "dayGridMonth,timeGridWeek",
        //   center: "title",
        //   left: "prev,next today",

        //   // right: "dayGridMonth",
        // }}

        headerToolbar={{
          right: "prev,next today",
          left: "title",

          // right: "dayGridMonth",
        }}
        height={"100%"}
        selectable={true}
        dateClick={(e) => handleTimeSelect(e)}
        displayEventTime={true}
        displayEventEnd={true}
        eventTimeFormat={{
          hour: "2-digit",
          minute: "2-digit",
          hour12: false /* 24-hour clock */,
          meridiem: false,
        }}
        events={reserveTimes.map((data) => {
          return {
            id: data.scheduledId,
            title: data.title,
            start: data.startTime,
            end: data.endTime,
            color: "#B77D38",
          };
        })}
        selectAllow={(e) => {
          const today = eventDateFormat(new Date());
          return new Date(e.start) >= new Date(`${today} 00:00:00`);
        }}
        // showNonCurrentDates={false}
        slotMinTime="9:00:00"
        slotMaxTime="21:00:00"
        eventShortHeight="30"
        eventClick={handleEventClick}
      />
    </Wrapper>
  );
};

export default RemoteCalendar;
