import { styled, css } from "styled-components";
import { RWD_XL, RWD_LG, RWD_MD, RWD_SM } from "../../../../../utils/rwd";

import device from "../../../../../images/timewaver/product/device.svg";
import device_2 from "../../../../../images/timewaver/product/device_2.svg";

import program_1 from "../../../../../images/timewaver/product/program_1.jpg";
import program_2 from "../../../../../images/timewaver/product/program_2.jpg";
import program_3 from "../../../../../images/timewaver/product/program_3.jpg";
import program_4 from "../../../../../images/timewaver/product/program_4.jpg";
import program_5 from "../../../../../images/timewaver/product/program_5.jpg";
import program_6 from "../../../../../images/timewaver/product/program_6.jpg";
import program_7 from "../../../../../images/timewaver/product/program_7.jpg";
import program_8 from "../../../../../images/timewaver/product/program_8.jpg";
import program_9 from "../../../../../images/timewaver/product/program_9.jpg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const TitleWrapper = styled.div`
  padding-bottom: 50px;
  width: 100%;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};

  ${RWD_SM(css`
    text-align: left;
  `)};
`;
const MainImage = styled.div`
  width: 28vw;
  display: flex;
  background: ${({ theme }) => theme.colors.gray};

  img {
    display: block;
  }

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const IntroSection = styled.section`
  padding-bottom: 100px;
  display: flex;
  gap: 40px;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const IntroContent = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 28vw - 40px);

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const VideWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

const PriceWrappaer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 40px;

  ${RWD_SM(css`
    flex-direction: column;
    align-items: flex-end;
    gap: 0px;
  `)};
`;

const Price = styled.div`
  font-size: 48px;
  font-weight: 600;
`;

const PriceCurrency = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
`;

const ProductTitle = styled.div`
  font-size: clamp(24px, 2.0833vw, 36px);
  font-weight: 600;
  text-align: left;
`;

const ProductSubTitle = styled.div`
  font-size: clamp(24px, 2.0833vw, 30px);
  font-weight: 600;
  text-align: left;
`;

const PriceNotice = styled.div`
  font-size: 14px;
  line-height: normal;
  position: absolute;
  bottom: -10px;
`;

const ProductDescription = styled.div``;

const PriceBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: ${({ theme }) => theme.fonts[2]};

  justify-content: flex-end;
  position: relative;
`;

const DeviceImage = styled.div`
  width: 100%;

  img {
    display: block;
  }
`;

const ProgramSection = styled.section`
  width: 100%;
  padding-bottom: 100px;
`;

const ProgramWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  flex-direction: column;
`;

const ProgramContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;

  ${RWD_XL(css`
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  `)};

  ${RWD_LG(css`
    grid-template-columns: repeat(2, 1fr);
  `)};
`;

const ProgramBox = styled.div`
  /* background: ${({ theme }) => theme.colors.mainBrown}; */
  color: ${({ theme }) => theme.colors.mainBrown};
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
`;

const ProgramImage = styled.div`
  &:first-child {
    min-width: 180px;
  }

  img {
    display: block;
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
`;

const ProgramContent = styled.div`
  padding: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;
  font-weight: 700;
  font-size: 18px;
  position: absolute;
  bottom: 0;
  right: 0;
  /* background: ${({ theme }) => theme.colors.copyRight}; */
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  z-index: 5;
`;

const MainProgram = styled.div`
  display: flex;
  /* background: ${({ theme }) => theme.colors.gray}; */
  color: ${({ theme }) => theme.colors.mainBrown};
  position: relative;
  border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
`;

const MainProgramImage = styled.div`
  width: 100%;

  img {
    display: block;
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
`;

const MainProgramContent = styled.div`
  padding: 20px;
  font-weight: 700;
  font-size: 18px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-evenly;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 5;
  width: 100%;
  text-align: left;

  color: ${({ theme }) => theme.colors.white};

  ul {
    margin: 0;
    display: flex;
    gap: 20px;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
  }

  li {
    white-space: nowrap;
    min-width: 50px;
    /* text-align: center; */
    /* border: 1px solid ${({ theme }) => theme.colors.mainBrown}; */
  }
`;

const Shadow = styled.div`
  height: 35%;
  width: 100%;
  background-color: aliceblue;
  position: absolute;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.4));
`;

export default function Device() {
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>TimeWaver Home</Title>
        {/* <Title>微電流頻率儀</Title> */}
      </TitleWrapper>

      <IntroSection>
        <MainImage>
          <img src={device} />
        </MainImage>
        <IntroContent>
          {/* <ProductTitle>TimeWaver Home</ProductTitle> */}
          <ProductSubTitle>微電流頻率儀</ProductSubTitle>
          <ProductDescription>
            <p>
              TimeWaver Home
              是一部生物電流儀器，適合人或動物調整身體健康為細胞充電。
              針對各種身體失衡狀況啟動我們身體細胞天然修復能力。
            </p>
          </ProductDescription>

          <PriceWrappaer>
            <PriceBox>
              <PriceContainer>
                <PriceCurrency>HKD</PriceCurrency>
                <Price>38,000</Price>
              </PriceContainer>
            </PriceBox>
            <PriceBox>
              <PriceContainer>
                <PriceCurrency>HKD</PriceCurrency>
                <Price>42,800</Price>
              </PriceContainer>
              <PriceNotice>包括動物程式</PriceNotice>
            </PriceBox>
          </PriceWrappaer>
        </IntroContent>
      </IntroSection>
      <ProgramSection>
        <ProgramWrapper>
          <MainProgram>
            <MainProgramImage>
              <img src={program_1} alt="數位順勢療法" />
            </MainProgramImage>
            <MainProgramContent>
              廣泛應用程式
              <ul>
                <li>純淨</li>
                <li>關心</li>
                <li>能量</li>
                <li>平衡</li>
                <li>存在</li>
                <li>放鬆</li>
                <li>緩解</li>
              </ul>
            </MainProgramContent>
            <Shadow />
          </MainProgram>
          <ProgramContainer>
            <ProgramBox>
              <ProgramImage>
                <img src={program_2} alt="數位順勢療法" />
              </ProgramImage>
              <ProgramContent>
                數位
                <br />
                順勢療法
              </ProgramContent>
              <Shadow />
            </ProgramBox>
            <ProgramBox>
              <ProgramImage>
                <img src={program_3} alt="數位順勢療法" />
              </ProgramImage>
              <ProgramContent>
                數位
                <br />
                舒斯勒鹽
              </ProgramContent>
              <Shadow />
            </ProgramBox>
            <ProgramBox>
              <ProgramImage>
                <img src={program_4} alt="數位順勢療法" />
              </ProgramImage>
              <ProgramContent>
                數位
                <br />
                巴赫花
              </ProgramContent>
              <Shadow />
            </ProgramBox>
            <ProgramBox>
              <ProgramImage>
                <img src={program_5} alt="數位順勢療法" />
              </ProgramImage>
              <ProgramContent>美容保養</ProgramContent>
              <Shadow />
            </ProgramBox>
            <ProgramBox>
              <ProgramImage>
                <img src={program_6} alt="數位順勢療法" />
              </ProgramImage>
              <ProgramContent>健康</ProgramContent>
              <Shadow />
            </ProgramBox>
            <ProgramBox>
              <ProgramImage>
                <img src={program_7} alt="數位順勢療法" />
              </ProgramImage>
              <ProgramContent>運動</ProgramContent>
              <Shadow />
            </ProgramBox>
            <ProgramBox>
              <ProgramImage>
                <img src={program_8} alt="數位順勢療法" />
              </ProgramImage>
              <ProgramContent>動物程式</ProgramContent>
              <Shadow />
            </ProgramBox>
            <ProgramBox>
              <ProgramImage>
                <img src={program_9} alt="數位順勢療法" />
              </ProgramImage>
              <ProgramContent>強大分析模組</ProgramContent>
              <Shadow />
            </ProgramBox>
          </ProgramContainer>
        </ProgramWrapper>
      </ProgramSection>

      <DeviceImage>
        <img src={device_2} alt="TimeWaver_Home" />
      </DeviceImage>

      {/* <VideWrapper>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/e3IRFVN6vUQ?si=6xqS3LlZOE8P1U8o"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </VideWrapper> */}
    </Wrapper>
  );
}
