import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "../services/authApi";
import authReducer from "../Slices/authSlice";
import { articleApi } from "../services/articleApi";
import { activeApi } from "../services/activeApi";
import { crawlerArticleApi } from "../services/crawlerArticleApi";
import { manageApi } from "../services/manageApi";
import { telegramApi } from "../services/telegramApi";
import { consultationTimeApi } from "../services/consultationTimeApi";
import consultationReducer from "../Slices/consultationSlice";
import { remoteCalendarApi } from "../services/remoteCalendarApi";
import { reservationApi } from "../services/reservationApi";
import { doctorApi } from "../services/doctorApi";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    auth: authReducer,
    [articleApi.reducerPath]: articleApi.reducer,
    [activeApi.reducerPath]: activeApi.reducer,
    [crawlerArticleApi.reducerPath]: crawlerArticleApi.reducer,
    [manageApi.reducerPath]: manageApi.reducer,
    [telegramApi.reducerPath]: telegramApi.reducer,
    [consultationTimeApi.reducerPath]: consultationTimeApi.reducer,
    consultation: consultationReducer,
    [remoteCalendarApi.reducerPath]: remoteCalendarApi.reducer,
    [reservationApi.reducerPath]: reservationApi.reducer,
    [doctorApi.reducerPath]: doctorApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      [authApi.middleware],
      [articleApi.middleware],
      [activeApi.middleware],
      [crawlerArticleApi.middleware],
      [manageApi.middleware],
      [telegramApi.middleware],
      [consultationTimeApi.middleware],
      [remoteCalendarApi.middleware],
      [reservationApi.middleware],
      [doctorApi.middleware]
    ),
});

export default store;
