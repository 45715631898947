import { styled, css } from "styled-components";
import { useEffect, useRef, useState } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray};
  height: 100%;
  position: fixed;
  z-index: 999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  top: 0;
  left: 0;
`;

const Box = styled.div`
  width: 200px;
  height: 200px;
`;

const TextBox = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts[1]};
  color: ${({ theme }) => theme.colors.subBrown};
  overflow: hidden;
  justify-content: center;
  padding-top: 10px;
  font-size: clamp(18px, 1.25vw, 24px);
`;

const TextContainer = styled.div`
  width: auto;
  display: flex;
  gap: 5px;
`;

gsap.registerPlugin(useGSAP);

const TopAnimation = ({ startAnim }) => {
  const container = useRef();
  const { contextSafe } = useGSAP({ scope: container });
  const tl = useRef();
  const handleAnim = contextSafe(() => {
    tl.current = gsap
      .timeline()
      .fromTo(
        ".word",
        {
          y: 50,
          opacity: 0,
        },
        {
          duration: 1.5,
          opacity: 1,
          delay: 4,
          y: 0,
          stagger: 0.3,
          ease: "sine.out",
          force3D: true,
        }
      )
      .fromTo(
        ".loading",
        { opacity: 1 },
        {
          opacity: 0,
          display: "none",
          duration: 1.5,
          delay: 0.5,
          onComplete: () => {
            sessionStorage.setItem("loadingAnim", "completed");
          },
        }
      );
  });

  useEffect(() => {
    if (startAnim) {
      handleAnim();
    }
  }, [startAnim]);

  // useGSAP(
  //   () => {
  //     gsap.fromTo(
  //       ".word",
  //       {
  //         y: 50,
  //         opacity: 0,
  //       },
  //       {
  //         duration: 1.5,
  //         opacity: 1,
  //         delay: 4,
  //         y: 0,
  //         stagger: 0.3,
  //         ease: "sine.out",
  //         force3D: true,
  //       }
  //     );
  //   },
  //   { scope: container }
  // );

  // useGSAP(
  //   () => {
  //     gsap.fromTo(
  //       ".loading",
  //       { opacity: 1 },
  //       {
  //         opacity: 0,
  //         display: "none",
  //         duration: 1.5,
  //         delay: 7,
  //       }
  //     );
  //   },
  //   { scope: container }
  // );

  const slogan = "For Your Healthy Lifestyle";

  return (
    <div ref={container}>
      <Wrapper className="loading">
        <div>
          {/* <div ref={ref}>Fade In</div> */}
          {/* <Box className="box" onClick={onEnter}></Box> */}
          <svg
            width="500"
            // height="152"
            viewBox="0 0 500 152"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="svg"
          >
            <path
              d="M110.979 87.5593H67.9328C71.9103 79.2996 75.7603 71.2882 79.6777 63.1563H115.37C116.272 58.0759 117.167 53.1156 118.032 48.1402C118.858 43.3604 119.648 38.5805 120.452 33.7932C121.137 29.7198 117.806 25.5712 113.663 25.5261C107.076 25.4509 100.467 25.2555 93.8957 25.5862C87.527 25.9019 82.8429 29.2463 80.1436 35.0933C71.5044 53.7696 62.9029 72.4603 54.2859 91.1442C45.4287 110.361 36.5565 129.564 27.7594 148.803C27.0752 150.306 26.3007 150.855 24.6165 150.832C16.4886 150.727 8.36816 150.787 0.0146484 150.787C1.3079 147.961 2.51845 145.293 3.74403 142.625C19.7668 107.844 35.8122 73.0693 51.8124 38.2724C54.993 31.3581 58.2411 24.4965 62.993 18.4615C70.5042 8.93182 80.0608 2.95697 92.2489 1.62672C101.512 0.612127 110.806 0.882677 120.084 1.28852C127.107 1.59665 133.16 4.24964 137.994 9.33766C143.633 15.2674 146.092 22.5124 145.19 30.5765C144.393 37.6862 142.911 44.7208 141.716 51.7852C140.43 59.3685 139.145 66.9592 137.814 74.5348C136.49 82.0577 135.092 89.566 133.761 97.0888C132.219 105.807 130.738 114.532 129.174 123.243C129.084 123.761 128.498 124.596 128.137 124.603C120.385 124.686 112.633 124.663 104.603 124.663C106.738 112.27 108.843 100.012 110.979 87.5743V87.5593Z"
              // fill="#8B7442"
            />
            <path
              d="M141.979 124.656C149.272 83.328 156.513 42.2483 163.784 1.0255H165.588C185.461 1.0255 205.333 0.980405 225.206 1.04804C233.551 1.07811 240.912 3.69347 246.589 10.0967C250.191 14.1626 252.153 18.965 252.98 24.3311C253.868 30.0805 252.439 35.5744 251.499 41.1358C250.416 47.5239 249.281 53.9047 248.138 60.2855C246.815 67.6956 245.461 75.1061 244.123 82.5161C243.409 86.4545 242.935 90.4529 241.928 94.3157C239.07 105.311 232.401 113.51 222.739 119.305C216.581 123.002 209.897 124.716 202.664 124.686C183.107 124.588 163.55 124.656 144.001 124.656H141.971H141.979ZM158.941 100.125C158.986 100.238 159.031 100.358 159.084 100.471C159.445 100.471 159.806 100.471 160.167 100.471C175.949 100.471 191.739 100.426 207.521 100.501C212.145 100.524 217.258 96.6981 218.213 91.7455C218.815 88.6413 219.296 85.5148 219.852 82.3961C221.205 74.7677 222.596 67.1547 223.935 59.5264C225.386 51.2217 226.732 42.9019 228.236 34.6124C229.145 29.5694 225.912 25.5111 220.777 25.5111C208.927 25.5111 197.07 25.5111 185.22 25.5111H183.558C182.964 28.8029 182.386 31.9143 181.844 35.0257C180.536 42.5035 179.25 49.9818 177.927 57.4519C176.483 65.5988 174.852 73.7079 173.611 81.8848C172.972 86.1011 171.904 89.671 168.107 92.279C164.814 94.5413 161.986 97.4873 158.949 100.125H158.941Z"
              // fill="#8B7442"
            />
            <path
              d="M236.439 124.648C238.703 121.965 240.71 119.591 242.718 117.215C248.883 109.925 255.049 102.635 261.214 95.3455C266.733 88.8296 272.252 82.3133 277.763 75.7901C281.185 71.739 284.628 67.7105 287.989 63.6071C288.282 63.2464 288.244 62.3372 288.011 61.8487C282.974 51.5 277.884 41.1808 272.808 30.8547C268.109 21.2949 263.41 11.7276 258.718 2.16037C258.583 1.88981 258.485 1.59671 258.289 1.1082C258.981 1.07063 259.53 1.02555 260.079 1.02555C268.635 1.02555 277.192 1.04808 285.749 0.987953C286.951 0.980439 287.515 1.40131 288.011 2.42344C294.147 14.9894 300.32 27.5328 306.493 40.0837C306.606 40.3092 306.749 40.5271 306.967 40.9179C308.696 38.9113 310.35 37.0099 311.989 35.0934C317.29 28.8781 322.591 22.6627 327.892 16.4474C331.922 11.7276 335.96 7.00788 339.96 2.2656C340.606 1.49902 341.2 0.995484 342.321 1.003C351.088 1.05561 359.862 1.03304 368.629 1.03304H370.133C368.479 3.01713 367.058 4.74574 365.607 6.45176C360.486 12.4792 355.358 18.4916 350.245 24.5116C343.96 31.9068 337.666 39.3021 331.403 46.7197C327.057 51.868 322.734 57.0312 318.47 62.2471C318.155 62.6378 318.222 63.6298 318.485 64.1633C323.14 73.7534 327.862 83.3207 332.561 92.8953C337.509 102.989 342.441 113.09 347.381 123.191C347.584 123.604 347.779 124.025 348.05 124.588C347.373 124.618 346.862 124.663 346.358 124.663C337.749 124.663 329.14 124.641 320.531 124.693C319.35 124.693 318.794 124.31 318.275 123.266C312.282 111.098 306.237 98.9527 300.2 86.8001C299.921 86.2439 299.636 85.6881 299.222 84.8689C297.508 86.8602 295.891 88.7168 294.297 90.5957C288.245 97.7729 282.192 104.95 276.147 112.128C272.898 115.983 269.665 119.846 266.387 123.671C266.011 124.107 265.402 124.618 264.898 124.626C255.553 124.686 246.207 124.671 236.432 124.671L236.439 124.648Z"
              // fill="#8B7442"
            />
            <path
              d="M375.847 40.5948H384.103C385.366 40.5948 386.328 41.3464 386.998 42.8569C387.065 43.1352 387.103 43.3831 387.103 43.5937V110.346C387.103 110.414 387.14 110.452 387.208 110.452H398.358C398.712 110.452 399.133 110.437 399.622 110.399C400.494 110.399 401.321 110.887 402.096 111.872C402.449 112.361 402.622 112.871 402.622 113.398V121.65C402.622 122.95 401.87 123.912 400.358 124.543C400.08 124.611 399.832 124.648 399.622 124.648H396.937C391.997 124.648 385.02 124.663 376.012 124.701C374.749 124.701 373.772 124.002 373.065 122.597L372.854 121.702C372.892 113.465 372.907 106.566 372.907 100.997V44.8636C372.907 44.5106 372.892 44.0895 372.854 43.601C372.854 42.7291 373.343 41.9026 374.328 41.1285C374.817 40.7827 375.328 40.6023 375.854 40.6023L375.847 40.5948Z"
              // fill="#8B7442"
            />
            <path
              d="M412.08 40.5948H420.336C421.599 40.5948 422.561 41.3464 423.231 42.8569C423.298 43.1352 423.336 43.3831 423.336 43.5937V120.38C423.336 120.733 423.351 121.154 423.388 121.642C423.388 122.521 422.9 123.341 421.915 124.115C421.389 124.498 420.704 124.694 419.862 124.694H413.185C411.538 124.694 410.448 124.31 409.922 123.536C409.396 122.943 409.133 122.311 409.133 121.642V44.8563C409.133 44.5029 409.118 44.0822 409.08 43.5937C409.08 42.7218 409.569 41.8949 410.554 41.1208C411.043 40.7752 411.554 40.5948 412.08 40.5948Z"
              // fill="#8B7442"
            />
            <path
              d="M436.794 40.5948H460.616C461.877 40.5948 462.826 41.3464 463.456 42.8569C463.525 43.0297 463.564 43.263 463.564 43.5409V51.7929C463.564 53.0555 462.813 54.0175 461.298 54.6865C461.019 54.7538 460.775 54.7916 460.565 54.7916H448.156C448.087 54.7916 448.053 54.8293 448.053 54.8967V75.4444C448.053 75.5118 448.087 75.5495 448.156 75.5495H455.411C455.768 75.5495 456.188 75.5345 456.677 75.4968C457.548 75.4968 458.376 75.9702 459.149 76.9172C459.53 77.4434 459.728 78.1425 459.728 79.0217V85.6954C459.728 87.3114 459.359 88.3784 458.621 88.9046C458.029 89.4309 457.377 89.6938 456.677 89.6938H448.156C448.087 89.7315 448.053 89.7615 448.053 89.7988V120.387C448.053 120.74 448.066 121.161 448.104 121.65C448.104 122.529 447.615 123.348 446.628 124.122C446.105 124.505 445.418 124.701 444.577 124.701H437.901C436.254 124.701 435.164 124.318 434.636 123.543C434.113 122.95 433.847 122.319 433.847 121.65V64.6218C433.847 59.504 433.834 52.4993 433.795 43.601C433.795 42.7291 434.284 41.9026 435.271 41.1285C435.756 40.7827 436.271 40.6023 436.794 40.6023V40.5948Z"
              // fill="#8B7442"
            />
            <path
              d="M472.969 40.5948H496.791C498.052 40.5948 499 41.3464 499.631 42.8569C499.7 43.0297 499.734 43.263 499.734 43.5409V51.7929C499.734 53.0555 498.983 54.0175 497.473 54.6865C497.194 54.7538 496.945 54.7916 496.735 54.7916H484.33C484.262 54.7916 484.223 54.8293 484.223 54.8967V75.4444C484.223 75.5118 484.262 75.5495 484.33 75.5495H491.586C491.938 75.5495 492.358 75.5345 492.847 75.4968C493.723 75.4968 494.547 75.9702 495.323 76.9172C495.705 77.4434 495.902 78.1425 495.902 79.0217V85.6954C495.902 87.3114 495.533 88.3784 494.795 88.9046C494.203 89.4309 493.547 89.6938 492.847 89.6938H484.33C484.262 89.7315 484.223 89.7615 484.223 89.7988V110.346C484.223 110.414 484.262 110.452 484.33 110.452H495.482C495.834 110.452 496.254 110.437 496.743 110.399C497.614 110.399 498.443 110.887 499.219 111.872C499.571 112.361 499.743 112.871 499.743 113.398V121.65C499.743 122.95 498.992 123.912 497.481 124.543C497.203 124.611 496.954 124.648 496.743 124.648H494.057C489.119 124.648 482.142 124.663 473.136 124.701C472.042 124.701 471.132 124.137 470.399 123.018C470.188 122.672 470.043 122.214 469.978 121.65C470.013 112.962 470.03 106.078 470.03 100.997V64.6295C470.03 59.5113 470.013 52.507 469.978 43.6087C469.978 42.7368 470.463 41.9099 471.45 41.1358C471.939 40.7902 472.45 40.6098 472.977 40.6098L472.969 40.5948Z"
              // fill="#8B7442"
            />
          </svg>
          <TextBox>
            <TextContainer>
              {slogan.split(" ").map((word) =>
                word === " " ? (
                  <div className="word" key={word}>
                    &nbsp;
                  </div>
                ) : (
                  <div className="word" key={word}>
                    {word}
                  </div>
                )
              )}
            </TextContainer>
          </TextBox>
        </div>
      </Wrapper>
    </div>
  );
};

export default TopAnimation;
