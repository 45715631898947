import styled from "styled-components";
import { useState, useEffect } from "react";
import { useGetArticleListQuery } from "../../../../services/articleApi";
import { useNavigate } from "react-router-dom/dist";

const Wrapper = styled.div``;
const Container = styled.div`
  width: 100%;
`;

const ArticleWrapper = styled.div``;

const ArticleContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 20px;
`;

const ArticleContentWrapper = styled.div`
  border: 1px solid pink;
  width: 75%;
`;

const Article = styled.div`
  border: 1px solid blue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
`;

const ArticleTitle = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid yellow;
  font-size: 14px;
  color: gray;
`;

const ArticleContent = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid yellow;
`;

const ArticleSource = styled.div``;

const ArticleLabel = styled.label`
  width: 10%;
  border: 1px solid red;
`;

const ArticleText = styled.div``;

const ButtonWrapper = styled.div``;

const EditorButton = styled.button`
  /* border: 1px solid rebeccapurple; */
  padding: 5px 15px;
`;

const PublishArticle = () => {
  const navigate = useNavigate();
  const { data, isSuccess, isError, error } = useGetArticleListQuery({
    page: 1,
  });

  const [articleList, setArticleList] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      setArticleList(data.previewArticleList);
    }
  }, [data, isError]);

  console.log(data);

  const handleEdit = (id) => {
    navigate(`/edit/type=publish&articleId=${id}`);
  };

  return (
    <Wrapper>
      <Container>
        <ArticleWrapper>
          <ArticleContainer>
            {articleList.map((data) => (
              <Article>
                <ArticleContentWrapper>
                  <ArticleContent>
                    <ArticleText>{data.articleTitle}</ArticleText>
                  </ArticleContent>
                  <ArticleTitle>
                    <ArticleText>{data.partContent}</ArticleText>
                  </ArticleTitle>
                </ArticleContentWrapper>

                {/* <ArticleSource>{data.sourceName}</ArticleSource> */}
                <ButtonWrapper>
                  <EditorButton onClick={() => handleEdit(data.articleId)}>
                    編輯
                  </EditorButton>
                  <EditorButton>檢視</EditorButton>
                </ButtonWrapper>
              </Article>
            ))}
          </ArticleContainer>
        </ArticleWrapper>
      </Container>
    </Wrapper>
  );
};

export default PublishArticle;
