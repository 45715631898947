import { styled } from "styled-components";

const ButtonEl = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.subBrown};
  border-radius: 10px;
  /* padding: 0.729vw 1.041vw; */
  padding: clamp(14px, 0.729vw, 16px) clamp(23.5px, 1.041vw, 45px);
  background-color: transparent;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts[1]};
  transition: all 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.subBrown};
  }
`;

const Text = styled.div`
  font-size: 20px;
  font-size: clamp(16px, 1.041vw, 20px);
  transition: all 0.3s;
  color: ${({ theme }) => theme.colors.subBrown};

  ${ButtonEl}:hover & {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const Button = ({ text, onClickFun }) => {
  return (
    <ButtonEl onClick={onClickFun}>
      <Text>{text}</Text>
    </ButtonEl>
  );
};

export default Button;
