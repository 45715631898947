import { createApi } from "@reduxjs/toolkit/query/react";
import frontendBaseQuery from "./query/frontendBaseQuery";

export const consultationTimeApi = createApi({
  reducerPath: "consultationTimeApi",
  baseQuery: frontendBaseQuery,
  endpoints: (builder) => ({
    getConsultationTime: builder.mutation({
      query: ({ DoctorId, Type, Year, Month }) => ({
        url: `/ConsultationTime/GetConsultationTime`,
        method: "POST",
        body: JSON.stringify({
          DoctorId,
          Type,
          Year,
          Month,
        }),
      }),
      providesTags: [{ type: "consultationTime", id: "CONSULTATIONTIME" }],
    }),
    createConsultationOffTime: builder.mutation({
      query: ({ DoctorId, OffTime }) => ({
        url: `/ConsultationTime/CreateConsultationOffTime`,
        method: "POST",
        body: JSON.stringify({
          DoctorId,
          OffTime,
        }),
      }),
      invalidatesTags: [{ type: "consultationTime", id: "CONSULTATIONTIME" }],
    }),
    deleteConsultationOffTime: builder.mutation({
      query: ({ DoctorId, OffTime }) => ({
        url: `/ConsultationTime/DeleteConsultationOffTime?DoctorId=${DoctorId}&OffTime=${OffTime}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "consultationTime", id: "CONSULTATIONTIME" }],
    }),
    getConsultationOffTimeList: builder.mutation({
      query: ({ DoctorId, Year, Month }) => ({
        url: `/ConsultationTime/GetConsultationOffTimeList?DoctorId=${DoctorId}&Year=${Year}&Month=${Month}`,
        method: "GET",
      }),
      providesTags: [{ type: "consultationTime", id: "CONSULTATIONTIME" }],
    }),
  }),
});

export const {
  useGetConsultationTimeMutation,
  useCreateConsultationOffTimeMutation,
  useDeleteConsultationOffTimeMutation,
  useGetConsultationOffTimeListMutation,
} = consultationTimeApi;
