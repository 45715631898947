import { Navigate } from "react-router-dom";
import { useState } from "react";
import { getAuthToken } from "../utils";
const Protected = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(getAuthToken().token);

  if (!isLoggedIn) {
    console.log(isLoggedIn);
    return <Navigate to="/login" replace />;
  }
  return children;
};
export default Protected;
