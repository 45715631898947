import { styled, css } from "styled-components";
import { useState, useEffect, Fragment } from "react";

import { useOutletContext } from "react-router-dom";

import parse from "html-react-parser";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: clamp(300px, 64.0625vw, 1230px);

  ${RWD_LL(css`
    width: 83.22vw;
  `)};
`;

const TitleWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 80px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: left;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const TabWrapper = styled.div`
  padding-bottom: 50px;
  display: flex;
  justify-content: flex-start;

  ${RWD_MD(css`
    padding-bottom: 20px;
  `)};
`;

const TabContainer = styled.div`
  width: 100%;
`;

const Tab = styled.div`
  border-radius: 10px;
  /* width: clamp(100px, 9.375vw, 180px); */
  /* width: 180px; */
  /* margin-right: 30px; */
  color: ${({ theme }) => theme.colors.titleBrown};
  border: 1px solid ${({ theme }) => theme.colors.copyRight};
  font-weight: ${({ $activeTab }) => ($activeTab ? "bold" : "normal")};
  cursor: pointer;
  background: ${({ $activeTab, theme }) =>
    $activeTab ? theme.colors.gray : "transparent"};

  ${RWD_SM(css`
    border-radius: 4px;
    /* margin-right: 16px; */
    white-space: nowrap;
  `)};
`;

const TabText = styled.div`
  padding: 16px 30px;
  font-size: 24px;
  font-size: clamp(16px, 1.25vw, 24px);

  ${RWD_MD(css`
    padding: 10px 12px;
  `)};
`;

const FaqWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.copyRight};
  padding-bottom: 120px;
  padding-top: 50px;

  ${RWD_MD(css`
    padding-top: 20px;
  `)};
`;

const FaqCombineContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  align-items: start;

  ${RWD_MD(css`
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 16px;
  `)};
`;

const FaqContainer = styled.div`
  /* padding-top: 50px; */
  color: ${({ theme }) => theme.colors.titleBrown};
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
  align-items: start;

  ${RWD_MD(css`
    grid-gap: 16px;
  `)};
`;

const Faq = styled.div`
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  padding: 16px 30px 0px 30px;
  border: 1px solid ${({ theme }) => theme.colors.copyRight};
  background: ${({ $status, theme }) =>
    $status ? theme.colors.gray : "transparent"};
  cursor: pointer;

  ${RWD_SM(css`
    padding: 10px 10px 0 10px;
    border-radius: 4px;
  `)};
`;

const FaqContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 16px;
  font-size: clamp(16px, 1.25vw, 24px);

  ${RWD_SM(css`
    padding-bottom: 8px;
  `)};

  /* padding: 0 30px; */
`;

const FaqText = styled.div`
  text-align: left;
  width: 80%;
  transition: all 0.3s ease-in-out;
  font-weight: ${({ $status }) => ($status ? "bold" : "normal")};
  text-align: justify;
`;

const FaqButton = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.copyRight};
  position: relative;
  z-index: 1;
  margin-top: 2px;

  ${RWD_SM(css`
    width: 20px;
    height: 20px;
  `)};

  &::before {
    content: " ";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 13.2px;
    height: 1px;
    transition: 0.1s ease-in-out;
    background: ${({ theme }) => theme.colors.copyRight};
    transform: ${({ $status }) =>
      $status
        ? "translate(-50%, -50%)  rotate(90deg)"
        : " translate(-50%, -50%)  rotate(0deg)"};
    /* width: ${({ $status }) => ($status ? "0px" : "13.2px")}; */
    opacity: ${({ $status }) => ($status ? "0" : "1")};

    ${RWD_SM(css`
      width: 11px;
    `)};
  }

  &::after {
    content: " ";
    position: absolute;
    z-index: 1;
    transform: translate(-50%, -50%) rotate(90deg);
    top: 50%;
    left: 50%;
    width: 13.2px;
    height: 1px;
    transition: all 0.3s ease-in-out;
    background: ${({ theme }) => theme.colors.copyRight};
    transform: ${({ $status }) =>
      $status
        ? "translate(-50%, -50%) rotate(0deg)"
        : " translate(-50%, -50%) rotate(-90deg)"};

    ${RWD_SM(css`
      width: 11px;
    `)};
  }
`;

const AnsWrapper = styled.div`
  /* max-height: 0;
  transition: all 0.5s ease-in-out;
  max-height: ${({ $status }) => ($status ? "800px" : "0")};
  will-change: height;
  align-self: center;
  display: flex;
  align-items: flex-start; */
  width: 100%;

  display: grid;
  will-change: grid-template-rows;
  /* grid-template-rows: 0fr; */
  transition: grid-template-rows 0.5s ease-in-out;

  grid-template-rows: ${({ $status }) => ($status ? "1fr" : "0fr")};

  /* ${RWD_SM(css`
    max-height: ${({ $status }) => ($status ? "700px" : "0")};
  `)}; */
`;

const AnsContainer = styled.div`
  overflow: hidden;
`;

const Ans = styled.div`
  padding-bottom: 30px;
  text-align: justify;

  ${RWD_SM(css`
    padding-bottom: 10px;
  `)};
`;

const FaqsPage = () => {
  const windowSize = useOutletContext();
  const [swiperSpace, setSwiperSpace] = useState(50);
  const [firstLine, setFirstLine] = useState([]);
  const [secondLine, setSecondLine] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [tabList, setTabList] = useState([
    {
      id: 1,
      name: "檢測與預約",
    },

    {
      id: 3,
      name: "過敏測試",
    },
    {
      id: 4,
      name: "功能醫學",
    },
  ]);

  const [faqList, setFaqList] = useState([
    {
      id: 1,
      list: [
        {
          id: 1,
          faq: "測試會告訴關於我自己的什麼？",
          ans: "我們的測試並沒有告訴你有關你的祖先，而是關於你自己的重要信息，可以幫助你過上健康的生活方式，達到最佳的健康目標。",
          isOpen: false,
        },
        {
          id: 2,
          faq: "為什麼這個測試比市場上其他的DNA測試費用更高？",
          ans: "這個測試涵蓋了你的整個健康狀況的58個基因組。",
          isOpen: false,
        },
        {
          id: 3,
          faq: "結果如何準確可靠？",
          ans: "報告已被驗證為99.9％準確。為了準確，它測試一個人的樣本兩次。進行比較，得出結論。",
          isOpen: false,
        },
        {
          id: 4,
          faq: "誰來檢查我的結果？",
          ans: "一旦有你的測試結果，ADX營養師會提供一個免費諮詢。你也可以與你的私人教練或醫師檢查你的測試結果。",
          isOpen: false,
        },
        {
          id: 5,
          faq: "我如何收到我的結果？",
          ans: "一旦ADX收到樣品，你的最終結果將在2-3週內準備就緒。您將收到一封電子郵件提醒你已加密的文件需要密碼才能檢索報告信息。",
          isOpen: false,
        },
        {
          id: 6,
          faq: "你在抄襲其他公司的測試嗎？",
          ans: "ADX的DNA測試與現今市場上其他的基因測試是不同的。我們評估的基因遠遠多於其他公司，這些與整體健康，飲食和健身直接相關。",
          isOpen: false,
        },
        {
          id: 7,
          faq: "這個測試會告訴有關我的祖宗嗎？",
          ans: "我們的測試並沒有告訴你有關你的祖先，而是關於你自己的重要信息，可以幫助你過上健康的生活方式，達到最佳的健康目標。",
          isOpen: false,
        },
        {
          id: 8,
          faq: "誰會從這樣的基因測試中受益最大？",
          ans: "所有人。 新生兒在生命的初始階段到老年人可以從基因測試中受益。遺傳測試通常被看作是一種解脫，消除了一些關於健康的不確定性。這些結果也可能有助於醫生提出治療或監測的建議，並為人們提供更多的信息來決定他們的家屬的健康狀況。",
          isOpen: false,
        },
        {
          id: 9,
          faq: "我如何購買基因測試？",
          ans: "您可以點擊上方「點我預約」與我們聯絡",
          isOpen: false,
        },
      ],
    },
    {
      id: 2,
      list: [
        {
          id: 1,
          faq: "你在抄襲其他公司的測試嗎？",
          ans: "ADX的DNA測試與現今市場上其他的基因測試是不同的。我們評估的基因遠遠多於其他公司，這些與整體健康，飲食和健身直接相關。",
          isOpen: false,
        },
        {
          id: 2,
          faq: "這個測試會告訴有關我的祖宗嗎？",
          ans: "我們的測試並沒有告訴你有關你的祖先，而是關於你自己的重要信息，可以幫助你過上健康的生活方式，達到最佳的健康目標。",
          isOpen: false,
        },
        {
          id: 3,
          faq: "測試背後的科學是什麼？",
          ans: "所有的DNA都是由腺嘌呤 （A)，鳥嘌呤(G)，胞嘧啶（C) 和胸腺嘧啶（T）構成的。這些序列構成了一個人的基因信息。在2003年，人類基因組計劃能夠對整個人類基因組進行測序和定位。這個藍圖奠定了測試所需的基礎，然後確定與最佳健康和運動表現直接相關的基因。所有選擇納入每個報告的基因都經過科學評估委員會的高度研究和驗證，具有臨床相關性。",
          isOpen: false,
        },
        {
          id: 4,
          faq: "此為常見問題分類的其中一項問題。",
          ans: "",
          isOpen: false,
        },
        {
          id: 5,
          faq: "此為常見問題分類的其中一項問題。",
          ans: "",
          isOpen: false,
        },
        {
          id: 6,
          faq: "此為常見問題分類的其中一項問題。",
          ans: "",
          isOpen: false,
        },
        {
          id: 7,
          faq: "此為常見問題分類的其中一項問題。",
          ans: "",
          isOpen: false,
        },
        {
          id: 8,
          faq: "此為常見問題分類的其中一項問題。如果內容超出範圍太多就會往下跑。",
          ans: "",
          isOpen: false,
        },
      ],
    },
    {
      id: 3,
      list: [
        {
          id: 1,
          faq: "孕婦或小童可以進行食物過敏化驗嗎？",
          ans: "這個化驗適合所有孕婦或小童進行，而嬰兒則最理想於半歲以上才進行此化驗，因為初生嬰兒的免疫系統未完全成長及不能全面產生抗體。",
          isOpen: false,
        },
        {
          id: 2,
          faq: "測試背後的科學是什麼？",
          ans: "所有的DNA都是由腺嘌呤 （A)，鳥嘌呤(G)，胞嘧啶（C) 和胸腺嘧啶（T）構成的。這些序列構成了一個人的基因信息。在2003年，人類基因組計劃能夠對整個人類基因組進行測序和定位。這個藍圖奠定了測試所需的基礎，然後確定與最佳健康和運動表現直接相關的基因。所有選擇納入每個報告的基因都經過科學評估委員會的高度研究和驗證，具有臨床相關性。",
          isOpen: false,
        },

        {
          id: 3,
          faq: "如果報告顯示我雞蛋和牛奶等不同食物呈高度過敏，戒口會導致營養不足嗎？",
          ans: "每樣食物都有其更替品，例如對雞蛋過敏可以用不同的肉類或豆類代替；對牛奶過敏，羊奶、豆奶、米奶、芝麻等都是合適的選擇，必要時可以服用營養補充劑。如對報告內容有任何疑問，請與我們的營養師查詢。",
          isOpen: false,
        },
      ],
    },
    {
      id: 4,
      list: [
        {
          id: 1,
          faq: "ADX的DNA測試與祖先基因測試不同？",
          ans: "ADX的DNA測試側重於那些與你的飲食，營養和運動表現最相關的基因。血統檢測公司提供信息來自世界上被測試的人的家譜。",
          isOpen: true,
        },
        {
          id: 2,
          faq: "唾液，血液或口腔拭子...有什麼區別？",
          ans: "從這三種不同的收集方法獲得的遺傳信息沒有區別。口腔拭子是首選方法的原因有很多。僅舉幾例：<ul style=padding-left:20px><li>口腔拭子是有效的，而且價格便宜。口腔拭子不像常規所使用的棉籤：它們專門設計用於有效地收集和捕獲臉頰細胞在纖維內。由於每個參與者兩個棉籤都包含在試劑盒中，因此實驗室可以獲得進行測試所需的全部DNA。</li> <li>這是一個無創傷性的收集方法：不需使用針頭而且不會疼痛</li><li>與收集唾液不同，很容易從小孩子 - 甚至是新生兒身上收集好的DNA樣本！</li><li>棉籤不需要冷藏。事實上，樣本收集後，只要棉籤保存在陰涼乾燥的地方，可以保存長達六個月。</li><li>從口腔拭子中提取DNA比從血液中提取DNA更容易，更快速。</li></ul>",
          isOpen: false,
        },
        {
          id: 3,
          faq: "測試背後的科學是什麼？",
          ans: "所有的DNA都是由腺嘌呤 （A)，鳥嘌呤(G)，胞嘧啶（C) 和胸腺嘧啶（T）構成的。這些序列構成了一個人的基因信息。在2003年，人類基因組計劃能夠對整個人類基因組進行測序和定位。這個藍圖奠定了測試所需的基礎，然後確定與最佳健康和運動表現直接相關的基因。所有選擇納入每個報告的基因都經過科學評估委員會的高度研究和驗證，具有臨床相關性。",
          isOpen: false,
        },
      ],
    },
  ]);

  useEffect(() => {
    if (windowSize[0] <= "1024") {
      if (windowSize[0] <= "834") {
        setSwiperSpace(16);
      }
    } else {
      setSwiperSpace(30);
    }
  }, [windowSize]);

  const handleFaqSlide = (id, type) => {
    if (type === "first") {
      setFirstLine(
        firstLine.map((faq) => {
          if (faq.id === id) {
            return {
              ...faq,
              isOpen: !faq.isOpen,
            };
          } else {
            return {
              ...faq,
              isOpen: false,
            };
          }
        })
      );
      setSecondLine(
        secondLine.map((faq) => {
          return {
            ...faq,
            isOpen: false,
          };
        })
      );
    } else {
      setSecondLine(
        secondLine.map((faq) => {
          if (faq.id === id) {
            return {
              ...faq,
              isOpen: !faq.isOpen,
            };
          } else {
            return {
              ...faq,
              isOpen: false,
            };
          }
        })
      );
      setFirstLine(
        firstLine.map((faq) => {
          return {
            ...faq,
            isOpen: false,
          };
        })
      );
    }
  };

  const handleTab = (id) => {
    setActiveTab(id);
    setFaqList(
      faqList.map((faq) => {
        return {
          ...faq,
          list: faq.list.map((item) => {
            return {
              ...item,
              isOpen: false,
            };
          }),
        };
      })
    );
  };

  useEffect(() => {
    const Quotient = Math.floor(
      faqList.filter((item) => item.id === activeTab)[0].list.length / 2
    );

    setFirstLine(
      faqList
        .filter((item) => item.id === activeTab)[0]
        .list.slice(0, Quotient + 1)
    );

    setSecondLine(
      faqList
        .filter((item) => item.id === activeTab)[0]
        .list.slice(Quotient + 1)
    );
  }, [activeTab]);

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>常見問題</Title>
        </TitleWrapper>
        <TabWrapper>
          <TabContainer>
            <Swiper slidesPerView={"auto"} spaceBetween={swiperSpace}>
              {tabList.map((data) => (
                <SwiperSlide style={{ width: "auto" }} key={data.id}>
                  <Tab
                    $activeTab={data.id == activeTab}
                    onClick={() => handleTab(data.id)}
                  >
                    <TabText>{data.name}</TabText>
                  </Tab>
                </SwiperSlide>
              ))}
            </Swiper>
          </TabContainer>
        </TabWrapper>
        <FaqWrapper>
          <FaqCombineContainer>
            <FaqContainer>
              {firstLine.map((data) => (
                <Fragment key={data.id}>
                  <Faq
                    onClick={() => handleFaqSlide(data.id, "first")}
                    $status={data.isOpen}
                  >
                    <FaqContent>
                      <FaqText $status={data.isOpen}>{data.faq}</FaqText>
                      <FaqButton $status={data.isOpen}></FaqButton>
                    </FaqContent>
                    <AnsWrapper $status={data.isOpen}>
                      <AnsContainer>
                        <Ans>{parse(data.ans)}</Ans>
                      </AnsContainer>
                    </AnsWrapper>
                  </Faq>
                </Fragment>
              ))}
            </FaqContainer>
            <FaqContainer>
              {secondLine.map((data) => (
                <Fragment key={data.id}>
                  <Faq
                    onClick={() => handleFaqSlide(data.id, "second")}
                    $status={data.isOpen}
                  >
                    <FaqContent>
                      <FaqText $status={data.isOpen}>{data.faq}</FaqText>
                      <FaqButton $status={data.isOpen}></FaqButton>
                    </FaqContent>
                    <AnsWrapper $status={data.isOpen}>
                      <AnsContainer>
                        <Ans>{parse(data.ans)}</Ans>
                      </AnsContainer>
                    </AnsWrapper>
                  </Faq>
                </Fragment>
              ))}
            </FaqContainer>
          </FaqCombineContainer>
          {/* <FaqContainer>
            {faqList
              .filter((item) => item.id === activeTab)
              .map((data) => (
                <Fragment>
                  {data.list.map((faq) => (
                    <Faq
                      onClick={() => handleFaqSlide(faq.id)}
                      $status={faq.isOpen}
                    >
                      <FaqContent>
                        <FaqText $status={faq.isOpen}>{faq.faq}</FaqText>
                        <FaqButton $status={faq.isOpen}></FaqButton>
                      </FaqContent>

                      <AnsWrapper $status={faq.isOpen}>
                        <Ans>{faq.ans}</Ans>
                      </AnsWrapper>
                    </Faq>
                  ))}
                </Fragment>
              ))}
          </FaqContainer> */}
        </FaqWrapper>
      </Container>
    </Wrapper>
  );
};

export default FaqsPage;
