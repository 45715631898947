import { createApi } from "@reduxjs/toolkit/query/react";
import defaultBaseQuery from "./query/defaultBaseQuery";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: defaultBaseQuery,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ UserName, Password }) => ({
        url: "/Auth/Login",
        method: "POST",
        body: JSON.stringify({
          UserName,
          Password,
        }),
      }),
    }),
  }),
});

export const { useLoginMutation } = authApi;
