import { createSlice } from "@reduxjs/toolkit";
import { setAuthToken } from "../utils";

const initialState = {
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      setAuthToken(action.payload?.accessToken);
      state.token = action.payload?.accessToken;
    },
  },
});

export const { setUser } = authSlice.actions;

export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
