import { styled, css } from "styled-components";
import { RWD_MD, RWD_SM } from "../../../../../utils/rwd";

import womenImg from "../../../../../images/timewaver/product/women.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const TitleWrapper = styled.div`
  /* padding: 50px 0; */

  padding-bottom: 50px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};

  ${RWD_SM(css`
    text-align: left;
  `)};
`;

const MainImage = styled.div`
  width: 28vw;
  display: flex;
  background: ${({ theme }) => theme.colors.gray};

  img {
    width: 100%;

    display: block;
  }

  ${RWD_MD(css`
    width: 83.22vw;
  `)};
`;

const IntroSection = styled.section`
  padding-bottom: 100px;
  display: flex;
  justify-content: space-between;
  gap: 40px;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const ProgramSection = styled.section`
  width: 100%;
  /* background: ${({ theme }) => theme.colors.gray}; */
  border: 1px solid ${({ theme }) => theme.colors.copyRight};
  margin-bottom: 50px;
  padding: 30px 40px;
`;

const ProgramContent = styled.div`
  /* border: 1px solid red; */
`;

const SubTitle = styled.h4`
  font-size: clamp(18px, 1.666vw, 32px);
  font-weight: bold;
  text-align: left;
  color: ${({ theme }) => theme.colors.titleBrown};
  margin-top: 0;
  margin-bottom: 20px;
`;

const ProgramList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  list-style: square;
`;

const ProgramItem = styled.li`
  text-align: left;
  padding: 5px;
  background: ${({ theme }) => theme.colors.SubTitle};
  /* border: 1px solid ${({ theme }) => theme.colors.copyRight}; */
`;

const ProgramNotice = styled.div`
  text-align: right;
`;

const ProductTitle = styled.div`
  font-size: clamp(24px, 2.0833vw, 36px);
  font-weight: 600;
  text-align: left;
`;

const ProductSubTitle = styled.div`
  font-size: clamp(24px, 2.0833vw, 30px);
  font-weight: 600;
  text-align: left;
`;

const IntroContent = styled.div`
  width: calc(100% - 28vw - 40px);

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const PriceWrappaer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-family: ${({ theme }) => theme.fonts[2]};
  margin-top: auto;
  justify-content: flex-end;
`;

const Price = styled.div`
  font-size: 48px;
  font-weight: 600;
`;

const PriceCurrency = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

const ProductDescription = styled.div``;

export default function Women() {
  return (
    <Wrapper>
      <TitleWrapper>
        <Title>TimeWaver Home Frequency Device</Title>
        {/* <Title>Women's Edition 女性健康版本微電頻率儀</Title> */}
      </TitleWrapper>

      <IntroSection>
        <MainImage>
          <img src={womenImg} alt="timewaver_women" />
        </MainImage>
        <IntroContent>
          {/* <ProductTitle>TimeWaver Home Frequency Device</ProductTitle> */}
          <ProductSubTitle>
            Women's Edition 女性健康版本微電頻率儀
          </ProductSubTitle>
          <ProductDescription>
            <p>
              有許多研究表明氧化壓力會導致身體失衡，發現氫氣和微電流都有助於減少氧化壓力。
            </p>
            <p>
              TimeWaver Home
              中的微電流旨在提高粒線體能量水平，使我們的細胞（請參閱附圖中的細胞週期照片）能夠保持健康狀態以排毒/修復，同時支持大幅減少體內炎症出現狀況。
            </p>
            <p>
              TimeWaver Home
              中的頻率就像音叉，將我們的器官/系統/能量調整回和諧狀態。
            </p>
            <p>
              然而，還有其他因素導致身體失衡，如環境、心理、DNA
              損傷、生活方式因素，這些也在身體失衡的發展中有一定影響。
              <br />
              請注意，TimeWaver Home
              不是醫療設備，它只是一款旨在幫助我們的身體恢復平衡的健康設備。如有任何進一步問題或是否適用於某些個人情況，我們建議諮詢醫療專業人員。
            </p>
          </ProductDescription>

          <PriceWrappaer>
            <PriceCurrency>HKD</PriceCurrency>
            <Price>42,800</Price>
          </PriceWrappaer>
        </IntroContent>
      </IntroSection>
      <ProgramSection>
        <SubTitle>所有女性適用程式</SubTitle>
        <ProgramContent>
          <ProgramList>
            <ProgramItem>
              腎臟支持頻率程式 Kidney - 增強腎臟生物能量
            </ProgramItem>
            <ProgramItem>肝臟支持頻率程式 Liver - 增強肝臟生物能量</ProgramItem>
            <ProgramItem>
              循環和淋巴支持頻率程式 Circulation & Lymph -
              協助支持加強血液循環以及淋巴排毒功能
            </ProgramItem>
            <ProgramItem>
              心血管支持頻率程式 Cardiovascular - 增加心血管生物能量
            </ProgramItem>
            <ProgramItem>
              高血壓頻率程式 High Blood Pressure Support -
              協助身體增強自動調節血壓的能力
            </ProgramItem>
            <ProgramItem>
              糖尿平衡頻率程式 Diabetes Support -
              支持胰臟生物能量，啟動身體自身平衡血糖的能力（需要配合飲食、生活習慣改善以及維生素）
            </ProgramItem>
            <ProgramItem>
              肌肉消脂支援頻率程式 Muscle & Fat Burn -
              支持肌肉生物能量和脂肪燃燒
            </ProgramItem>
            <ProgramItem>
              卵巢子宮支持頻率程式 Ovary & Uterus Support -
              增強卵巢生物能量，協助保持身體平衡
            </ProgramItem>
            <ProgramItem>
              月經痛支持頻率程式 Menstruation Support -
              支持血液循環和支持腹部放鬆
            </ProgramItem>
            <ProgramItem>
              尿道炎支持頻率程式 UTI Support -
              增強膀胱、尿道生物能量減少感染發生的可能性
            </ProgramItem>
            <ProgramItem>
              精神平靜頻率程式 Mental Calm -
              協助減低精神壓力，特別適合思緒無法平靜的朋友
            </ProgramItem>
            <ProgramItem>
              炎症支持頻率程式 Inflammation -
              當細胞在沒有電的時候便會容易進入發炎狀態，這個程式可以為細胞充電協助細胞回復健康
            </ProgramItem>
          </ProgramList>
        </ProgramContent>
      </ProgramSection>

      <ProgramSection>
        <SubTitle>美容系列頻率程式</SubTitle>
        <ProgramContent>
          <ProgramList>
            <ProgramItem>
              抗衰老頻率程式 Anti Aging - 支持細胞生物能量再生
            </ProgramItem>
            <ProgramItem>
              頭髮支援頻率程式 Hair Support - 支持皮膚頭髮能量
            </ProgramItem>
            <ProgramItem>
              內在美頻率程式 Inner Beauty - 支持感受內心的快樂
            </ProgramItem>
            <ProgramItem>
              皮膚支援頻率程式 Skin Support - 支持皮膚生物能量
            </ProgramItem>
            <ProgramItem>
              皮膚 ( 脈衝 ) 頻率程式 Skin Pulse - 此程式需要與滾輪及凝膠 ( Gel )
              一起直接用於皮膚護理，滾輪有助導入頻率於皮膚之中
            </ProgramItem>
            <ProgramItem>
              皮膚護理 ( 直接 ) 頻率程式 Skincare Direct -
              此程式需要與滾輪及凝膠 ( Gel )
              一起直接用於皮膚護理，滾輪有助導入頻率於皮膚之中
            </ProgramItem>
          </ProgramList>
          <ProgramNotice>** 包括美容配件：滾輪一個 **</ProgramNotice>
        </ProgramContent>
      </ProgramSection>

      <ProgramSection>
        <SubTitle>獨家 ***NEW*** 女性適用程式</SubTitle>
        <ProgramContent>
          <ProgramList>
            <ProgramItem>
              乳房支持頻率程式 Breast Support - 增強乳房生物能量協助保持平衡
            </ProgramItem>
            <ProgramItem>
              痛風支持頻率程式 Gout Support -
              增強關節生物能量，減少酸過多引起的炎症
            </ProgramItem>
            <ProgramItem>
              細菌和真菌平衡頻率程式 Bacterial & Fungal Balance -
              支持免疫並幫助平衡細菌和真菌的影響
            </ProgramItem>
            <ProgramItem>
              過敏平衡頻率程式 Allergies Balance - 支持免疫並幫助平衡過敏
            </ProgramItem>
            <ProgramItem>
              關節炎支持頻率程式 Arthritis Support - 增強關節生物能量
              (當細胞在沒有電的時候便會容易進入發炎狀態，這個程式可以為細胞充電協助細胞回復健康)
            </ProgramItem>
            <ProgramItem>
              老年癡呆支持頻率程式 Dementia & Alzheimer's -
              增強大腦神經系統生物能量
            </ProgramItem>
            <ProgramItem>
              骨骼再生支持頻率程式 Bone Regeneration -
              協助骨骼增強能量回復健康狀態 （建議配合維他命一同使用)
            </ProgramItem>
          </ProgramList>
        </ProgramContent>
      </ProgramSection>

      <ProgramSection>
        <SubTitle>額外添加 - Daily Use Programs 日常使用程式</SubTitle>
        <ProgramContent>
          <ProgramList>
            <ProgramItem>
              Bacteria Harmony 細菌調和 - 協調防禦的生物能量場和壓抑憤怒的轉化
            </ProgramItem>
            <ProgramItem>
              Circulation 循環 - 協調血管壁的生物能量場和壓抑情緒的平衡
            </ProgramItem>
            <ProgramItem>
              Connective Tissue Harmony結締組織調和 -
              協調結締組織經絡的生物能量場並給自己空間
            </ProgramItem>
            <ProgramItem>
              Gastrointestinal Harmony 腸胃道調和 -
              協調消化和消除恐懼的生物能量場
            </ProgramItem>
            <ProgramItem>
              Head Harmony 頭部調和 - 協調緊張的生物能量場並使之平靜
            </ProgramItem>
            <ProgramItem>
              Hearing Harmony 聽力調和 - 協調耳朵的生物能量場和思想模式的釋放
            </ProgramItem>
            <ProgramItem>
              Heart Harmony 心臟調和 - 協調心臟的生物能量場和解放感受
            </ProgramItem>
            <ProgramItem>
              Joint Harmony關節平衡 - 協調關節的生物能量場和內在價值的認可
            </ProgramItem>
            <ProgramItem>
              Lung Harmony 肺部平衡 - 協調肺部的生物能量場和釋放受抑制的能量
            </ProgramItem>
            <ProgramItem>
              Memory 記憶 - 協調知識的生物能量場和對自己負責
            </ProgramItem>
            <ProgramItem>
              Mental Balance 心理平衡 - 支持積極思考和走自己的路
            </ProgramItem>
            <ProgramItem>
              Myelin Sheaths Harmony髓鞘調和 -
              協調髓鞘的生物能量場和活化內在能量
            </ProgramItem>
            <ProgramItem>
              Overwork過勞 - 協調生物能量場以恢復和對自己的善意
            </ProgramItem>
            <ProgramItem>
              Pancreas Harmony 胰腺調和 - 胰腺生物能量場的協調和自信地生活
            </ProgramItem>
            <ProgramItem>
              Performance 性能 - 生物能量場的協調和克服內心的不安全感
            </ProgramItem>
            <ProgramItem>
              Prostate Harmony前列腺調和 -
              前列腺生物能量場的協調和激活豐富的情感生活
            </ProgramItem>
            <ProgramItem>
              Sensitivity敏感 - 協調過度反應和接受自己的生物能量場
            </ProgramItem>
            <ProgramItem>
              Hypothyroid 甲狀腺功能調和1 -
              甲狀腺生物能量場的協調和生活的積極進步
            </ProgramItem>
            <ProgramItem>
              Hyperthyroid 甲狀腺功能調和2 -
              甲狀腺生物能量場的協調和自身的安全感
            </ProgramItem>
            <ProgramItem>
              Tonsils Harmony扁桃腺調和 - 協調扁桃腺體的生物能量場並保持其性質
            </ProgramItem>
          </ProgramList>
        </ProgramContent>
      </ProgramSection>
    </Wrapper>
  );
}
