import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";

import Member_1 from "../../../../images/team/victor.jpg";
import Member_2 from "../../../../images/team/canny.jpg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: clamp(300px, 64.0625vw, 1230px);
  padding-bottom: 120px;

  ${RWD_LL(css`
    width: 83.22vw;
  `)};
`;

const TitleWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 80px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: left;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
`;

const Member = styled.div`
  display: flex;
  gap: 5%;

  ${RWD_LG(css`
    flex-direction: column;
  `)};
`;

const MemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  ${RWD_LG(css`
    width: 100%;
    padding-top: 20px;
  `)};
`;

const MemberImage = styled.div`
  width: 50%;

  ${RWD_LG(css`
    width: 100%;
  `)};
`;

const MemberInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
`;

const MemberName = styled.div`
  font-size: 30px;
  font-weight: 600;
`;

const MemberTitle = styled.div`
  color: ${({ theme }) => theme.colors.mainBrown};
`;

const MemberProfileLink = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  &::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #787878;
  }
`;

const MemberProfile = styled.ul`
  padding-left: 20px;
`;

const MemberProfileItem = styled.li`
  text-align: left;
  padding: 5px 0;
`;

const TeamPage = () => {
  const memberData = [
    {
      id: 1,
      name: "Victor Chan",
      title: "創辦人及首席營養師",
      img: Member_1,
      profile: [
        "Massey University Engineering & Automation Technologist (自動機械工程學士)",
        "香港理工大學工程商務管理研究生",
        "美國Western State大學功能醫學碩士 (在讀)",
        "成功創立及營運半導體設備零件及人工智能財富管理公司",
        "過去13年專研綜合中西醫學、營養學、心理學、肌肉筋腱、經絡氣血、脊椎關節各範疇的理論，創造一個可以個性化提升及優化每人身體的健康計劃。",
        "香港及英國食物安全Officer",
        "美國國家功能醫學治療師",
        "香港首間功能醫學中心創辦人",
        "香港功能醫學協會 (AFAMA) 創辦人",
        "美國認證NLP執行師及教練",
        "美國認證時間線心理療法治療師",
        "中國國家註冊二級公共營養師",
        "YouTube最早一批廣東話頻道主講功能醫學，單一視頻近萬播放量",
      ],
    },
    {
      id: 2,
      name: "Canny Ma ",
      title: "共同創辦人及首席評估官",
      img: Member_2,
      profile: [
        "功能醫學營養師",
        "香港首間功能醫學中心創聯合創辦人",
        "美國Western State大學功能醫學碩士 (在讀)",
        "成功創立及營運半導體設備零件及人工智能財富管理公司",
        "過去13年專研綜合中西醫學、營養學、心理學、肌肉筋腱、經絡氣血、脊椎關節各範疇的理論，創造一個可以個性化提升及優化每人身體的健康計劃。",
        "香港及英國食物安全Officer",
        "美國國家功能醫學治療師",
        "香港首間功能醫學中心創辦人",
        "香港功能醫學協會 (AFAMA) 創辦人",
        "美國認證NLP執行師及教練",
        "美國認證時間線心理療法治療師",
      ],
    },
  ];

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>ADX團隊</Title>
        </TitleWrapper>
        <MemberWrapper>
          {memberData.map((data) => (
            <Member key={data.id}>
              <MemberImage>
                <img src={data.img} alt="" />
              </MemberImage>
              <MemberContainer>
                <MemberInfo>
                  <MemberTitle>{data.title}</MemberTitle>
                  <MemberName>{data.name}</MemberName>
                </MemberInfo>
                <MemberProfileLink>
                  <span>PROFILE</span>
                </MemberProfileLink>
                <MemberProfile>
                  {data.profile.map((item, index) => (
                    <MemberProfileItem key={index}>{item}</MemberProfileItem>
                  ))}
                </MemberProfile>
              </MemberContainer>
            </Member>
          ))}
        </MemberWrapper>
      </Container>
    </Wrapper>
  );
};

export default TeamPage;
