import { styled, css, keyframes } from "styled-components";
import { useState, useEffect, useRef, useLayoutEffect, Fragment } from "react";
import { useOutletContext } from "react-router-dom";
import BannerImg from "../../../images/banner/banner.png";
import WaveImg from "../../../images/banner/wave.png";

import BannerImg_Mob from "../../../images/banner/banner_mobile.png";

import BannerImg_Mob1 from "../../../images/banner/banner_mob_1.png";
import BannerImg_Mob2 from "../../../images/banner/banner_mob_2.png";
import BannerImg_Mob3 from "../../../images/banner/banner_mob_3.png";

import Button from "../../../global/Button";

import Banner1 from "../../../images/banner/banner_1.png";
import Banner2 from "../../../images/banner/banner_2.png";
import Banner3 from "../../../images/banner/banner_3.png";

import EventImg1 from "../../../images/event/event_1.jpg";
import EventImg2 from "../../../images/event/event_2.jpg";
import EventImg3 from "../../../images/event/event_3.jpg";

import LeftBanner from "../../../images/banner/left.png";
import MiddleBanner from "../../../images/banner/middle.png";
import RightBanner from "../../../images/banner/right.png";

import CoverImg from "../../../images/banner/cover.png";

import {
  RWD_XL,
  RWD_LG,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import {
  useTransition,
  animated,
  useSpringRef,
  useTrail,
  useChain,
  useSpring,
  useSprings,
} from "@react-spring/web";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";

import "../../../index.css";

const Wrapper = styled.div``;

const Container = styled.div`
  /* background: #f8f6f2; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url(${BannerImg}); */
`;

const BannerWrapper = styled.div`
  /* background: ${({ theme }) => theme.colors.gray}; */
  /* background-image: url(${BannerImg}); */
  /* background-color: teal; */
  width: 100%;

  /* height: 90.74vh; */
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);

  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;

  ${RWD_SM(css`
    height: 87.5vh;
    height: calc(var(--vh, 1vh) * 87.5);
    display: flex;
    align-items: flex-end;
    justify-content: center;
  `)};
`;

const slide = keyframes`
  10% {
    opacity: 1
  }

  20% {
    opacity: 1
  }
    
  30% {
    opacity: 0
  }
    
  40% {
    transform: scale(1.1)
  }
   
`;

const fontAni = keyframes`
  10% {
    opacity: 1
  }

  20% {
    opacity: 1
  }
    
  30% {
    opacity: 0
  }
    
  40% {
     transform: scale(1)
  }
   
`;

const BannerImgItem = styled.div`
  width: 100%;
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  background-size: cover;
  background-position: center center;
  position: absolute;
  /* animation: ${slide} 25s infinite; */
  background-image: url(${({ $bannerImg }) => $bannerImg});
  left: 0;
  bottom: 0;
  overflow: hidden;

  /* &:nth-child(2) {
    animation-delay: 5s;
  }

  &:nth-child(3) {
    animation-delay: 10s;
  }

  &:nth-child(4) {
    animation-delay: 15s;
  }

  &:nth-child(5) {
    animation-delay: 20s;
  } */

  img {
    object-fit: cover;
    height: 100%;
    /* filter: saturate(85%); */
  }

  ${RWD_SM(css`
    height: 87.5vh;
    height: calc(var(--vh, 1vh) * 87.5);
    display: flex;
    align-items: flex-end;
    justify-content: center;
  `)};
`;

const WaveWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  transition: all 1.5s ease-in-out;
  height: 40vh;

  /* ${BannerWrapper}:hover & {
    opacity: 0;
  } */

  ${RWD_SM(css`
    /* position: static; */
    position: absolute;
    display: flex;
    flex-direction: column;

    /* img {
      display: none;
      height: auto;
    } */
  `)};
`;

const W = styled.div`
  height: 25vh;
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
  transform: scaleX(-1);
`;

const WW = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='341' viewBox='0 0 1920 341' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 341L1920 155.674C1920 155.674 1757 0.000168856 1299 0.000128816C818.296 8.67914e-05 630 155.674 1.62017e-05 155.674L0 341L1920 341Z' fill='%23EEEEEC' fill-opacity='0.8'/%3E%3C/svg%3E%0A");
  position: absolute;
  width: 200%;
  height: 100%;
  /* animation: wave 10s -3s linear infinite; */
  transform: translate3d(0, 0, 0);
  opacity: 1;
`;

const SloganWrapper = styled.div`
  position: absolute;
  bottom: 10%;
  left: 6vw;
  color: white;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts[2]};
  z-index: 9;
  overflow: hidden;

  display: flex;
  align-items: center;
  width: 100%;
  /* animation: ${fontAni} 25s infinite; */
  background-image: url(${({ $bannerImg }) => $bannerImg});

  opacity: 1;
  &:nth-child(2) {
    animation-delay: 5s;
  }

  &:nth-child(3) {
    animation-delay: 10s;
  }

  &:nth-child(4) {
    animation-delay: 15s;
  }

  &:nth-child(5) {
    animation-delay: 20s;
  }

  ${RWD_SM(css`
    /* position: static;
    transform: translateY(0%); */
    color: ${({ theme }) => theme.colors.white};
    /* width: 55.55vw; */
    padding: 0 30px;
    left: 0;
    bottom: 5%;
  `)};
`;

const SloganMain = styled.div`
  font-weight: bolder;
  font-size: clamp(40px, 4.166vw, 70px);
  padding-right: 20px;
  /* color: ${({ theme }) => theme.colors.titleBrown}; */
`;

const SloganSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 2px solid ${({ theme }) => theme.colors.white};
  padding-left: 20px;
`;

const SloganTitle = styled.div`
  font-weight: bold;
  font-size: clamp(24px, 2.0833vw, 40px);
  /* color: ${({ theme }) => theme.colors.mainBrown}; */
`;

const SloganContent = styled.div`
  /* color: ${({ theme }) => theme.colors.mainBrown}; */
  font-size: clamp(18px, 1.25vw, 24px);
`;

const Cover = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  clip-path: polygon(35% 0%, 100% 0%, 100% 100%, 15% 100%);

  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: ${({ theme }) => theme.fonts[1]};
  opacity: 1;
  top: 0;

  /* transform: translateX(100%);
  transition: all 1.5s ease-in-out; */

  /* ${BannerWrapper}:hover & {
    transform: translateX(0%);
    opacity: 1;
  } */

  /* ${RWD_SM(css`
    display: none;
  `)}; */

  img {
    width: 100%;
    height: calc(100vh - 9.25vh);
    height: calc(var(--vh, 1vh) * 90.75);
    position: absolute;
    right: 0;
    z-index: 1;
    object-fit: cover;
  }
`;

const CoverContentWrapper = styled.div`
  width: 47.86vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-right: 13.52vw;
  position: relative;
  z-index: 2;

  ${RWD_SM(css`
    width: 58vw;
    margin-right: 6vw;
  `)};
`;

const CoverTitle = styled.div`
  color: ${({ theme }) => theme.colors.titleBrown};
  font-size: 2.5vw;
  padding-bottom: 10px;
  font-weight: bold;
  text-align: left;
  font-size: clamp(38px, 2.5vw, 48px);
  opacity: 1;
  transition: all 1.5s linear;

  ${RWD_SM(css`
    font-size: 24px;
  `)};

  /* ${BannerWrapper}:hover & {
    opacity: 1;
    transition-delay: 0.3s;
  } */
`;

const CoverContent = styled.div`
  text-align: left;

  // 32px
  color: ${({ theme }) => theme.colors.mainBrown};
  font-size: 1.666vw;
  padding-bottom: 70px;
  font-weight: 500;
  font-size: clamp(22px, 1.666vw, 32px);
  opacity: 1;
  transition: all 1s ease-in-out;

  ${RWD_SM(css`
    font-size: 16px;
  `)};

  /* ${BannerWrapper}:hover & {
    opacity: 1;
    transition-delay: 0.5s;
  } */
`;

const CoverButtonWrapper = styled.div`
  opacity: 1;
  transition: all 1s ease-in-out;
  /* ${BannerWrapper}:hover & {
    opacity: 1;
    transition-delay: 0.7s;
  } */
`;

const ParallaxWrapperFirst = styled.div`
  /* z-index: 1; */
  position: absolute;
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  display: flex;
  width: 100%;

  img {
    height: calc(100vh - 9.25vh);
    height: calc(var(--vh, 1vh) * 90.75);
    object-fit: cover;
    object-position: center;
  }

  ${RWD_LG(css`
    img {
      object-position: top;
    }
  `)};

  /* clip-path: polygon(36% 0%, 100% 0%, 100% 100%, 15% 100%); */

  clip-path: polygon(0 0, 35% 0, 15% 100%, 0 100%);
  /* background-color: yellow; */
  /* background-image: url(${EventImg1}); */
  /* background-size: contain; */
  background-position: center center;
`;

const ParallaxWrapperSecond = styled.div`
  /* z-index: 2; */
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  width: 100%;
  position: absolute;

  img {
    height: calc(100vh - 9.25vh);
    height: calc(var(--vh, 1vh) * 90.75);
    object-fit: cover;
    object-position: center;
  }

  /* background-image: url(${BannerImg});
  background-size: cover;
  background-position: center center; */
  clip-path: polygon(35% 0, 82% 0, 61% 100%, 15% 100%);
  background-color: aqua;
`;

const ParallaxWrapperThird = styled.div`
  /* z-index: 3; */
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  position: absolute;
  width: 100%;
  right: 0;
  /* border: 1px solid yellow; */

  img {
    height: calc(100vh - 9.25vh);
    height: calc(var(--vh, 1vh) * 90.75);
    object-fit: cover;
    object-position: center;
  }

  ${RWD_LG(css`
    img {
      object-position: bottom;
    }
  `)};
  /* background-image: url(${BannerImg});
  background-size: cover;
  background-position: center center; */
  clip-path: polygon(81% 0, 100% 0, 100% 100%, 61% 100%);
  background-color: pink;
`;

const ParallaxWrapper = styled.div`
  /* z-index: 3; */
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  position: absolute;
  width: 100%;
  right: 0;
  background-position: center center;
  overflow: hidden;

  ${RWD_SM(css`
    height: 87.5vh;
    height: calc(var(--vh, 1vh) * 87.5);
  `)};

  /* border: 1px solid yellow; */

  /* ${RWD_LG(css`
    img {
      object-position: bottom;
    }
  `)}; */
  /* background-image: url(${BannerImg});
  background-size: cover;
  background-position: center center; */
  /* clip-path: polygon(81% 0, 100% 0, 100% 100%, 61% 100%);
  background-color: pink; */
`;

const handleAnimationType = (active, state, index) => {
  switch (state) {
    case false:
      if (index === 0) {
        return "translate3d(-35%, 0, 0)";
      } else if (index === 2) {
        return "translate3d(35%, 0, 0)";
      }

    default:
      return "translate3d(0%, 0, 0)";
  }
};

const ParallaxImg = styled.img`
  height: calc(100vh - 9.25vh);
  height: calc(var(--vh, 1vh) * 90.75);
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  transition: all 1s linear;
  transform: ${({ $active, $state, $index }) =>
    handleAnimationType($active, $state, $index)};

  /* &:hover {
    transform: scale(1.1);
  } */

  ${RWD_SM(css`
    height: 87.5vh;
    height: calc(var(--vh, 1vh) * 87.5);
  `)};
`;

const Mask = styled.div`
  height: 40%;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.6));
`;

const TestBanner = () => {
  const startAnim = useOutletContext();

  console.log(startAnim);

  const imgList = [
    {
      id: 1,
      img: LeftBanner,
      main: "身",
      title: "檢測與調整",
      content: "全面檢測身體健康，提供個性化調整方案，重建身體平衡。",
    },
    {
      id: 2,
      img: MiddleBanner,
      main: "心",
      title: "訓練與強化",
      content: "透過專業訓練，提高心靈韌性，強化個人意志力，增強心理素質。",
    },
    {
      id: 3,
      img: RightBanner,
      main: "靈",
      title: "穩定與和諧",
      content: "結合禪坐與脈輪平衡技巧，引導內在和諧，促進靈性穩定。",
    },
    // {
    //   id: 4,
    //   img: LeftBanner,
    //   title: "身：檢測與調整",
    //   content: "全面檢測身體健康，提供個性化調整方案，重建身體平衡。",
    // },
    // {
    //   id: 5,
    //   img: MiddleBanner,
    //   title: "心：訓練與強化",
    //   content: "透過專業訓練，提高心靈韌性，強化個人意志力，增強心理素質。",
    // },
  ];

  //   const [activeIndex, setActiveIndex] = useState(0);
  //   const springApi = useSpringRef();

  //   const transitions = useTransition(activeIndex, {
  //     from: {
  //       clipPath: "polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%)",
  //     },
  //     enter: {
  //       clipPath: "polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%)",
  //     },
  //     leave: {
  //       clipPath: "polygon(100% 0%, 100% 100%, 100% 100%, 100% 0%)",
  //     },
  //     onRest: (_springs, _ctrl, item) => {
  //       if (activeIndex === item) {
  //         setActiveIndex(activeIndex === IMAGES.length - 1 ? 0 : activeIndex + 1);
  //       }
  //     },
  //     exitBeforeEnter: true,
  //     config: {
  //       duration: 4000,
  //     },
  //     delay: 1000,
  //     ref: springApi,
  //   });

  //   const springs = useSpring({
  //     from: {
  //       strokeDashoffset: 120,
  //     },
  //     to: {
  //       strokeDashoffset: 0,
  //     },
  //     config: {
  //       duration: 11000,
  //     },
  //     loop: true,
  //     ref: springApi,
  //   });

  //   useLayoutEffect(() => {
  //     springApi.start();
  //   }, [activeIndex]);

  const container = useRef();

  // const tl = gsap.timeline({
  //   // repeat: -1,
  //   onComplete: () => {
  //     setCurrentIndex(currentIndex + 1);
  //   },
  // });
  // useGSAP(
  //   () => {
  //     tl.from(".banner", {
  //       right: "-100%",
  //       duration: 1,
  //       stagger: 3,
  //       ease: "circ.inOut",
  //       autoAlpha: 1,
  //     });
  //   },
  //   { dependencies: [currentIndex] }
  // );

  const [currentIndex, setCurrentIndex] = useState(0);

  const { contextSafe } = useGSAP({ scope: container });
  const tl = useRef();

  const createBanners = contextSafe(() => {
    tl.current = gsap
      .timeline()
      .fromTo(
        ".banner_0",
        { scale: 1.1, ease: "none", zIndex: 1 },
        {
          duration: 5,
          scale: 1,
          ease: "none",
          zIndex: -1,
        }
      )
      .fromTo(
        ".banner_1",
        { scale: 1.1, ease: "none", zIndex: 1 },
        {
          duration: 5,
          scale: 1,
          ease: "none",
        }
      );
    // .fromTo(
    //   ".bg_0",
    //   {
    //     y: 50,
    //     opacity: 0,
    //     duration: 2,
    //     stagger: 0.3,
    //     ease: "power2.inOut",
    //   },
    //   {
    //     y: 0,
    //     opacity: 1,
    //     duration: 2,
    //     stagger: 0.3,
    //     ease: "power2.inOut",
    //   }
    // );
  });

  const createBanner = contextSafe(() => {
    tl.current = gsap
      .timeline()
      .fromTo(
        `.banner_${currentIndex}`,
        { opacity: 1, scale: 1.1, ease: "none" },
        {
          duration: 5,
          opacity: 1,
          scale: 1,
          ease: "none",
          onComplete: () => {
            if (currentIndex == imgList.length - 1) {
              setCurrentIndex(0);
            } else {
              setCurrentIndex(currentIndex + 1);
            }
          },
        }
      )
      .to(
        `.banner_${currentIndex == 0 ? 2 : currentIndex - 1}`,
        {
          opacity: 0,
        },
        "<"
      )

      .fromTo(
        `.bg_${currentIndex}`,
        {
          y: 50,
          opacity: 0,
          duration: 2,
          stagger: 0.3,
          ease: "power2.inOut",
        },
        {
          y: 0,
          opacity: 1,
          duration: 2,
          stagger: 0.3,
          ease: "power2.inOut",
        }
      )
      .to(
        `.bg_${currentIndex == 0 ? 2 : currentIndex - 1}`,
        {
          opacity: 0,
        },
        "<"
      );
  });

  useEffect(() => {
    console.log(currentIndex);
    createBanner();
  }, [currentIndex]);

  // useEffect(() => {
  //   createBanners();
  // }, []);

  return (
    <Wrapper>
      <Container>
        <BannerWrapper ref={container}>
          {imgList.map((data, index) => (
            <BannerImgItem>
              <img className={`banner_${index}`} src={data.img} />

              <SloganWrapper>
                <SloganMain className={`bg_${index}`}>{data.main}</SloganMain>
                <SloganSubWrapper>
                  <SloganTitle className={`bg_${index}`}>
                    {data.title}
                  </SloganTitle>
                  <SloganContent className={`bg_${index}`}>
                    {data.content}
                  </SloganContent>
                </SloganSubWrapper>
              </SloganWrapper>
            </BannerImgItem>
          ))}

          {/* {transitions((springs, item) => (
            <animated.div style={springs}>
              <img src={IMAGES[item]} />
            </animated.div>
          ))} */}
          {/* <W>
            <WW></WW>
          </W> */}
          <Mask></Mask>
        </BannerWrapper>
      </Container>
    </Wrapper>
  );
};

export default TestBanner;
