import styled, { css } from "styled-components";

import { useState, useEffect } from "react";
import { useGetCrawlerArticleListQuery } from "../../../services/crawlerArticleApi";
import { useNavigate } from "react-router-dom/dist";

import CrawlerArticle from "./Tab/CrawlerArticle";
import PublishArticle from "./Tab/PublishArticle";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 80%;
`;

const ArticleWrapper = styled.div``;

const ArticleContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 1fr);
  grid-gap: 20px;
`;

const ArticleContentWrapper = styled.div`
  border: 1px solid pink;
  width: 75%;
`;

const Article = styled.div`
  border: 1px solid blue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
`;

const ArticleTitle = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid yellow;
  font-size: 14px;
  color: gray;
`;

const ArticleContent = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid yellow;
`;

const ArticleSource = styled.div``;

const ArticleLabel = styled.label`
  width: 10%;
  border: 1px solid red;
`;

const ArticleText = styled.div``;

const ButtonWrapper = styled.div``;

const EditorButton = styled.button`
  /* border: 1px solid rebeccapurple; */
  padding: 5px 15px;
`;

const TabWrapper = styled.div``;

const TabContainer = styled.div`
  display: flex;
`;

const Tab = styled.div`
  border: 1px solid blue;
`;

const TabContent = styled.div`
  padding: 10px 5px;
`;

const ArticlePage = () => {
  const [tabList, setTabList] = useState([
    { id: 1, name: "Crawler" },
    { id: 2, name: "Publish" },
  ]);

  const [activeTab, setActiveTab] = useState(2);

  const navigate = useNavigate();
  const [articleList, setArticleList] = useState([]);

  const { data, isSuccess, isError, error } = useGetCrawlerArticleListQuery({
    page: 1,
  });

  useEffect(() => {
    if (isSuccess) {
      setArticleList(data.previewCrawlerArticleList);
    }
  }, [data, isError]);

  const handleEdit = (id) => {
    navigate(`/edit/${id}`);
  };

  const handleTab = (id) => {
    setActiveTab(id);
  };

  return (
    <Wrapper>
      <Container>
        <TabWrapper>
          <TabContainer>
            {tabList.map((tab) => (
              <Tab key={tab.id} onClick={() => handleTab(data.id)}>
                <TabContent>{tab.name}</TabContent>
              </Tab>
            ))}
          </TabContainer>
        </TabWrapper>

        {activeTab === 1 && <CrawlerArticle />}

        {activeTab === 2 && <PublishArticle />}

        {/* <ArticleWrapper>
          <ArticleContainer>
            {articleList.map((data) => (
              <Article>
                <ArticleContentWrapper>
                  <ArticleContent>
                    <ArticleText>{data.crawlerArticleContent}</ArticleText>
                  </ArticleContent>
                  <ArticleTitle>
                    <ArticleText>{data.crawlerArticleTitle}</ArticleText>
                  </ArticleTitle>
                </ArticleContentWrapper>

                <ArticleSource>{data.sourceName}</ArticleSource>
                <ButtonWrapper>
                  <EditorButton
                    onClick={() => handleEdit(data.crawlerArticleId)}
                  >
                    編輯
                  </EditorButton>
                  <EditorButton>檢視</EditorButton>
                </ButtonWrapper>
              </Article>
            ))}
          </ArticleContainer>
        </ArticleWrapper> */}
      </Container>
    </Wrapper>
  );
};

export default ArticlePage;
