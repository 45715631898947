import { styled, css } from "styled-components";
import { Fragment } from "react";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../../utils/rwd";

import Banner from "../../../../images/companyProfile/banner.jpg";
import ADX from "../../../../images/companyProfile/adx.jpg";
import FounderImg from "../../../../images/companyProfile/founder.jpg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: clamp(300px, 64.0625vw, 1230px);

  ${RWD_LL(css`
    width: 83.22vw;
  `)};
`;

const TitleWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 80px;

  ${RWD_MD(css`
    padding-top: 20px;
    padding-bottom: 20px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.0833vw, 40px);
  text-align: left;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const TopImgWrapper = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  /* border: 1px solid ${({ theme }) => theme.colors.copyRight}; */
  display: inline-flex;
  /* background: rgba(187, 175, 149, 0.1); */

  img {
    object-fit: cover;

    /* object-position: center; */
  }

  ${RWD_SM(css`
    height: 51.667vw;

    img {
      height: 51.667vw;
      object-fit: cover;
    }
  `)};
`;

const SubTitle = styled.h3`
  font-size: clamp(20px, 1.875vw, 36px);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
  text-align: left;
`;

const InnerTitle = styled.h4`
  font-size: clamp(18px, 1.666vw, 32px);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.mainBrown};
  text-align: left;
`;

const ContentWrapper = styled.div``;

const CompanyFeatuerWrapper = styled.div`
  margin-bottom: 100px;
`;

const AdxWrapper = styled.div`
  margin-bottom: 100px;
`;

const ConceptWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 50px 0;

  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
  `)};
`;

const ConcepContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(187, 175, 149, 0.1);
  padding: 20px;
  border-radius: 10px;
`;

const ConceptItem = styled.div`
  font-size: clamp(17px, 0.9375, 18px);
  color: ${({ theme }) => theme.colors.mainBrown};
`;

const ConceptTitle = styled.div`
  font-size: clamp(18px, 1.666vw, 32px);
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.titleBrown};
  font-weight: 600;
`;

const Concept = styled.div`
  margin-bottom: 20px;

  ${RWD_SM(css`
    margin-bottom: 10px;
  `)};
`;

const ContentContent = styled.div`
  text-align: justify;
`;

const FounderWrapper = styled.div`
  padding-bottom: 120px;
`;

const FounderContainer = styled.div`
  display: flex;
  gap: 40px;

  ${RWD_SM(css`
    flex-direction: column;
  `)};
`;

const Founder = styled.div`
  width: 40%;

  ${RWD_SM(css`
    width: 100%;
  `)};
`;

const FonderInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 25px;
  font-weight: 500;
  margin-top: auto;
  margin-left: auto;

  :first-child {
    font-size: 15px;
    font-weight: normal;
  }
`;

const FounderImage = styled.div``;

const FonderContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const FounderSlogan = styled.div`
  font-size: clamp(20px, 1.45833vw, 28px);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.mainBrown};
  text-align: left;
`;

const CompanyProfilePage = () => {
  const companyFeatuer = [
    {
      id: 1,
      title: "使命",
      content: [
        "ADX的使命是把預防醫學和恢復終身健康和生活方案帶給忙碌、壓力繁重的現代人。",
        "我們相信只要提供身體所需的必需品，從內而外用天然無添加的飲食和減低日常接觸化學品和毒素便能擁有健康，更鼓勵我們每個人對自己的健康和幸福負責。",
      ],
    },
    {
      id: 2,
      title: "遠景",
      content: [
        "ADX 相信我們每天透過選擇合適的食物和補充品，參與鍛煉和休息，並納入健康的生活習慣和平衡我們所做的一切，就是我們最好的創造最佳的生活方式。",
        "透過學習和輔助，每個人都可以過上充實的生活。",
      ],
    },
  ];

  return (
    <Wrapper>
      <Container>
        <TitleWrapper>
          <Title>公司背景</Title>
        </TitleWrapper>
        <TopImgWrapper>
          <img src={Banner} />
        </TopImgWrapper>
        <CompanyFeatuerWrapper>
          {companyFeatuer.map((data) => (
            <Fragment key={data.id}>
              <InnerTitle>{data.title}</InnerTitle>
              <ContentWrapper>
                {data.content.map((item) => (
                  <p>{item}</p>
                ))}
              </ContentWrapper>
            </Fragment>
          ))}
        </CompanyFeatuerWrapper>

        <AdxWrapper>
          <SubTitle>關於ADX</SubTitle>
          <img src={ADX} />
          <ContentWrapper>
            <p>
              ADX時代健康創富有限公司致力於提供個性化、一站式的優質生活管理服務，其命名源自於以下三個英文單詞：
            </p>
          </ContentWrapper>
          <ConceptWrapper>
            <ConcepContainer>
              <Concept>
                <ConceptTitle>領先</ConceptTitle>
                <ConceptItem>前端科技 / 數據管理 / 定制個人程式</ConceptItem>
              </Concept>

              <ContentContent>
                ADX
                運用先進前端的科技，從多角度收集數據包括身體各部份的檢查診斷、營養學、環境基因學、運動學等，再綜合分析並管理，從而針對個人的生活式去度身定制出一套有效的健康管理方案。
              </ContentContent>
            </ConcepContainer>

            <ConcepContainer>
              <Concept>
                <ConceptTitle>多元</ConceptTitle>
                <ConceptItem>因應體質及習慣 / 設計專屬生活方案</ConceptItem>
              </Concept>
              <ContentContent>
                各人的體質及生活方式都不同，我們是一個完全體，體質會因應環境及生活習慣的改變而作出調節，因此
                ADX 的健康管理方案亦會隨著個人體質的改變而作出應有的調。
              </ContentContent>
            </ConcepContainer>
            <ConcepContainer>
              <Concept>
                <ConceptTitle>卓越</ConceptTitle>
                <ConceptItem>沒有限制 / 超凡體驗</ConceptItem>
              </Concept>
              <ContentContent>
                ADX並不是一般的健康管理方案，是一種超凡體驗：因為ADX
                能讓大家在生活上選擇您喜歡的食物，做您喜歡的運動，甚至任何歷奇冒險！
                ADX能夠讓您每一天都充滿選擇，創造屬於您自己的非凡生活方式。
              </ContentContent>
            </ConcepContainer>
          </ConceptWrapper>
        </AdxWrapper>

        <SubTitle>創辦人的話</SubTitle>
        <FounderWrapper>
          <FounderContainer>
            <Founder>
              <FounderImage>
                <img src={FounderImg} />
              </FounderImage>
            </Founder>

            <FonderContent>
              <FounderSlogan>ADX，令你輕鬆達至最佳狀態的生活方式</FounderSlogan>
              <p>
                ADX是一個創新的健康管理概念，通過對日常生活習慣的小小改變，達至身體改善的最佳狀態。
              </p>
              <p>
                我們在多年前開始遍尋世界各地的健康管理之道，最終搵到一個突破點，在此基礎上，融合中西醫的理念，結合營養醫學、運動醫學、身心靈全人發展等方法。經過兩年時間，在超過500人的臨床實驗當中，證實ADX係可以安全、有效的達致纖體、增肌、改善亞健康及病前症狀的效果，並且簡單進行，容易長期維持。
              </p>
              <p>
                透過課程，ADX學員可以掌握一種全新的知識型身體管理方法，可以隨時隨地靈活使用，將健康人生掌握在自己手中。我們的目標係是協助所有ADX學員解開身體健康方程式，啟動身體潛在的自我修復功能。
              </p>
              <FonderInfo>
                <div>創辦人及首席營養師</div>
                <div>Victor Chan</div>
              </FonderInfo>
            </FonderContent>
          </FounderContainer>
        </FounderWrapper>
      </Container>
    </Wrapper>
  );
};

export default CompanyProfilePage;
