import styled from "styled-components";
import { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Creatable from "react-select/creatable";

import { useGetCrawlerArticleDetailMutation } from "../../../services/crawlerArticleApi";

import {
  useCreateArticleMutation,
  useGetArticleDetailMutation,
} from "../../../services/manageApi";

import { useParams } from "react-router-dom/dist";

import {
  useGetTagListQuery,
  useCreateTagMutation,
} from "../../../services/manageApi";

import Select from "../global/Select";
import Editor from "../global/Editor";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: 80%;
`;

const EditorWrapper = styled.div`
  padding: 20px 0;
`;

const InputWrapper = styled.div`
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InputEl = styled.input`
  width: 100%;
`;

const LabelEl = styled.label``;

const PublishButton = styled.button``;

const TitleAndTagWrapper = styled.div`
  width: 100%;
`;

const EditorPage = () => {
  const { articleInfo } = useParams();

  const [type, setType] = useState("");
  const [articleId, setArticleId] = useState("");

  useEffect(() => {
    setType(articleInfo.split("&")[0].split("=")[1]);
    setArticleId(articleInfo.split("&")[1].split("=")[1]);
  }, [articleInfo]);

  console.log(articleInfo.split("&")[1].split("=")[1]);

  const [
    _getCrawlerArticle,
    {
      data: crawlerData,
      isSuccess: crawlerIsSuccess,
      isError: crawlerIsError,
      error: crawlerError,
    },
  ] = useGetCrawlerArticleDetailMutation();

  const [
    _getPublishArticle,
    {
      data: publishData,
      isSuccess: publishIsSuccess,
      isError: publishIsError,
      error: publishError,
    },
  ] = useGetArticleDetailMutation();

  useEffect(() => {
    if (type === "publish") {
      _getPublishArticle({ articleId: articleId });
    }
  }, [type, articleId]);

  useEffect(() => {
    if (publishIsSuccess) {
      setContent(publishData?.articleContent);
      setTitle(publishData?.articleTitle);
      setTagList(publishData.tagList);
    }
  }, [publishData, publishIsError]);

  //   const { data, isSuccess, isError, error } =
  //     useGetCrawlerArticleDetailMutation({
  //       originalArticleId: articleInfo,
  //     });

  const {
    data: tagListData,
    isSuccess: tagListDatatIsSuccess,
    isError: tagListDataIsError,
    error: tagListDataError,
  } = useGetTagListQuery();

  console.log(tagListData);

  const [
    _createArticle,
    {
      data: articleData,
      isSuccess: articleIsSuccess,
      isError: articleIsError,
      error: articleError,
    },
  ] = useCreateArticleMutation();

  const [
    _createTag,
    {
      data: tagData,
      isSuccess: tagDataIsSuccess,
      isError: tagDataIsError,
      error: tagDataError,
    },
  ] = useCreateTagMutation();

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tagList, setTagList] = useState([]);

  // select options
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptiosn] = useState([]);

  //   useEffect(() => {
  //     if (isSuccess) {
  //       setContent(data.crawlerArticleContent);
  //       setTitle(data.crawlerArticleTitle);
  //     }
  //   }, [data, isError]);

  useEffect(() => {
    if (tagListDatatIsSuccess) {
      setOptiosn(
        tagListData.tagList.map((tag) => {
          return {
            value: tag.tagId,
            label: tag.tagName,
          };
        })
      );
    }
  }, [tagListData, tagListDataIsError]);

  //   console.log(data);

  const handleEditTitle = (e) => {
    console.log(e.target.value);
    setTitle(e.target.value);
  };

  const checkTagList = () => {
    // const newTag = selectedOption.filter((data) => data.__isNew__);
    // console.log(newTag[0].value);
    _createTag({ Tag: "圍棋" });
  };

  const handlePublish = () => {
    const data = {
      Title: title,
      Content: content,
      TagList: tagList,
    };
    console.log(data);
    // _createArticle(data);
  };

  console.log(selectedOption);

  return (
    <Wrapper>
      <Container>
        <InputWrapper>
          <LabelEl>文章標題</LabelEl>
          <InputEl
            defaultValue={title}
            onChange={(e) => handleEditTitle(e)}
          ></InputEl>
        </InputWrapper>
        {options && (
          <Select
            optionData={options}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            title={"文章標籤"}
          />
        )}

        {/* <Creatable
          isMulti
          closeMenuOnSelect={false}
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={options}
        /> */}

        {/* <InputWrapper>
          <LabelEl>文章標籤</LabelEl>
          <InputEl
            defaultValue={title}
            onChange={(e) => handleEditTitle(e)}
          ></InputEl>
        </InputWrapper> */}

        <EditorWrapper>
          <Editor contentData={content} setContent={setContent} />
        </EditorWrapper>

        <PublishButton onClick={checkTagList}>發布</PublishButton>
      </Container>
    </Wrapper>
  );
};

export default EditorPage;
