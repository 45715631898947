import { createApi } from "@reduxjs/toolkit/query/react";
import frontendBaseQuery from "./query/frontendBaseQuery";

export const articleApi = createApi({
  reducerPath: "articleApi",
  baseQuery: frontendBaseQuery,
  endpoints: (builder) => ({
    getArticleList: builder.query({
      query: ({ page }) => ({
        url: `/Article/ArticleList?page=${page}`,
        method: "GET",
      }),
    }),
    getArticleInfo: builder.query({
      query: ({ articleId }) => ({
        url: `/Article/ArticleInfo?articleId=${articleId}`,
        method: "GET",
      }),
    }),
    getArticleByTag: builder.query({
      query: ({ page, tagId }) => ({
        url: "/Article/ArticleByTag?tagId=6&page=1",
        method: "GET",
      }),
    }),
    getAllArticleList: builder.query({
      query: () => ({
        url: "/Article/GetAllArticleList",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetArticleListQuery,
  useGetArticleInfoQuery,
  useGetArticleByTagQuery,
  useGetAllArticleListQuery,
} = articleApi;
