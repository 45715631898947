import { styled, css } from "styled-components";
import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../utils/rwd";

const Wrapper = styled.div``;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  ${RWD_SM(css`
    grid-template-columns: repeat(1, 1fr);
  `)};
`;

const LabelEl = styled.label`
  font-size: clamp(16px, 1.25vw, 24px);
  text-align: left;

  color: ${({ theme }) => theme.colors.titleBrown};

  ${RWD_SM(css`
    padding-bottom: 5px;
  `)};
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
`;

const InputEl = styled.input`
  padding: 10px 15px;
  border: 1px solid ${({ theme }) => theme.colors.subBrown};
  border-radius: 5px;
  width: 100%;
  min-height: 40px;
`;

const ErrorMsg = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 100%;
`;

const Input = ({ title, placeholder, onChangeFun, value, errorMsg, type }) => {
  return (
    <Wrapper>
      <Container>
        <LabelEl htmlFor={type}>{title}</LabelEl>
        <InputWrapper>
          <InputEl
            placeholder={placeholder}
            onChange={(e) => onChangeFun(e.target.value)}
            value={value}
            type={type}
            id={type}
          />
          <ErrorMsg>{errorMsg}</ErrorMsg>
        </InputWrapper>
      </Container>
    </Wrapper>
  );
};

export default Input;
