import { styled, css } from "styled-components";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import HeavyMetalImg from "../../../images/services/heavyMetal.jpg";
import EndocrinelImg from "../../../images/services/endocrine.jpg";
import AllergyImg from "../../../images/services/allergy.jpg";
import DigestionImg from "../../../images/services/digestion.jpg";
import DnaImg from "../../../images/services/dna.jpg";
import BasicImg from "../../../images/services/basic.jpg";
import lgsImg from "../../../images/services/lgs.jpg";

import {
  RWD_XL,
  RWD_LG,
  RWD_LL,
  RWD_MD,
  RWD_SM,
  RWD_SS,
  RWD_XS,
} from "../../../utils/rwd";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: clamp(300px, 62.5vw, 1200px);
  padding-top: 80px;
  padding-bottom: 200px;

  // 1200px
  text-align: left;

  ${RWD_LL(css`
    width: 83.22vw;
  `)};

  ${RWD_MD(css`
    padding-top: 30px;
    padding-bottom: 60px;
  `)};
`;

const ContentContainer = styled.div``;

const TopImgWrapper = styled.div`
  width: 100%;
  height: clamp(300px, 27.0833vw, 520px);
  border-radius: 10px;
  overflow: hidden;
  /* border: 1px solid ${({ theme }) => theme.colors.copyRight}; */
  display: inline-flex;
  background: rgba(187, 175, 149, 0.1);

  img {
    object-fit: contain;

    /* object-position: center; */
  }

  ${RWD_SM(css`
    height: 51.667vw;

    img {
      height: 51.667vw;
      object-fit: cover;
    }
  `)};
`;

const TitleWrapper = styled.div`
  padding-top: 70px;
  padding-bottom: 20px;

  ${RWD_SM(css`
    padding-top: 30px;
    padding-bottom: 15px;
  `)};
`;

const Title = styled.div`
  font-size: clamp(24px, 2.5vw, 48px);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const SubTitleWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 20px;

  ${RWD_SM(css`
    padding-top: 30px;
    padding-bottom: 15px;
  `)};
`;
const SubTitle = styled.div`
  font-size: clamp(20px, 1.875vw, 36px);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.titleBrown};
`;

const ContentWrapper = styled.div`
  padding-bottom: 80px;
  text-align: justify;
  line-height: 2.5;

  ${RWD_SM(css`
    padding-top: 20px;
    padding-bottom: 40px;
  `)};
`;

const ExamineListUl = styled.ul`
  margin: 0;
  padding: 0;
`;

const ExamineListItem = styled.li`
  padding: 5px 0;
  list-style-type: decimal;
  list-style-position: inside;
`;

const PlusExamineUl = styled.ul`
  margin: 0;
  padding: 0;
`;

const PlusExamineListItem = styled.li`
  list-style-type: square;
  padding: 5px 0;
  color: #b5495b;
  list-style-position: inside;
`;

const ServicesPgae = () => {
  const params = useParams();

  const dataList = [
    {
      name: [
        {
          ch: "內分泌及荷爾蒙測試",
          en: "endocrine",
        },
      ],
      img: EndocrinelImg,
      symptomTitle: "典型不適症狀",
      symptom: [
        "頭痛 - 睡醒後仍覺疲憊",
        "精力體力下降 - 無原因之情緒波動",
        "記憶力減退 - 無原因之思緒混亂",
        "生理週期不穩定",
        "基本身體檢查各項指標正常，但仍然有上述症狀",
        "嘗試過多種健康改善方案，但仍然有原因不明的上述症狀",
      ],
      examine: [
        "由專業諮詢顧問針對個體狀況提出建議",
        "黃體酮",
        "褪黑激素",
        "睪酮",
        "雌二醇",
        "皮質醇",
        "尿液代謝指標",
      ],
    },
    {
      name: [
        {
          ch: "腸漏症測試",
          en: "lgs",
        },
      ],
      img: lgsImg,
      symptomTitle: "典型不適症狀",
      symptom: [
        "長期瘦不下去或是胖不起來？",
        "就算獲得足夠的休息也難以減輕疲勞/怎麼睡都睡不飽？",
        "明明特別喜愛某種食物卻又對它過敏？",
        "原因不明的偏頭痛、關節疼痛、關節僵硬",
        "原因不明的情緒低落、記憶力減退、學習障礙",
      ],
      examine: ["由專業諮詢顧問針對個體狀況提出建議"],
    },
    {
      name: [
        {
          ch: "消化系統功能測試",
          en: "digestion",
        },
      ],
      img: DigestionImg,
      symptomTitle: "典型不適症狀",
      symptom: [
        "化膿性炎症",
        "腳癬、甲癬",
        "腹部不適、壓痛",
        "間歇性便秘或腹瀉",
        "大便隱血、出疹",
        "代謝功能紊亂",
        "舌苔白而肥厚",
        "生殖系統炎症",
      ],
      examine: [
        "由專業諮詢顧問針對個體狀況提出建議",
        "腸道菌群指數",
        "細菌",
        "黴菌",
        "寄生蟲",
        "消化、吸收、代謝綜合指數",
      ],
      plusExamine: [
        "消化道炎症指數（加購項目）",
        "消化道潰瘍或腫瘤指數（加購項目）",
      ],
    },
    {
      name: [
        {
          ch: "重金屬及毒素測試",
          en: "heavyMetal",
        },
      ],
      img: HeavyMetalImg,
      symptomTitle: "典型不適症狀",
      symptom: [
        "原因不明的肝臟腎臟功能下降",
        "原因不明的皮膚問題",
        "原因不明的專注力不足",
        "焦慮、暴力傾向",
        "久久不癒的傷口、傷患",
      ],
      examine: ["由專業諮詢顧問針對個體狀況提出建議"],
    },
    {
      name: [
        {
          ch: "延遲性/即時性食物過敏測試",
          en: "allergy",
        },
      ],
      img: AllergyImg,
      symptomTitle: "延遲性食物過敏症狀",
      symptom: [
        "腦部-憂慮 緊張 情緒低落過度活躍 集中力差",
        "肺部-食物引起的支氣管炎 哮喘",
        "消化道-腹痛 腹瀉 腹脹 噁心 嘔吐 消化不良 沒有胃口",
        "關節、肌肉及結締組織-食物過敏性關節炎 疼痛 四肢僵硬及腫脹",
        "皮膚-瘙癢 皮疹 麻疹 加厚 發紅 腫脹 鱗屑",
        "全身-發熱 疲勞 出汗畏寒 無力 增肥 水腫",
      ],
      examine: ["抗體血液測試"],
    },
    {
      name: [
        {
          ch: "健康基因DNA解碼檢測",
          en: "dna",
        },
      ],
      img: DnaImg,
      symptomTitle: "DNA解析",
      symptom: [
        "了解你的基因如何影響你身體！！",
        "根據基因選擇飲食和運動！！",
        "全面的45種基因標記測試",
        "最簡易的唾液樣本來分析DNA",
        "根據個別獨特的遺傳基因特徵提供個人化的飲食建議",
        "化驗技術建基於具公信力的科研報告",
      ],
      examine: [
        "糖份與碳水化合物代謝",
        "酒精與咖啡因代謝",
        "脂肪攝取和血脂反應",
        "乳糖不耐症和骨骼健康",
        "糖尿病管理",
      ],
    },
    {
      name: [
        {
          ch: "ADX身體檢查計劃",
          en: "basic",
        },
      ],
      img: BasicImg,
      symptomTitle: "身體檢查",
      symptom: [
        "有些慢性疾病（例如糖尿病和高血壓，在早期時不一定有明顯的病徵，但其併發症會對身體構成莫大的影響。如果能及早發現，再配合適當的飲食調理，可以有助改善身體健康。",
        "ADX專業團隊根據功能醫學精選反映七大系統健康的檢測項目。ADX專業團隊會綜合體檢數據、家族史、病歷及其他體重、體脂、肌肉含量等數據分析現時健康狀況及趨勢，提供專屬改善方案。",
      ],
      examine: [
        "由專業諮詢顧問針對個體狀況提出建議",
        "血脂肪分析 - 總膽固醇、高密度膽固醇、總/高膽固醇比率、三酸甘油脂、低密度膽固醇",
        "肝功能檢驗 - 谷丙轉氨酵素、丙種谷氨轉移酵素、乙型肝炎表面抗體",
        "尿酸",
        "血片檢驗 - 尿液常規檢驗、顯微鏡檢驗",
        "甲狀腺檢驗 - 游離甲狀腺素、促甲狀腺激素",
        "腎功能檢驗 - 肌酸酐",
        "糖尿檢驗 -空腹血糖測試、糖化血色素",
        "血液學檢驗 - 白血球計數分佈、血型檢測",
      ],
    },
  ];

  return (
    <Wrapper>
      <Container>
        {dataList
          .filter((item) => item.name[0].en === params.servicesType)
          .map((data) => (
            <ContentContainer>
              <TopImgWrapper>
                <img src={data.img} />
              </TopImgWrapper>
              <TitleWrapper>
                <Title>{data.name[0].ch}</Title>
              </TitleWrapper>
              <SubTitleWrapper>
                <SubTitle>{data.symptomTitle}</SubTitle>
              </SubTitleWrapper>
              <ContentWrapper>
                {data.symptom.map((item) => (
                  <div>{item}</div>
                ))}
              </ContentWrapper>
              <SubTitleWrapper>
                <SubTitle>檢查項目</SubTitle>
              </SubTitleWrapper>
              <ExamineListUl>
                {data.examine.map((item) => (
                  <ExamineListItem>{item}</ExamineListItem>
                ))}
              </ExamineListUl>
              <PlusExamineUl>
                {data?.plusExamine?.map((item) => (
                  <PlusExamineListItem>{item}</PlusExamineListItem>
                ))}
              </PlusExamineUl>
            </ContentContainer>
          ))}
      </Container>
    </Wrapper>
  );
};

export default ServicesPgae;
