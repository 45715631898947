import { styled } from "styled-components";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { DefaultSwal, AppointmentInfoSwal } from "./MySwal";
import { useEffect, useState, useRef } from "react";

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

const FullCalendarComponent = ({
  consultationTimes,
  consultationOffTimes,
  setMonth,
  setYear,
  reservationEventData,
  handleCreateConsultationOffTime,
  handleDeleteConsultationOffTime,
}) => {
  const calendarRef = useRef(null);

  console.log(reservationEventData);

  // useEffect(() => {
  //   if (calendarRef) {setMonth
  //     const calendarApi = calendarRef.current.getApi();
  //     const date = calendarApi.getDate();
  //     const month = date.getMonth() + 1;
  //     console.log(month);
  //   }
  // }, [calendarRef]);

  const [eventData, setEventData] = useState([]);
  useEffect(() => {
    if (consultationTimes && reservationEventData && consultationOffTimes) {
      // 先取得可以看診的時間
      // 判斷是否有看診時間

      console.log(consultationTimes);
      const defaultConsultationTime = [
        {
          dayOffWeek: 1,
          time: ["14:00", "14:30", "15:00", "15:30"],
        },
        {
          dayOffWeek: 4,
          time: ["10:00", "10:30", "14:00", "14:30"],
        },
        {
          dayOffWeek: 5,
          time: ["10:00", "10:30", "14:00", "14:30", "15:00", "15:30"],
        },
      ];

      const consultationTimesList = consultationTimes
        .map((data) => {
          return data.hours.map((time) => {
            return {
              title: `可預約時段`,
              start: `${eventDateFormat(new Date(data.date))}T${time}`,
              color: "#8B7442",
              extendedProps: {
                type: "consultation",
              },
              // allDay: true,
              display: "list-item",
            };
          });

          // const dayOfWeek = new Date(data.date).getDay();

          // return defaultConsultationTime
          //   .filter((day) => dayOfWeek === day.dayOffWeek)[0]
          //   .time.map((el) => {
          //     return {
          //       title: data.hours.includes(el) ? "看診" : "休診",
          //       start: `${eventDateFormat(new Date(data.date))}T${
          //         el.split(":")[0]
          //       }:${el.split(":")[1]}`,
          //       color: data.hours.includes(el) ? "#8B7442" : "#ccc",
          //       extendedProps: {
          //         type: "consultation",
          //       },
          //     };
          //   });
        })
        .flat();

      const consultationOffTimesList = consultationOffTimes.map((data) => {
        return {
          title: "休診",
          start: `${data.split(" ")[0]}T${data.split(" ")[1]}`,
          color: "#ccc",
          extendedProps: {
            type: "consultationOff",
          },
        };
      });

      const reservationTimeList = reservationEventData.map((data) => {
        return {
          title: "預約",
          id: data.reservationId,
          start: `${data.bookerTime.split(" ")[0]}T${
            data.bookerTime.split(" ")[1]
          }`,
          color: "#FFB71B",
          extendedProps: {
            type: "reservationTime",
          },
          extendedProps: {
            type: "reservation",
            bookerType: data.bookerType,
            bookerName: data.bookerName,
            bookerEmail: data.bookerEmail,
            bookerPhone: data.bookerPhone,
          },
        };
      });

      console.log(consultationTimesList);
      console.log(consultationOffTimesList);

      const listData = consultationTimesList
        .concat(consultationOffTimesList)
        .concat(reservationTimeList);

      console.log(listData);

      function isSameDay(date1, date2) {
        return (
          date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate() &&
          date1.getHours() === date2.getHours() &&
          date1.getMinutes() === date2.getMinutes()
        );
      }

      // reservationEventData.map((data) => {
      //   const date = new Date(data.bookerTime);
      //   console.log(
      //     a.map((el) => {
      //       if (
      //         isSameDay(
      //           new Date(`${el.start.split("T")[0]} ${el.start.split("T")[1]}`),
      //           date
      //         )
      //       ) {
      //         return {
      //           title: "預約",
      //           id: data.reservationId,
      //           start: `${eventDateFormat(new Date(data.bookerTime))}T${
      //             data.bookerTime.split(" ")[1]
      //           }`,
      //           color: "#FFB71B",
      //           extendedProps: {
      //             type: "reservation",
      //             bookerType: data.bookerType,
      //             bookerName: data.bookerName,
      //             bookerEmail: data.bookerEmail,
      //             bookerPhone: data.bookerPhone,
      //           },
      //         };
      //       } else {
      //         return el;
      //       }
      //     })
      //   );
      // });

      // const comparisonTime = a.map((data) => {
      //   const date = new Date(
      //     `${data.start.split("T")[0]} ${data.start.split("T")[1]}`
      //   );

      //   const reservation = reservationEventData.find((el) =>
      //     isSameDay(new Date(el.bookerTime), date)
      //   );

      //   return reservation
      //     ? {
      //         title: "預約",
      //         id: reservation.reservationId,
      //         start: `${eventDateFormat(new Date(reservation.bookerTime))}T${
      //           reservation.bookerTime.split(" ")[1]
      //         }`,
      //         color: "#FFB71B",
      //         extendedProps: {
      //           type: "reservation",
      //           bookerType: reservation.bookerType,
      //           bookerName: reservation.bookerName,
      //           bookerEmail: reservation.bookerEmail,
      //           bookerPhone: reservation.bookerPhone,
      //         },
      //       }
      //     : data;
      // });

      setEventData(listData);
    }
  }, [consultationTimes, reservationEventData, consultationOffTimes]);

  const handleEventClick = (info) => {
    if (info.event.extendedProps.type === "reservation") {
      AppointmentInfoSwal({
        title: "預約資訊",
        name: info.event.extendedProps.bookerName,
        email: info.event.extendedProps.bookerEmail,
        phone: info.event.extendedProps.bookerPhone,
        type: info.event.extendedProps.bookerType === 1 ? "初診" : "複診",
        time: `${timeFormat(info.event.start)}`,
        confirmButtonText: "確定",
      });
    } else {
      DefaultSwal({
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        title: `確定${info.event.title === "休診" ? "取消" : "安排"}休診嗎？`,
        text: ` ${timeFormat(info.event.start)}`,
      }).then((result) => {
        if (result.isConfirmed) {
          info.event.title === "休診"
            ? handleDeleteConsultationOffTime(info.event.start)
            : handleCreateConsultationOffTime(info.event.start);
        }
      });
    }
  };

  const handleDatesSet = (info) => {
    const date = info.view.currentStart;
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    setMonth(month);
    setYear(year);
  };

  const timeFormat = (value) => {
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, "0");
    const date = value.getDate().toString().padStart(2, "0");
    const hour = value.getHours().toString().padStart(2, "0");
    const minute = value.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${date} ${hour}:${minute}`;
  };

  const eventDateFormat = (value) => {
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, "0");
    const date = value.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${date}`;
  };

  const handleTimeSelect = (info) => {
    console.log(timeFormat(info.start));
    DefaultSwal({
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "確定",
      cancelButtonText: "取消",
      title: "確定安排休診嗎？",
      text: ` ${timeFormat(info.start)} - ${timeFormat(info.end)} `,
    });
    // alert("selected " + info.startStr + " to " + info.endStr);
  };

  const handleDayRender = (info) => {
    const dayOfWeek = info.date.getDay(); // 0 (Sunday) to 6 (Saturday)
    if (dayOfWeek === 1 || dayOfWeek === 4 || dayOfWeek === 5) {
      console.log(333);
      console.log(info.el);
    }
  };

  return (
    <Wrapper>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          right: "prev,next today",
          left: "title",
          // right: "dayGridMonth",
        }}
        // dayMaxEventRows={true}
        // views={{
        //   dayGridMonth: {
        //     dayMaxEventRows: 20,
        //   },
        // }}
        height={"100%"}
        datesSet={handleDatesSet}
        dateClick={() => console.log("click")}
        // selectable={true}
        // select={(e) => handleTimeSelect(e)}
        displayEventTime={true}
        eventTimeFormat={{
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
          meridiem: false,
        }}
        events={eventData}
        slotMinTime="10:00:00"
        slotMaxTime="21:00:00"
        eventShortHeight="30"
        // showNonCurrentDates={false}
        // dayCellDidMount={handleDayRender}
        eventClick={handleEventClick}
      />
    </Wrapper>
  );
};

export default FullCalendarComponent;
