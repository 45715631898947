import { createApi } from "@reduxjs/toolkit/query/react";
import frontendBaseQuery from "./query/frontendBaseQuery";

export const remoteCalendarApi = createApi({
  reducerPath: "remoteCalendarApi",
  baseQuery: frontendBaseQuery,
  endpoints: (builder) => ({
    getCalendarList: builder.query({
      query: () => ({
        url: "/RemoteCalendar/GetCalendarList",
        method: "GET",
      }),
      providesTags: [{ type: "remoteCalendar", id: "REMOTECALENDAR" }],
    }),
    reserveCalendar: builder.mutation({
      query: ({ RecipientEmail, StartDateTime, EndDateTime }) => ({
        url: "/RemoteCalendar/ReserveCalendar",
        method: "POST",
        body: JSON.stringify({
          RecipientEmail,
          StartDateTime,
          EndDateTime,
        }),
      }),
      invalidatesTags: [{ type: "remoteCalendar", id: "REMOTECALENDAR" }],
    }),
    DeleteCalendarItem: builder.mutation({
      query: ({ ScheduledId }) => ({
        url: "/RemoteCalendar/DeleteCalendarItem",
        method: "DELETE",
        body: JSON.stringify({
          ScheduledId,
        }),
      }),
      invalidatesTags: [{ type: "remoteCalendar", id: "REMOTECALENDAR" }],
    }),
  }),
});

export const {
  useGetCalendarListQuery,
  useReserveCalendarMutation,
  useDeleteCalendarItemMutation,
} = remoteCalendarApi;
